import { TeamMateDTO } from '../dto/teamMate.DTO';
import { TeamMate } from "../../../../domain/entity/teamMate";
import { TeamMateBuilder } from "../../../../domain/entity/teamMate.builder";

export class TeamMateMapper {
    static mapDataToTeamMate(data: TeamMateDTO, badges: string[]): TeamMate {
        const teamMate = new TeamMateBuilder()
            .withEmail(data.email)
            .withFirstName(data.firstName)
            .withLastName(data.lastName)
            .withRegion(data.regionLabel)
            .withAgency(data.agencyName)
            .withAgencyCode(data.agencyCode)
            .withScore(data.score)
            .withBadges(badges)

        if (data.avatar)
            teamMate.withAvatar(data.avatar)

        return teamMate.build()
    }
}
