import { AppEvent, Reference } from "./AppEvent";
import { BeaconsType } from "./beacons.type";

export class AppEventBuilder {

    protected _beacon!: BeaconsType
    protected _value!: string
    protected _references: Reference[]
    protected _isSecured: boolean

    constructor() {
        this._isSecured = true;
        this._references = [];
    }


    withBeacon(value: BeaconsType): AppEventBuilder {
        this._beacon = value;
        return this
    }

    withValue(value: string): AppEventBuilder {
        this._value = value;
        return this
    }

    withReferences(value: Reference[]): AppEventBuilder {
        this._references = value;
        return this
    }

    withSecured(value: boolean): AppEventBuilder {
        this._isSecured = value;
        return this
    }

    build(): AppEvent {
        return new AppEvent(this._beacon, this._value, this._references, this._isSecured)
    }
}
