import React from 'react';
import { Profile } from "../../../../../profile/domain/entities/profile";
import { AdventureScore } from "../../../../domain/entities/score/adventureScore";
import { AdventurePlayerScore } from "../../../../domain/entities/score/adventurePlayerScore";
import { AdventureI18n } from "../../../../config/i18n";
import { AdventureSpecifications } from "../../specifications";

interface Props {
    profile: Profile | undefined;
    adventureScore: AdventureScore | undefined;
}

const ItinerantStandardDashboard = (props: Props) => {

    const getPlayerScore = (): AdventurePlayerScore => {
        const email = props.profile?.email
        return props.adventureScore?.playersScore.find(item => item.email === email) as AdventurePlayerScore
    }

    return (
        <React.Fragment>
            <div className={'flex-box'}>

                <div className={'box-adventure-info'}>
                    <p>{AdventureI18n.turnover}</p>
                    <strong>{AdventureSpecifications.formatNumber(getPlayerScore()?.turnover, ' ', ' €')}</strong>
                    <span className={'mt-2'}>
                       sur {AdventureSpecifications.formatNumber(getPlayerScore()?.turnoverGoal, ' ', ' €')}
                    </span>
                </div>

                <div className={'box-adventure-info'}>
                    <p>{AdventureI18n.rank}</p>
                    <strong>{getPlayerScore()?.rank}
                        <sup style={{fontSize: '60%', color: "var(--silver-chalice)", fontWeight: 'lighter'}}>
                            {getPlayerScore()?.rankSuffix()}
                        </sup></strong>
                </div>

                <div className={'box-adventure-info'}>
                    <p>{AdventureI18n.cm}</p>
                    <strong>{getPlayerScore()?.activatedClient}</strong>
                    <span>sur {getPlayerScore()?.activatedClientGoal}</span>
                </div>
            </div>

            <div className={'box-100'}>
                <div className={'adventure-progress'}>
                    <div className="progress-title">
                        <span>{AdventureI18n.result}</span>
                    </div>
                    <div className="progressbar">
                        <div className={'progress-bg'} style={{marginTop: 10}}>
                            <div className="actual-progress"
                                 style={{
                                     width: AdventureSpecifications.playerScorePercentage(getPlayerScore())
                                         .replace(' ', '')
                                 }}/>
                        </div>
                        <div className="actual-progress-val" style={{marginTop: 0}}>
                            {AdventureSpecifications.playerScorePercentage(getPlayerScore())}
                        </div>
                    </div>
                    <div className={'d-flex justify-content-between fex-row progress-bar-values'}>
                        <p>{AdventureSpecifications.formatNumber(getPlayerScore()?.turnover, ' ', ' €')}</p>
                        <p>{AdventureSpecifications.formatNumber(getPlayerScore()?.turnoverGoal, ' ', ' €')}</p>
                    </div>
                    <div className={'d-flex justify-content-between fex-row progress-bar-values'}>
                        <p>{AdventureSpecifications.formatNumber(getPlayerScore()?.activatedClient, ' ', '')}</p>
                        <p>{AdventureSpecifications.formatNumber(getPlayerScore()?.activatedClientGoal, ' ', '')}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default ItinerantStandardDashboard;
