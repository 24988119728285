import React from "react";
import { Message } from "../../../../../message/domain/entities/message";
import { DashboardI18n } from "../../../../config/i18n";

interface Props {
    message: Message;
    onClick: () => void;
}

export default function MessageItem(props: Props) {
    return (
        <div className={'message-item item'}>
            <div className={'message-overlay'}>
                <div className={'subtitle'}>
                    <span className={'gradient-text'}>{DashboardI18n.message}</span>
                </div>

                <h2>{props.message.title}</h2>

                <button onClick={() => props.onClick()}>
                    <div className={'play-icon'}>
                        <span/>
                    </div>
                    {DashboardI18n.readMessage}
                </button>
            </div>
        </div>
    )
}
