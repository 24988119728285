import React from "react";
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Badge } from "../../../../../profile/domain/entities/badge/badge";
import { DashboardI18n } from "../../../../config/i18n";

interface Props {
    badges: Badge[];
    totalBadges: number;
}

export default function DashboardBadgesCarousel(props: Props) {

    const [index, setIndex] = useState(0);

    return (
        <div className={'dark-item mt-3 flex-grow-2'}>
            <div className={'d-flex justify-content-between align-items-center mb-4'}>
                <h4>{DashboardI18n.earnedBadges}</h4>

                <div className={'badge-number'}>
                    <span className={'gradient-text'}>{props.badges.length} sur {props.totalBadges}</span>
                </div>
            </div>

            <Carousel className={'badges-carousel'}
                      indicators={false}
                      activeIndex={index}
                      onSelect={(selectedIndex: number) => setIndex(selectedIndex)}>

                {props.badges.map(badge => (
                    <Carousel.Item key={badge.id}>
                        <div className="badge-container">
                            <div className="hex-background">
                                <img src={badge.picture} alt={badge.id}/>
                            </div>
                        </div>

                        <p>{badge.name}</p>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}
