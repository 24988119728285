import { LOGOUT_USER, LOGOUT_USER_FAILED, LOGOUT_USER_SUCCEEDED, LogoutUserActionTypes } from './actionTypes';
import { HandleRequestState } from "../../../redux-configuration/AppState";


const initialState: HandleRequestState = {
    loading: false,
    error  : undefined,
    success: undefined
}
export const logoutUserReducer = (state = initialState, action: LogoutUserActionTypes): HandleRequestState => {
    switch (action.type) {
        case LOGOUT_USER:
            return {
                loading: true,
                error  : undefined,
                success: undefined
            }
        case LOGOUT_USER_FAILED:
            return {
                loading: false,
                error  : action.payload,
                success: undefined
            }
        case LOGOUT_USER_SUCCEEDED:
            return {
                loading: false,
                error  : undefined,
                success: true
            }
        default:
            return state
    }
}
