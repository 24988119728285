import React from "react";
import { RegionBackground } from "../../../../shared/adapters/primaries/region.background";

interface Props {
    isUser: boolean;
    rank: number;
    title: string | undefined;
    subTitle: string | undefined;
    region: string;
    score: number;
    children?: JSX.Element;
    onRef: (itemRef: HTMLDivElement | null) => void
}

export default function GlobalScoreItem(props: Props) {
    return (
        <div className={'ranking-item d-flex align-items-center mt-3'}
             ref={(r) => {
                 if (props.isUser) {
                     props.onRef(r)
                 }
             }}>
            <p className={'rank'}>{props.rank}</p>

            <RegionBackground region={props.region}/>

            {props.children ? props.children : <div className={'px-3'}/>}

            <div className={"ranking-info"}>
                <p>{props.title}</p>
                <span>{props.subTitle}</span>
            </div>

            <p className={'score'}>{props.score.toFixed(0)}</p>
        </div>
    )
}
