import { Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs'
import { catchError, concatMap, switchMap } from 'rxjs/operators'
import { SAVE_MESSAGE, SaveMessageAction } from "./actionTypes";
import { MessageRepository } from "../../domain/gateway/message.repository";
import { saveMessageFailed, saveMessageSucceeded } from "./actions";
import { AppState } from "../../../redux-configuration/AppState";
import { loadMessages } from "../load/actions";

export const saveMessageEpic: Epic = (action$: Observable<SaveMessageAction>,
                                      store: StateObservable<AppState>,
                                      {messageRepository}: { messageRepository: MessageRepository }) =>
    action$.pipe(
        ofType(SAVE_MESSAGE),
        switchMap(action => messageRepository.saveMessage(action.payload)
            .pipe(
                concatMap(() => [
                    loadMessages(),
                    saveMessageSucceeded()
                ]),
                catchError((error: string) => of(saveMessageFailed(error)))
            )
        )
    )
