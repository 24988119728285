import {
    LOAD_ADVENTURE_SCORE,
    LOAD_ADVENTURE_SCORE_FAILED,
    LOAD_ADVENTURE_SCORE_SUCCEEDED,
    LoadAdventureScoreActionTypes
} from './actionTypes';
import { AdventureScore } from "../../domain/entities/score/adventureScore";

export const loadAdventureScore = (adventureId: string): LoadAdventureScoreActionTypes => ({
    type   : LOAD_ADVENTURE_SCORE,
    payload: adventureId
})


export const loadAdventureScoreFailed = (error: string): LoadAdventureScoreActionTypes => ({
    type   : LOAD_ADVENTURE_SCORE_FAILED,
    payload: error
})


export const loadAdventureScoreSucceeded = (adventureScore: AdventureScore): LoadAdventureScoreActionTypes => ({
    type   : LOAD_ADVENTURE_SCORE_SUCCEEDED,
    payload: adventureScore
})
