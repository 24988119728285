import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AdventurePlayerScore } from "../../../../domain/entities/score/adventurePlayerScore";
import { Profile } from "../../../../../profile/domain/entities/profile";
import { AdventureI18n } from "../../../../config/i18n";
import { ItinerantRankingItem } from "../../components/rankingItems/itinerantRankingItem";

interface Props {
    playersScores: AdventurePlayerScore[] | undefined
    profile: Profile | undefined
}

export const ItinerantStandardRanking = (props: Props) => {
    let profileScoreItemIndex: HTMLDivElement | null = null

    const allItinerant = props.playersScores?.map((item) => {
        return <ItinerantRankingItem key={item.email} onRef={(ref) => profileScoreItemIndex = ref}
                                     profile={props.profile}
                                     playerScore={item}/>
    })

    const myRegionItinerant = props.playersScores?.filter((item) => item.region === props.profile?.region).map((item) => {
        return <ItinerantRankingItem key={item.email} onRef={(ref) => profileScoreItemIndex = ref}
                                     profile={props.profile}
                                     playerScore={item}/>
    })

    return (
        <div className={'data-section'}>
            <div className={'d-flex align-items-center w-100 justify-content-between'}>
                <h2>{AdventureI18n.rank}</h2>

                <a className={'you-btn'} onClick={() => handleAction()}>
                    {AdventureI18n.you}
                </a>
            </div>

            <Tabs className={'d-flex flex-column p-0 overflow-hidden flex-grow-1'}>
                <TabList className={'d-flex flex-row p-0'}>
                    <Tab className={'tab-header'}>{AdventureI18n.allTCI}</Tab>
                    <Tab className={'tab-header'}>{AdventureI18n.myRegionTCI}</Tab>
                </TabList>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {allItinerant}
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {myRegionItinerant}
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );

    function handleAction() {
        if (profileScoreItemIndex) {
            profileScoreItemIndex.scrollIntoView({
                behavior: "smooth",
                block   : "center",
                inline  : "start"
            })
            const currentClassName = profileScoreItemIndex.getAttribute('class')
            profileScoreItemIndex.setAttribute('class', currentClassName + ' selected-item')
        }
    }
};
