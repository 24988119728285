import { combineReducers } from 'redux';
import { AdventureState } from './state';
import { loadAdventureListReducer } from '../usecases/list/reducer';
import { loadAdventureScoreReducer } from "../usecases/score/reducer";
import { loadAdventureDetailsReducer } from "../usecases/details/reducer";
import { subscribeToAdventureReducer } from "../usecases/subscribeToAdventure/subscribeToAdventure.reducer";

export const adventureRootReducer = combineReducers<AdventureState>({
    list         : loadAdventureListReducer,
    details      : loadAdventureDetailsReducer,
    score        : loadAdventureScoreReducer,
    subscribingToAdventure: subscribeToAdventureReducer
})
