import React, { useEffect, useState } from "react";
import { SpeedQuizResult } from "../../../../domain/entity/result";
import ScoreIcon from "../../../../../shared/adapters/primaries/icons/score.icon";
import { SpeedQuizI18n } from "../../../../config/i18n";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { retrieveProfile } from "../../../../../profile/usecases/retrieveProfile/retrieveProfile.actions";

interface Props {
    result: SpeedQuizResult
}

export default function SpeedQuizResultScore(props: Props) {

    const dispatch = useDispatch()

    const score = props.result.totalPoints - props.result.earnedPoints - props.result.bonusPoints

    const [counter, setCounter] = useState<number>(score)
    const [startAnimation, setStartAnimation] = useState<boolean>(false)

    useEffect(() => {
        if (startAnimation && counter < props.result.totalPoints) {
            setTimeout(() => setCounter(counter + 1), 100)
        }
    }, [counter])

    useEffect(() => {
        if (startAnimation && counter !== props.result.totalPoints) {
            setCounter(counter + 1)
        }
    }, [startAnimation])

    return (
        <div className={'result-item result-score mt-3 p-5'}>
            <h2>{props.result.message}</h2>

            <div className={'d-flex align-items-center justify-content-center mt-5 mb-4'}>
                <h3 className={'gradient-text'}>{counter}</h3>
                <ScoreIcon width={50}/>
            </div>

            <div className={'text-start d-inline-block'}>
                <p><span>+ {props.result.earnedPoints}</span> {SpeedQuizI18n.earnedPoints}</p>
                <p><span>+ {props.result.bonusPoints}</span> {SpeedQuizI18n.bonusPoints}</p>
            </div>

            {counter === props.result.totalPoints ?
                <Link to={'/dashboard'}
                      className={'large-blue-button d-block mt-5'}>{SpeedQuizI18n.finish}</Link> :
                <button className={'large-blue-button d-block mt-5'}
                        onClick={() => {
                            setStartAnimation(true)
                            dispatch(retrieveProfile())
                        }}>{SpeedQuizI18n.next}</button>
            }
        </div>
    )
}
