export class SpeedQuizUserAnswers {
    constructor(
        protected _questionId: string,
        protected _answers: string[],
    ) {}

    get questionId(): string {
        return this._questionId;
    }

    get answers(): string[] {
        return this._answers;
    }
}
