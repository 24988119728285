import React from "react"

interface Props {
    width: number;
    height: number;
    isSelected: () => boolean;
}

export function GlobalScoreIcon(props: Props) {
    const color = props.isSelected() ? '#FFF' : '#979797'

    return (
        <svg width={props.width} height={props.height} viewBox={`0 0 ${props.width} ${props.height}`}>
            <g fill='none' fillRule="evenodd" stroke="#3E3E3E">
                <path
                    d="M23.102 12l.086.055c.077.068.046.028.094.117l1.09 3 3.147.127c.257.01.362.34.16.501l-2.471 1.981.785 2.834 2.894-.117 1.09-3c.08-.175.172-.172.34-.172.16.11.109.044.18.173l1.09 3 3.147.126c.257.01.362.34.16.502l-2.471 1.98.853 3.079c.07.25-.206.455-.42.31l-2.619-1.776-2.313 1.569.969 2.665 3.146.127c.256.01.362.34.16.502l-2.471 1.98.853 3.079c.07.25-.206.455-.42.31l-2.619-1.776-2.618 1.776c-.214.145-.49-.06-.42-.31l.853-3.079-2.33-1.867-2.33 1.867.854 3.079c.069.25-.207.455-.42.31l-2.62-1.776-2.618 1.776c-.214.145-.49-.06-.42-.31l.853-3.079-2.471-1.98c-.202-.162-.096-.492.16-.502l3.146-.127.959-2.635-2.358-1.599-2.618 1.776c-.214.145-.49-.06-.42-.31l.853-3.078-2.471-1.981c-.202-.162-.097-.491.16-.502l3.146-.127 1.09-3c.08-.175.172-.172.34-.172.161.11.11.044.18.173l1.091 3 2.938.118.786-2.836-2.472-1.98c-.201-.162-.096-.492.16-.502l3.146-.127 1.091-3c.06-.152.198-.17.34-.172zm-4.59 14.863l-.896 2.462a.277.277 0 01-.248.183l-2.582.104 2.028 1.626c.088.07.126.188.095.297l-.7 2.527 2.15-1.458a.273.273 0 01.307 0l2.149 1.458-.7-2.527a.283.283 0 01.094-.297l2.029-1.626-2.582-.104a.277.277 0 01-.248-.183l-.896-2.462zm9.03 0l-.895 2.462a.277.277 0 01-.249.183l-2.582.104 2.029 1.626c.087.07.125.188.095.297l-.7 2.527 2.149-1.458a.273.273 0 01.307 0l2.149 1.457-.7-2.526a.283.283 0 01.095-.297l2.028-1.626-2.581-.104a.277.277 0 01-.249-.183l-.896-2.462zm-4.52-7.469l-2.618 1.775-2.456 1.939c.807 2.797.783 2.771.824 2.846l1.09 3 3.184.127 3.146-.127 1.007-2.77.853-3.076-2.458-1.97-2.572-1.744zm-7.26-.987l-.895 2.462a.277.277 0 01-.249.184l-2.581.104 2.028 1.625c.088.071.125.188.095.298l-.7 2.526 2.149-1.457a.274.274 0 01.307 0l2.15 1.457-.701-2.527a.284.284 0 01.095-.297l2.028-1.625-2.582-.105a.277.277 0 01-.248-.183l-.895-2.462z"
                    fill={color}
                    fillRule="nonzero"
                    stroke={color}
                    strokeWidth={0.3}
                />
            </g>
        </svg>
    )
}
