import React from "react";
import { AdventureAgencyScore } from "../../../domain/entities/score/adventureAgencyScore";
import { AdventurePlayerScore } from "../../../domain/entities/score/adventurePlayerScore";
import { Profile } from "../../../../profile/domain/entities/profile";
import { AdventureType } from "../../../domain/types";
import { ItinerantStandardRanking } from "../standard/ranking/itenerantStandard.ranking";
import { AgencyStandardRanking } from "../standard/ranking/agencyStandard.ranking";
import { SupportStandardRanking } from "../standard/ranking/supportStandard.ranking";
import { InterAgencyRanking } from "../interAgence/ranking/interAgency.ranking";

interface Props {
    agenciesScores: AdventureAgencyScore[] | undefined
    playersScores: AdventurePlayerScore[] | undefined
    profile: Profile | undefined
    missionType: AdventureType
}

export const RankingSection = (props: Props) => {

    if (props.missionType === 'STANDARD') {
        if (props.profile?.type === 'ITINERANT') {
            return <ItinerantStandardRanking profile={props.profile}
                                             playersScores={props.playersScores}/>
        } else if (props.profile?.type === 'AGENCE') {
            return <AgencyStandardRanking agenciesScores={props.agenciesScores}
                                       profile={props.profile}/>
        } else {
            return <SupportStandardRanking agenciesScores={props.agenciesScores}
                                           playersScores={props.playersScores}
                                           profile={props.profile}
            />
        }
    } else {
        return <InterAgencyRanking agenciesScores={props.agenciesScores}
                                   profile={props.profile}/>
    }
};
