import { RetrieveBadgesState } from "../../config/state";
import {
    LoadBadgesActionsTypes,
    RETRIEVE_BADGES,
    RETRIEVE_BADGES_FAILED,
    RETRIEVE_BADGES_SUCCEEDED
} from "./loadBadges.types";

const initialState: RetrieveBadgesState = {
    badges   : undefined,
    error    : undefined,
    isLoading: false
}
export const retrieveBadgesReducer = (state = initialState, action: LoadBadgesActionsTypes): RetrieveBadgesState => {
    switch (action.type) {
        case RETRIEVE_BADGES:
            return {
                isLoading: true,
                badges   : state.badges,
                error    : undefined
            }
        case RETRIEVE_BADGES_FAILED:
            return {
                isLoading: false,
                badges   : state.badges,
                error    : action.payload
            }
        case RETRIEVE_BADGES_SUCCEEDED:
            return {
                isLoading: false,
                error    : undefined,
                badges   : action.payload
            }
        default:
            return state;
    }
}
