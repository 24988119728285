import { SpeedQuizResponseType } from "../types";
import { SpeedQuizRound } from "../entity/round";

export class SpeedQuizRoundBuilder {
    protected _id!: string
    protected _title!: string
    protected _status!: boolean
    protected _category!: string
    protected _userResponse!: SpeedQuizResponseType[]
    protected _opponentResponse!: SpeedQuizResponseType[]

    withId(value: string): SpeedQuizRoundBuilder {
        this._id = value
        return this
    }

    withTitle(value: string): SpeedQuizRoundBuilder {
        this._title = value
        return this
    }

    withStatus(value: boolean): SpeedQuizRoundBuilder {
        this._status = value
        return this
    }

    withCategory(value: string): SpeedQuizRoundBuilder {
        this._category = value
        return this
    }

    withUserResponse(value: SpeedQuizResponseType[]): SpeedQuizRoundBuilder {
        this._userResponse = value
        return this
    }

    withOpponentResponse(value: SpeedQuizResponseType[]): SpeedQuizRoundBuilder {
        this._opponentResponse = value
        return this
    }

    build(): SpeedQuizRound {
        return new SpeedQuizRound(
            this._id,
            this._title,
            this._status,
            this._category,
            this._userResponse,
            this._opponentResponse
        )
    }
}
