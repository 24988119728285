import { Adventure } from '../../domain/entities/adventure';

export const LOAD_ADVENTURE_LIST = 'LOAD_ADVENTURE_LIST';
export const LOAD_ADVENTURE_LIST_FAILED = 'LOAD_ADVENTURE_LIST_FAILED';
export const LOAD_ADVENTURE_LIST_SUCCEEDED = 'LOAD_ADVENTURE_LIST_SUCCEEDED'

export interface LoadAdventureListAction {
    type: typeof LOAD_ADVENTURE_LIST;
}

export interface LoadAdventureListFailedAction {
    type: typeof LOAD_ADVENTURE_LIST_FAILED;
    payload: string;
}

export interface LoadAdventureListSucceededAction {
    type: typeof LOAD_ADVENTURE_LIST_SUCCEEDED;
    payload: Adventure[];
}

export type LoadAdventureListActionTypes =
    LoadAdventureListAction
    | LoadAdventureListFailedAction
    | LoadAdventureListSucceededAction
