import {
    SEND_MCQ_ANSWERS,
    SEND_MCQ_ANSWERS_FAILED,
    SEND_MCQ_ANSWERS_SUCCEEDED,
    SendMCQAnswersAction,
    SendMCQAnswersFailedAction,
    SendMCQAnswersSucceededAction,
} from "./actionTypes";
import { MCQScore } from "../../domain/entity/MCQScore";
import { MCQAnswerType } from "../../domain/type/MCQAnswer.type";

export const sendMCQAnswers = (mcqId: number, adventureId: string, answers: MCQAnswerType[]): SendMCQAnswersAction => ({
    type   : SEND_MCQ_ANSWERS,
    payload: {mcqId, adventureId, answers}
});

export const sendMCQAnswersFailed = (error: string): SendMCQAnswersFailedAction => ({
    type   : SEND_MCQ_ANSWERS_FAILED,
    payload: error
})

export const sendMCQAnswersSucceeded = (score: MCQScore): SendMCQAnswersSucceededAction => ({
    type   : SEND_MCQ_ANSWERS_SUCCEEDED,
    payload: score
});
