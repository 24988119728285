import React, { useEffect, useState } from 'react';
import "./mcq.css"
import { Profile } from "../../../profile/domain/entities/profile";
import { Adventure } from "../../../adventure/domain/entities/adventure";
import { MCQ } from "../../domain/entity/MCQ";
import LoadingContainer from "../../../shared/adapters/primaries/loading/loading";
import { useNavigate, useParams } from "react-router-dom";
import { McqQuestionContainer } from "./components/mcqQuestion.container";
import { MCQAnswerType } from "../../domain/type/MCQAnswer.type";
import { MCQScore } from "../../domain/entity/MCQScore";
import { McqResult } from "./components/mcq.result";

interface Props {
    profile: Profile | undefined;
    adventure: Adventure | undefined;
    mcq: MCQ | undefined;
    loadAdventure: (id: string) => void
    loadMcq: (id: string) => void
    sendMCQResponse: (adventureId: string, mcqId: number, response: MCQAnswerType[]) => void;
    mcqScore: MCQScore | undefined;
}

export const McqContainer = (props: Props) => {

    const [questionIndex, setQuestionIndex] = useState<number>(0)
    const [questionResponses, setQuestionResponses] = useState<Array<MCQAnswerType>>([])
    const [shouldDisplayResult, setShouldDisplayResult] = useState<boolean>(false)

    const router = useNavigate()
    const {adventureId, mcqId} = useParams();


    useEffect(() => {
        if (adventureId && mcqId) {
            if (props.adventure?.id !== adventureId) {
                props.loadAdventure(adventureId)
            }
            setQuestionIndex(0)
            setQuestionResponses([])
            setShouldDisplayResult(false)
        } else {
            router('/404')
        }
    }, [])

    useEffect(() => {
        if (props.mcqScore && questionResponses.length > 0) {
            setShouldDisplayResult(true)
        }
    }, [props.mcqScore])


    if (props.adventure === undefined || props.mcq === undefined) {
        return <LoadingContainer/>
    }

    if (shouldDisplayResult) {
        return <McqResult mcqQuestionNumber={props.mcq.questions.length}
                          adventure={props.adventure}
                          mcqScore={props.mcqScore}
                          onClick={(redo) => {
                              if (redo) {
                                  setQuestionIndex(0)
                                  setQuestionResponses([])
                                  setShouldDisplayResult(false)
                              } else {
                                  router(`/adventure/${adventureId}`)
                              }
                          }}/>
    }
    return (
        <div className="mcq-question">
            <div className="overlap-group overflow-scroll">
                <McqQuestionContainer isLastQuestion={questionIndex === (props.mcq?.questions.length - 1)}
                                      currentQuestion={props.mcq?.questions[questionIndex]}
                                      onNextQuestion={(lastQuestionId, responses) => renderNextQuestion(lastQuestionId, responses)}
                                      onSubmit={(lastQuestionId, responses) => submitResponses(lastQuestionId, responses)}
                />
            </div>
        </div>
    )

    function renderNextQuestion(previousQuestionId: number, previousQResponses: number[]): void {
        questionResponses.push({questionId: previousQuestionId, responses: previousQResponses})
        if (props.mcq && (questionIndex + 2) <= props.mcq?.questions.length) {
            setQuestionIndex(questionIndex + 1)
        }
    }

    function submitResponses(previousQuestionId: number, previousQResponses: number[]): void {
        questionResponses.push({questionId: previousQuestionId, responses: previousQResponses})
        if (props.adventure && props.mcq) {
            props.sendMCQResponse(props.adventure.id, props.mcq.id, questionResponses)
        }
    }
};
