import { Badge } from "../../domain/entities/badge/badge";

export const RETRIEVE_BADGES = 'RETRIEVE_BADGES';
export const RETRIEVE_BADGES_FAILED = 'RETRIEVE_BADGES_FAILED';
export const RETRIEVE_BADGES_SUCCEEDED = 'RETRIEVE_BADGES_SUCCEEDED';


export interface RetrieveBadgesAction {
    type: typeof RETRIEVE_BADGES;
}

export interface RetrieveBadgesFailedAction {
    type: typeof RETRIEVE_BADGES_FAILED;
    payload: string;
}

export interface RetrieveBadgesSucceededAction {
    type: typeof RETRIEVE_BADGES_SUCCEEDED;
    payload: Badge[];
}

export type LoadBadgesActionsTypes = RetrieveBadgesAction | RetrieveBadgesFailedAction | RetrieveBadgesSucceededAction
