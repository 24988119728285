import { AdventureStatusType, AdventureType } from '../types';

export class Adventure {
    constructor(
        protected _id: string,
        protected _label: string,
        protected _description: string,
        protected _starDate: Date,
        protected _endDate: Date,
        protected _picture: string,
        protected _status: AdventureStatusType,
        protected _earns: number,
        protected _type: AdventureType
    ) {
    }

    get id(): string {
        return this._id;
    }

    get label(): string {
        return this._label;
    }

    get description(): string {
        return this._description;
    }

    get starDate(): Date {
        return this._starDate;
    }

    get endDate(): Date {
        return this._endDate;
    }

    get picture(): string {
        return this._picture;
    }

    get status(): AdventureStatusType {
        return this._status;
    }

    get earns(): number {
        return this._earns;
    }

    get type(): AdventureType {
        return this._type;
    }
}
