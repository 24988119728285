import { BadgeIdType } from "../types";
import { Badge } from "./badge";

export class BadgeBuilder {
    protected _id!: BadgeIdType;
    protected _picture!: string;
    protected _name!: string;
    protected _description!: string;
    protected _isEncrypted!: boolean;


    withId(id: BadgeIdType): BadgeBuilder {
        this._id = id
        return this
    }

    withPicture(picture: string): BadgeBuilder {
        this._picture = picture
        return this
    }

    withName(name: string): BadgeBuilder {
        this._name = name
        return this
    }

    withDescription(description: string): BadgeBuilder {
        this._description = description
        return this
    }

    withIsEncrypted(isEncrypted: boolean): BadgeBuilder {
        this._isEncrypted = isEncrypted
        return this
    }

    build(): Badge {
        return new Badge(this._id, this._picture, this._name, this._description, this._isEncrypted)
    }
}
