import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Badge } from "../../domain/entities/badge/badge";
import { Profile } from "../../domain/entities/profile";
import { AppState } from "../../../redux-configuration/AppState";
import { isRetrievingProfileSelector } from "../../usecases/retrieveProfile/retrieveProfile.selectors";
import { badgesSelector, isRetrievingBadgesSelector } from "../../usecases/retrieveBadges/loadBadges.selectors";
import { profileSelector } from "../../usecases/profile.selector";
import { successUpdateAvatarSelector } from "../../usecases/updateAvatar/updateAvatar.selectors";
import { AvatarType } from "../../domain/entities/types";
import { RetrieveProfileAction } from "../../usecases/retrieveProfile/retrieveProfile.types";
import { retrieveProfile } from "../../usecases/retrieveProfile/retrieveProfile.actions";
import { RetrieveBadgesAction } from "../../usecases/retrieveBadges/loadBadges.types";
import { retrieveBadges } from "../../usecases/retrieveBadges/loadBadges.actions";
import { UpdateAvatarAction } from "../../usecases/updateAvatar/updateAvatar.types";
import ProfileContainer from "./profile.container";
import { updateProfileAvatar } from "../../usecases/updateAvatar/updateAvatar.actions";
import { logEvent } from "../../../shared/usecases/logging/logging.action";
import { AppEvent } from "../../../shared/domain/entities/logging/AppEvent";
import { LogEventAction } from "../../../shared/usecases/logging/analytics.types";

interface StateToPropsType {
    loading: boolean;
    profile: Profile | undefined;

    badges: Badge[] | undefined;
    updateAvatarSucceeded: boolean | undefined;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading              : isRetrievingProfileSelector(state) || isRetrievingBadgesSelector(state),
    profile              : profileSelector(state),
    badges               : badgesSelector(state),
    updateAvatarSucceeded: successUpdateAvatarSelector(state),
})

interface DispatchToPropsType {
    retrieveProfile: () => void;
    retrieveBadges: () => void;
    updateAvatar: (avatarId: AvatarType) => void;
    log: (event: AppEvent) => void;
}


const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    retrieveProfile: (): RetrieveProfileAction => dispatch(retrieveProfile()),
    retrieveBadges : (): RetrieveBadgesAction => dispatch(retrieveBadges()),
    updateAvatar   : (avatarId: AvatarType): UpdateAvatarAction => dispatch(updateProfileAvatar(avatarId)),
    log            : (event: AppEvent): LogEventAction => dispatch(logEvent(event))
})


export const ProfilePage = connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
