import { SAVE_MESSAGE, SAVE_MESSAGE_FAILED, SAVE_MESSAGE_SUCCEEDED, SaveMessageActionTypes } from "./actionTypes";
import { HandleRequestState } from "../../../redux-configuration/AppState";


const initialState: HandleRequestState = {
    loading: false,
    success: undefined,
    error  : undefined
}
export const saveMessageReducer = (state = initialState, action: SaveMessageActionTypes): HandleRequestState => {
    switch (action.type) {
        case SAVE_MESSAGE:
            return {
                loading: true,
                success: undefined,
                error  : undefined
            }
        case SAVE_MESSAGE_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        case SAVE_MESSAGE_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        default:
            return state;
    }
}
