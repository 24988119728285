import { AuthenticateUserState } from "../../config/state";
import {
    AUTHENTICATE_USER,
    AuthenticateUserActionTypes,
    USER_IS_AUTHENTICATED,
    USER_IS_NOT_AUTHENTICATED
} from './actionTypes';

const initialState: AuthenticateUserState = {
    loading       : false,
    isAuthenticated: undefined,
    token          : undefined
}

export const authenticateUserReducer = (state = initialState, action: AuthenticateUserActionTypes): AuthenticateUserState => {
    switch (action.type) {
        case AUTHENTICATE_USER:
            return {
                ...state,
                loading: true
            }
        case USER_IS_NOT_AUTHENTICATED:
            return {
                loading        : false,
                isAuthenticated: false,
                token          : undefined
            }
        case USER_IS_AUTHENTICATED:
            return {
                loading        : false,
                isAuthenticated: true,
                token          : action.payload
            }
        default:
            return state
    }
}
