import { Action, applyMiddleware, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';
import { AppState } from './AppState';
import { epicsMiddleware, rootEpics } from './epicsMiddleware.redux';
import { reduxReducer } from './reducers.redux';


export const reduxStore = (): Store<AppState> => {
    const logger = createLogger({
        collapsed: true,
        level    : 'info',
    })

    const store: Store = createStore<AppState, Action, object, object>(
        reduxReducer,
        applyMiddleware(epicsMiddleware, logger),
    );
    epicsMiddleware.run(rootEpics);

    return store;
};
