import { BadgeIdType } from "../types";

export class Badge {
    constructor(
        protected _id: BadgeIdType,
        protected _picture: string,
        protected _name: string,
        protected _description: string,
        protected _isEncrypted: boolean
    ) {
    }

    get id(): BadgeIdType {
        return this._id;
    }

    get picture(): string {
        return this._picture;
    }

    get name(): string {
        return this._name;
    }

    get description(): string {
        return this._description;
    }

    get isEncrypted(): boolean {
        return this._isEncrypted
    }
}
