import { Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { RetrieveBadgesState } from "../../config/state";
import { ProfileService } from "../../domain/gateway/profile.service";
import { RETRIEVE_BADGES, RetrieveBadgesAction } from "./loadBadges.types";
import { retrieveBadgesFailed, retrieveBadgesSucceeded } from "./loadBadges.actions";
import { Badge } from "../../domain/entities/badge/badge";

export const retrieveBadgesEpic: Epic = (action$: Observable<RetrieveBadgesAction>,
                                     store: StateObservable<RetrieveBadgesState>,
                                     {profileService}: { profileService: ProfileService }) =>
    action$.pipe(
        ofType(RETRIEVE_BADGES),
        switchMap(() => profileService.retrieveBadges()
            .pipe(
                map((badges: Badge[]) =>
                    retrieveBadgesSucceeded(badges)
                ),
                catchError((error: string) => of(retrieveBadgesFailed(error)))
            )
        )
    )
