import React from "react";
import { SpeedQuizDetails } from "../../../../domain/entity/details";
import { SpeedQuizI18n } from "../../../../config/i18n";
import { findAvatar } from "../../../../../profile/config/findAvatar";

interface Props {
    speedQuizDetails: SpeedQuizDetails | undefined
}

export default function SpeedQuizResultHeader(props: Props) {
    return (
        <div className={'result-item result-header d-flex align-items-center justify-content-around'}>
            <div className={'text-center'}>
                <img src={findAvatar(props.speedQuizDetails?.user.image).image}
                     alt={'Profile'}
                     width={80}
                     height={80}/>
                <p>{SpeedQuizI18n.you}</p>
            </div>

            <b>{props.speedQuizDetails?.user.score} : {props.speedQuizDetails?.opponent.score}</b>

            <div className={'text-center'}>
                <img src={findAvatar(props.speedQuizDetails?.opponent.image).image}
                     alt={'Profile'}
                     width={80}
                     height={80}/>
                <p>{props.speedQuizDetails?.opponent.name}</p>
            </div>
        </div>
    )
}
