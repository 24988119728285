import { TeamMate } from "../../domain/entity/teamMate";

export const LOAD_TEAM_MATE = 'LOAD_TEAM_MATE';
export const LOAD_TEAM_MATE_FAILED = 'LOAD_TEAM_MATE_FAILED';
export const LOAD_TEAM_MATE_SUCCEEDED = 'LOAD_TEAM_MATE_SUCCEEDED';

export interface LoadTeamMateAction {
    type: typeof LOAD_TEAM_MATE;
    payload: string;
}

export interface LoadTeamMateFailedAction {
    type: typeof LOAD_TEAM_MATE_FAILED;
    payload: string;
}

export interface LoadTeamMateSucceededAction {
    type: typeof LOAD_TEAM_MATE_SUCCEEDED;
    payload: TeamMate;
}

export type LoadTeamMateActionTypes = LoadTeamMateAction | LoadTeamMateFailedAction | LoadTeamMateSucceededAction
