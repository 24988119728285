import React from "react";
import { useEffect, useState } from "react";
import './components/modals/modal.css';
import { Profile } from "../../../../profile/domain/entities/profile";
import { Message } from "../../../../message/domain/entities/message";
import { Adventure } from "../../../../adventure/domain/entities/adventure";
import { SpeedQuiz } from "../../../../speedQuiz/domain/entity/SpeedQuiz";
import { PlayerScore } from "../../../../score/domain/entities/playerScore";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import LoadingContainer from "../../../../shared/adapters/primaries/loading/loading";
import DashboardHeader from "./components/header";
import MessageItem from "./components/message.item";
import { DashboardMissionList } from "./components/mission.list";
import { DashboardStats } from "./components/stats";
import StartAdventureModal from "./components/modals/adventure/startAdventure.modal";
import SpeedQuizDetailsModal from "../../../../speedQuiz/adapters/primaries/details/details.modal";
import { DashboardI18n } from "../../../config/i18n";
import MessageDetailsModal from "./components/modals/message.modal";
import { AppEvent } from "../../../../shared/domain/entities/logging/AppEvent";
import { AppEventBuilder } from "../../../../shared/domain/entities/logging/appEvent.builder";
import { BeaconsType } from "../../../../shared/domain/entities/logging/beacons.type";


interface Props {
    loading: boolean;
    profile: Profile | undefined
    messages: Message[] | undefined;
    adventures: Adventure[] | undefined;
    speedQuizList: SpeedQuiz[] | undefined;
    playersScore: PlayerScore[] | undefined;
    loadScore: () => void;
    loadBadges: () => void;
    loadMessages: () => void;
    loadAdventures: () => void;
    loadSpeedQuizList: () => void;
    log: (event: AppEvent) => void;
}

export function DashboardContainer(props: Props) {

    const [searchParams] = useSearchParams();
    const pathname = useLocation().pathname;

    const router = useNavigate()
    const [selectedMessage, setSelectedMessage] = useState<Message | undefined>(undefined)
    const [selectedAdventure, setSelectedAdventure] = useState<Adventure | undefined>(undefined)
    const [selectedSpeedQuizId, setSelectedSpeedQuizId] = useState<string | undefined>(undefined)

    useEffect(() => {
        props.loadScore()
        props.loadBadges()
        props.loadMessages()
        props.loadAdventures()
        props.loadSpeedQuizList()
        const event = (new AppEventBuilder())
            .withBeacon(BeaconsType.DISPLAY_DASHBOARD)
            .build()
        props.log(event)
    }, [])

    useEffect(() => {
        const adventure = props.adventures?.find((adv) => adv.id === searchParams.get('adventure'))
        setSelectedAdventure(adventure)
    }, [props.adventures])


    if (props.loading) {
        return <LoadingContainer/>
    }

    return (
        <div className={'dashboard'}>
            <DashboardHeader title={DashboardI18n.dashboard}/>

            {props.messages?.map(message =>
                <MessageItem key={message.id}
                             message={message}
                             onClick={() => setSelectedMessage(message)}/>
            )}

            <div className={'dashboard-blocks'}>
                <div className={'row'}>
                    <DashboardMissionList adventures={props.adventures?.filter(adventure => adventure.status !== 'archived')}
                                          speedQuiz={props.speedQuizList?.filter(speedQuiz => speedQuiz.status !== 'archived')}
                                          onSpeedQuizClick={(id: string) => setSelectedSpeedQuizId(id)}
                                          onAdventureItemClick={(adventure) => {
                                              router(pathname + `?adventure=${adventure.id}`)
                                              setSelectedAdventure(adventure)
                                          }}/>

                    <DashboardStats profile={props.profile}
                                    playersScore={props.playersScore}
                                    adventures={props.adventures?.filter(adventure => adventure.status === 'archived')}
                                    speedQuiz={props.speedQuizList?.filter(speedQuiz => speedQuiz.status === 'archived')}/>
                </div>
            </div>

            {searchParams.has('adventure') && selectedAdventure &&
                <StartAdventureModal onClose={() => router(-1)}
                                     adventure={selectedAdventure}/>}

            <SpeedQuizDetailsModal id={selectedSpeedQuizId}
                                   onClose={() => setSelectedSpeedQuizId(undefined)}/>

            <MessageDetailsModal message={selectedMessage}
                                 onClose={() => setSelectedMessage(undefined)}/>
        </div>
    )
}
