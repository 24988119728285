import React from 'react';
import parse from "html-react-parser";
import { ApplicationContext } from "../../../../../../../shared/config/application.context";
import { Adventure } from "../../../../../../../adventure/domain/entities/adventure";
import { AdventureI18n } from "../../../../../../../adventure/config/i18n";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    subscribeToAdventure
} from "../../../../../../../adventure/usecases/subscribeToAdventure/subscribeToAdventure.actions";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    adventure: Adventure;
    onClose: () => void;
}

const StartAdventureModal = (props: Props) => {

    const dispatch = useDispatch()
    return (
        <div className={"modal modal-details adventure-modal show"} tabIndex={-1} role="dialog"
             onClick={(): void => props.onClose()}>
            <div className="modal-dialog " role="document" style={{minWidth: '50%'}}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <button type="button" className="close" onClick={(): void => props.onClose()}>
                        <span/><span/>
                    </button>

                    <div className="modal-header"/>
                    <div className={'d-flex modal-body flex-column'}>
                        <div className={'d-flex flex-row gap-4'}>
                            <div className={'adventure-metadata'}>
                                <div className={'mb-5'}>
                                    <p>{props.adventure.earns}</p>
                                    <span>{AdventureI18n.redPoint}</span>
                                </div>

                                <div className={'mb-4'}>
                                    <span>{AdventureI18n.from}</span>
                                    <p>{moment(props.adventure.starDate).format('DD/MM/YYYY')}</p>
                                </div>

                                <div className={'mb-4'}>
                                    <span>{AdventureI18n.to}</span>
                                    <p>{moment(props.adventure.endDate).format('DD/MM/YYYY')}</p>
                                </div>

                            </div>

                            <div className={'adventure-data flex-column'}>
                                <h5>{props.adventure.label}</h5>

                                <div className={'description overflow-scroll'}>
                                    <img className={'img-fluid w-100'} src={require('../../../../../../../assets/img/adventure/adventure-spacecraft.png')}
                                         alt={'adventure-preview'}/>
                                    <div>{parse(props.adventure.description,)}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <Link to={"/adventure/" + props.adventure.id} className={'large-blue-button'}
                              onClick={() => dispatch(subscribeToAdventure(props.adventure.id))}>
                            {AdventureI18n.participate}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StartAdventureModal;
