import { MCQService } from "../domain/gateway/MCQ.service";
import { ApiMCQService } from "../adapters/secondaries/real/apiMCQ.service";

export class MCQDependenciesFactory {

    static getMCQService(): MCQService {
        return new ApiMCQService()
    }

}
