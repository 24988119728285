import { CollectiveScore } from '../entities/collectiveScore';

export class CollectiveScoreBuilder {
    protected _code!: string
    protected _agencyName!: string
    protected _regionName!: string
    protected _regionLabel!: string
    protected _score!: number
    protected _rank!: number

    withCode(code: string): CollectiveScoreBuilder {
        this._code = code
        return this
    }

    withAgencyName(value: string): CollectiveScoreBuilder {
        this._agencyName = value
        return this
    }

    withRegionName(value: string): CollectiveScoreBuilder {
        this._regionName = value
        return this
    }

    withRegionLabel(value: string): CollectiveScoreBuilder {
        this._regionLabel = value
        return this
    }

    withScore(score: number): CollectiveScoreBuilder {
        this._score = score
        return this
    }

    withRank(rank: number): CollectiveScoreBuilder {
        this._rank = rank
        return this
    }

    build(): CollectiveScore {
        return new CollectiveScore(
            this._code,
            this._agencyName,
            this._regionName,
            this._regionLabel,
            this._score,
            this._rank
        )
    }
}
