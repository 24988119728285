import {
    SAVE_MESSAGE,
    SAVE_MESSAGE_FAILED,
    SAVE_MESSAGE_SUCCEEDED,
    SaveMessageAction,
    SaveMessageFailedAction,
    SaveMessageSucceededAction
} from "./actionTypes";

export const saveMessage = (messageId: string): SaveMessageAction => ({
    type   : SAVE_MESSAGE,
    payload: messageId
})

export const saveMessageFailed = (error: string): SaveMessageFailedAction => ({
    type   : SAVE_MESSAGE_FAILED,
    payload: error
})

export const saveMessageSucceeded = (): SaveMessageSucceededAction => ({
    type: SAVE_MESSAGE_SUCCEEDED
})
