import { SpeedQuizUserAnswers } from "../../domain/entity/userAnswers";

export const SEND_SPEED_QUIZ_ANSWERS = 'SEND_SPEED_QUIZ_ANSWERS'
export const SEND_SPEED_QUIZ_ANSWERS_FAILED = 'SEND_SPEED_QUIZ_ANSWERS_FAILED'
export const SEND_SPEED_QUIZ_ANSWERS_SUCCEEDED = 'SEND_SPEED_QUIZ_ANSWERS_SUCCEEDED'

export interface SendSpeedQuizAnswersAction {
    type: typeof SEND_SPEED_QUIZ_ANSWERS;
    payload: {
        speedQuizId: string;
        roundId: string;
        answers: SpeedQuizUserAnswers[]
    };
}

export interface SendSpeedQuizAnswersFailedAction {
    type: typeof SEND_SPEED_QUIZ_ANSWERS_FAILED;
    payload: string;
}

export interface SendSpeedQuizAnswersSucceededAction {
    type: typeof SEND_SPEED_QUIZ_ANSWERS_SUCCEEDED;
}

export type SendSpeedQuizAnswersActionTypes =
    SendSpeedQuizAnswersAction
    | SendSpeedQuizAnswersFailedAction
    | SendSpeedQuizAnswersSucceededAction
