import React, { useState } from 'react';
import { MCQQuestion } from "../../../domain/entity/MCQQuestion";
import { MCQI18n } from "../../../config/i18n";
import LoadingContainer from "../../../../shared/adapters/primaries/loading/loading";
import { MCQResponse } from "../../../domain/entity/MCQResponse";

interface Props {
    currentQuestion: MCQQuestion | undefined;
    isLastQuestion: boolean;
    onNextQuestion: (currentQuestionId: number, responses: number[]) => void;
    onSubmit: (currentQuestionId: number, responses: number[]) => void;
}

export const McqQuestionContainer = (props: Props) => {

    const [selectedAnswers, setAnswers] = useState<number[]>([])
    const [hasValidateAnswers, setHasValidateAnswers] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    if (props.currentQuestion) {
        return (
            <div className={'question-item'}>
                {
                    props.currentQuestion.picture &&
                    <img className={'img-fluid mb-4'} src={props.currentQuestion.picture}
                         alt={'mcq-question-image'}/>
                }

                <span>{props.currentQuestion.text}</span>

                <p>{props.currentQuestion.validResponses > 1 ? MCQI18n.manyAnswers : MCQI18n.onlyOneAnswer}</p>

                {loading && <LoadingContainer className={"text-shadow"}/>}
                <div className={'d-flex flex-row justify-content-evenly w-100'}>
                    {
                        props.currentQuestion.responses.map((item) => {
                            return renderResponse(item);
                        })
                    }
                </div>

                {renderBtn()}
            </div>
        );
    }
    return <LoadingContainer/>

    function renderResponse(item: MCQResponse) {
        const selectedButtonStyle = selectedAnswers.indexOf(item.id) > -1 ? ' selected' : ''
        const correctButtonStyle = hasValidateAnswers && item.isCorrect ? ' correct' : ''

        return <button key={item.id}
                       className={`answer-item ${correctButtonStyle} ${selectedButtonStyle}`}
                       onClick={() => chooseAnswer(item)}>
            {item.text}
        </button>
    }

    function chooseAnswer(item: MCQResponse) {
        if (!hasValidateAnswers) {
            if (props.currentQuestion?.validResponses === selectedAnswers.length) {
                setAnswers([item.id]);
            } else if (!selectedAnswers.includes(item.id)) {
                setAnswers([...selectedAnswers, item.id]);
            }
        }
    }

    function renderBtn() {
        const className = selectedAnswers.length === 0 ? ' disabled' : ''

        return !hasValidateAnswers ?
                <button className={'large-blue-button mt-3' + className} disabled={selectedAnswers.length === 0}
                        onClick={() => setHasValidateAnswers(true)}>{MCQI18n.validate}</button>
                :
                props.isLastQuestion ?
                    <button className={'large-blue-button mt-3'}
                            onClick={() => onSubmit()}>{MCQI18n.submit}</button>
                    :
                    <button className={'large-blue-button mt-3'}
                            onClick={() => onNextQuestion()}>{MCQI18n.continue}</button>;
    }

    function onNextQuestion(): void {
        if (props.currentQuestion) {
            props.onNextQuestion(props.currentQuestion.id, selectedAnswers)
            setAnswers([])
            setHasValidateAnswers(false)
        }
    }

    function onSubmit() {
        if (props.currentQuestion) {
            setLoading(true)
            props.onSubmit(props.currentQuestion.id, selectedAnswers)
            setAnswers([])
            setHasValidateAnswers(false)
        }
    }
}
