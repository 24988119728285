import React, { ReactElement } from "react";
import { AdventureSpecifications } from "../../specifications";
import { AdventureI18n } from "../../../../config/i18n";

interface Props {
    value: number;
    activatedClient: number | undefined;
    category: string;
}

export const AdventureBonus = (props: Props): ReactElement => {
    if (props.activatedClient != undefined) {
        const prime = AdventureSpecifications.calculatePrime(props.value, props.category);
        const boost = AdventureSpecifications.calculateBoost(props.activatedClient, prime, props.category);
        return (
            <div className={'box-adventure-info'}>
                <p>{AdventureI18n.estimation}</p>
                <strong>{prime + boost} €</strong>
                <span>{AdventureI18n.depends}</span>
            </div>
        )
    }
    return <React.Fragment/>
}
