import { PlayerScore } from '../entities/playerScore';
import { AvatarType } from "../../../profile/domain/entities/types";


export class PlayerScoreBuilder {
    protected _email!: string
    protected _fullName!: string
    protected _agency!: string
    protected _region!: string
    protected _score!: number
    protected _avatar!: AvatarType
    protected _rank!: number

    withEmail(email: string): PlayerScoreBuilder {
        this._email = email
        return this
    }

    withFullName(name: string): PlayerScoreBuilder {
        this._fullName = name
        return this
    }

    withAgency(agency: string): PlayerScoreBuilder {
        this._agency = agency
        return this
    }

    withRegion(region: string): PlayerScoreBuilder {
        this._region = region
        return this
    }

    withScore(score: number): PlayerScoreBuilder {
        this._score = score
        return this
    }

    withAvatar(avatar: AvatarType): PlayerScoreBuilder {
        this._avatar = avatar
        return this
    }

    withRank(rank: number): PlayerScoreBuilder {
        this._rank = rank
        return this
    }

    build(): PlayerScore {
        return new PlayerScore(
            this._email,
            this._fullName,
            this._agency,
            this._region,
            this._score,
            this._avatar,
            this._rank
        )
    }
}
