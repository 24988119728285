export class AdventureAgencyScore {
    constructor(
        protected _name: string,
        protected _region: string,
        protected _score: number,
        protected _rank: number,
        protected _turnover: number,
        protected _turnoverGoal: number,
        protected _code: string,
        protected _category?: 'A' | 'B' | 'C',
        protected _activatedClient?: number
    ) {
    }

    get name(): string {
        return this._name;
    }

    get region(): string {
        return this._region;
    }

    get score(): number {
        return this._score;
    }

    get rank(): number {
        return this._rank;
    }

    rankSuffix(): string {
        switch (this._rank) {
            case 1:
                 return " er";
            default:
                return " ème";
        }
    }

    get turnover(): number {
        return this._turnover;
    }

    get turnoverGoal(): number {
        return this._turnoverGoal;
    }

    get code(): string {
        return this._code;
    }

    get category(): 'A' | 'B' | 'C' | undefined {
        return this._category;
    }

    get activatedClient(): number | undefined {
        return this._activatedClient;
    }
}
