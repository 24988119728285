export class MCQResponse {
    constructor(
        protected _id: number,
        protected _text: string,
        protected _isCorrect: boolean
    ) {}

    get id(): number {
        return this._id;
    }

    get text(): string {
        return this._text;
    }

    get isCorrect(): boolean {
        return this._isCorrect;
    }
}
