import {
    UPDATE_AVATAR,
    UPDATE_AVATAR_FAILED,
    UPDATE_AVATAR_SUCCEEDED,
    UpdateAvatarActionsTypes
} from './updateAvatar.types';
import { HandleRequestState } from "../../../redux-configuration/AppState";

const initialState: HandleRequestState = {
    loading: false,
    error    : undefined,
    success  : undefined
}
export const updateAvatarReducer = (state = initialState, action: UpdateAvatarActionsTypes): HandleRequestState => {
    switch (action.type) {
        case UPDATE_AVATAR:
            return {
                loading: true,
                error    : undefined,
                success  : undefined
            }
        case UPDATE_AVATAR_FAILED:
            return {
                loading: false,
                error    : action.payload,
                success  : undefined
            }
        case UPDATE_AVATAR_SUCCEEDED:
            return {
                loading: false,
                error    : undefined,
                success  : true
            }
        default:
            return state
    }
}
