import React, { useEffect, useState } from 'react';
import { MCQI18n } from "../../../config/i18n";
import { Adventure } from "../../../../adventure/domain/entities/adventure";
import { MCQScore } from "../../../domain/entity/MCQScore";

interface Props {
    adventure?: Adventure;
    mcqScore: MCQScore | undefined;
    mcqQuestionNumber: number;
    onClick: (redo: boolean) => void;
}

type mcqStatus = 'won' | 'lost' | 'perfectScore' | undefined
export const McqResult = (props: Props) => {

    const [status, setStatus] = useState<mcqStatus>(undefined)

    const message = renderMessage(MCQI18n.perfectScore, MCQI18n.congrats, MCQI18n.youLost)
    const text1 = renderMessage(MCQI18n.perfectScoreMessage1, MCQI18n.winningMessage1, MCQI18n.losingMessage1)
    const text2 = renderMessage(MCQI18n.perfectScoreMessage2, MCQI18n.winningMessage2, MCQI18n.losingMessage2)

    useEffect(() => {
        guessResultStatus()
    }, [props.mcqScore?.score])

    return (
        <div className="mcq-question">
            <div className="overlap-group overflow-scroll">
                <div className={'start-mqc-container'}>
                    <div
                        className={'p-4 description d-flex align-items-center justify-content-center flex-column gap-5'}>
                        <p>{props.adventure?.label}</p>
                        <div className={'d-flex flex-column gap-3 align-items-center'}>
                            <h3>{MCQI18n.result}</h3>
                            <h4>{`${props.mcqScore?.score} / ${props.mcqQuestionNumber}`}</h4>
                            <div>{message}</div>
                        </div>
                        <div>{text1}</div>
                        <div>{text2}</div>
                    </div>
                    {
                        status !== 'lost' ?
                            (<button
                                className={'large-blue-button'}
                                onClick={(): void => props.onClick(false)}>
                                {MCQI18n.continue}
                            </button>)
                            :
                            (<button
                                className={'large-blue-button'}
                                onClick={(): void => props.onClick(true)}>
                                {MCQI18n.doMCQAgain}
                            </button>)
                    }
                </div>
            </div>
        </div>
    );

    function guessResultStatus(): void {
        if (props.mcqScore?.score === props.mcqQuestionNumber) {
            setStatus('perfectScore')
        } else if (props.mcqScore?.canAccessAdventure)
            setStatus('won')
        else
            setStatus('lost')
    }

    function renderMessage(perfect: string, won: string, lost: string): string {
        switch (status) {
            case 'perfectScore':
                return perfect
            case'won':
                return won
            case'lost':
                return lost
            default:
                return ''
        }
    }
};
