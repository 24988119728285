import { AuthenticationService } from '../../../domain/gateway/authentication.service';
import { from, Observable } from 'rxjs'

export class ApiAuthenticationService implements AuthenticationService {

    requestPassword(email: string): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/request-password'
        const form = new FormData()
        form.append('email', email)

        const promise = new Promise<void>((resolve, reject) => {
            fetch(url, {
                method: "POST",
                body  : form
            })
                .then((response) => {
                    if (response.status === 200) {
                        resolve()
                    }
                    reject(response.status.toString())
                })
                .catch(() => reject('server error'))
        })

        return from(promise)
    }

    signInUser(email: string, password: string): Observable<string> {
        const url = process.env.REACT_APP_API_URL + '/v1/signin'

        const form = new FormData()
        form.append('email', email)
        form.append('password', password)

        const promise = new Promise<string>((resolve, reject) => {
            fetch(url, {
                method: "POST",
                body  : form,
            }).then((response) => {
                response.json()
                    .then((rs: { data: { token: string } }) => {
                        if (rs.data) {
                            resolve(rs.data.token)
                        } else {
                            reject('401')
                        }
                    })
                    .catch(() => {
                        reject('500')
                    })
            }).catch(() => {
                reject('500')
            })
        })

        return from(promise)
    }
}
