export enum BeaconsType {
    EVENT_LOGIN_ASK_FOR_PASSWORD   = 'EVENT_LOGIN_ASK_FOR_PASSWORD',
    EVENT_LOGIN_WEB_SUCCESS        = 'EVENT_LOGIN_WEB_SUCCESS',
    EVENT_MCQ_SUBMIT_RESULT        = 'EVENT_MCQ_SUBMIT_RESULT',
    EVENT_SPEED_QUIZ_SUBMIT_RESULT = 'EVENT_SPEED_QUIZ_SUBMIT_RESULT',
    EVENT_SESSION_WEB_START        = 'EVENT_SESSION_WEB_START',
    DISPLAY_DASHBOARD              = 'DISPLAY_DASHBOARD',
    DISPLAY_SCORE                  = 'DISPLAY_SCORE',
    DISPLAY_NEWS                   = 'DISPLAY_NEWS',
    DISPLAY_ADVENTURE              = 'DISPLAY_ADVENTURE',
    DISPLAY_SPEED_QUIZ             = 'DISPLAY_SPEED_QUIZ',
    DISPLAY_TEAM_MATE              = 'DISPLAY_TEAM_MATE',
    DISPLAY_PROFILE                = 'DISPLAY_PROFILE'
}
