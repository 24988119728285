import { SendMCQAnswersState } from "../../config/state";
import {
    SEND_MCQ_ANSWERS,
    SEND_MCQ_ANSWERS_FAILED,
    SEND_MCQ_ANSWERS_SUCCEEDED,
    SendMCQAnswersActionTypes
} from "./actionTypes";

const initialState: SendMCQAnswersState = {
    loading: false,
    score  : undefined,
    error  : undefined
}

export const sendMCQAnswersReducer = (state = initialState, action: SendMCQAnswersActionTypes): SendMCQAnswersState => {
    switch (action.type) {
        case SEND_MCQ_ANSWERS:
            return {
                loading: true,
                score  : undefined,
                error  : undefined
            }
        case SEND_MCQ_ANSWERS_FAILED:
            return {
                loading: false,
                score  : undefined,
                error  : action.payload
            }
        case SEND_MCQ_ANSWERS_SUCCEEDED:
            return {
                loading: false,
                score  : action.payload,
                error  : undefined
            }
        default:
            return state
    }
};
