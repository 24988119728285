import React from 'react';
import { RegionBackground } from "../../../../../shared/adapters/primaries/region.background";
import { Profile } from "../../../../../profile/domain/entities/profile";
import { AdventurePlayerScore } from "../../../../domain/entities/score/adventurePlayerScore";
import { NavLink } from "react-router-dom";
import { findAvatar } from "../../../../../profile/config/findAvatar";

interface Props {
    profile: Profile | undefined;
    playerScore: AdventurePlayerScore;
    onRef: (itemRef: HTMLDivElement | null) => void
}

export const ItinerantRankingItem = (props: Props) => {

    return (
        <div key={props.playerScore.email} className={'ranking-item d-flex align-items-center mt-3'} ref={(r) => {
            if (props.profile?.email === props.playerScore.email) {
                props.onRef(r)
            }
        }}>
            <p className={'rank'}>{props.playerScore.rank}</p>

            <RegionBackground region={props.playerScore.region}/>

            <NavLink className={'mx-3'} to={`/team/${props.playerScore.email}`}>
                <img width={60} className={"img-fluid"} src={findAvatar(props.playerScore.avatar).image}
                     alt={'teammate-avatar'}/>
            </NavLink>

            <div className={"ranking-info"}>
                <p>{props.playerScore.fullName}</p>
                <span>{props.playerScore.region}</span>
            </div>

            <p className={'score'}>{(props.playerScore.score() * 100).toFixed(0)}%</p>
        </div>
    );
};
