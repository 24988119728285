import { SpeedQuizService } from "../../domain/gateway/speedQuiz.service";
import { loadSpeedQuizDetailsFailed, loadSpeedQuizDetailsSucceeded } from "./actions";
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { LOAD_SPEED_QUIZ_DETAILS, LoadSpeedQuizDetailsAction } from './actionTypes';
import { SpeedQuizDetails } from "../../domain/entity/details";
import { SpeedQuizDetailsState } from "../../config/state";

export const loadSpeedQuizDetailsEpic: Epic = (action$: Observable<LoadSpeedQuizDetailsAction>,
                                               store: StateObservable<SpeedQuizDetailsState>,
                                               {speedQuizService}: { speedQuizService: SpeedQuizService }) =>
    action$.pipe(
        ofType(LOAD_SPEED_QUIZ_DETAILS),
        switchMap(action => speedQuizService.loadSpeedQuizDetails(action.payload)
            .pipe(
                map((speedQuiz: SpeedQuizDetails) => loadSpeedQuizDetailsSucceeded(speedQuiz)),
                catchError(err => of(loadSpeedQuizDetailsFailed(err)))
            )
        )
    )
