import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators';
import { ObservableAjaxHttpClient } from "./observableAjaxHttpClient";
import { EventLogger } from "../../domain/gateway/event.logger";
import { AppEvent } from "../../domain/entities/logging/AppEvent";
import { SecuredHttpClient } from "./securedHttpClient";
import { AuthenticationDependenciesFactory } from "../../../authentication/config/dependencies.factory";

export class ApiLogger implements EventLogger {

    securedLog(appEvent: AppEvent): Observable<void> {
        const url = `${process.env.REACT_APP_API_URL}/v1/log/secure`
        const body = new FormData()
        body.append('beacon', appEvent.beacon)
        body.append('value', appEvent.value)
        body.append('references', JSON.stringify(appEvent.references))

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => of(err))
            )

    }

    unsecuredLog(appEvent: AppEvent): Observable<void> {
        const url = `${process.env.REACT_APP_API_URL}/v1/log/unsecure`
        const body = new FormData()
        body.append('beacon', appEvent.beacon)
        body.append('value', appEvent.value)
        body.append('references', JSON.stringify(appEvent.references))

        return new ObservableAjaxHttpClient()
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => of(err))
            )
    }

}
