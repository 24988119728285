import { Epic, ofType, StateObservable } from "redux-observable";
import { Observable, of } from "rxjs";
import { RETRIEVE_PROFILE, RetrieveProfileAction } from "./retrieveProfile.types";
import { HandleRequestState } from "../../../redux-configuration/AppState";
import { ProfileService } from "../../domain/gateway/profile.service";
import { catchError, concatMap, switchMap } from "rxjs/operators";
import { Profile } from "../../domain/entities/profile";
import { setStoreProfile } from "../setStoreProfile/setReduxProfile.actions";
import { retrieveProfileFailed, retrieveProfileSucceeded } from "./retrieveProfile.actions";

export const retrieveProfileEpic: Epic = (action$: Observable<RetrieveProfileAction>,
                                          store: StateObservable<HandleRequestState>,
                                          {profileService}: {
                                              profileService: ProfileService;
                                          }) =>
    action$.pipe(
        ofType(RETRIEVE_PROFILE),
        switchMap(() => profileService.retrieveRemoteProfile()
            .pipe(
                concatMap((profile: Profile) => [
                    setStoreProfile(profile),
                    retrieveProfileSucceeded()
                ]),
                catchError((error: string) => of(retrieveProfileFailed(error)))
            )
        )
    )
