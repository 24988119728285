import { combineEpics } from 'redux-observable';
import { loadAdventureListEpic } from '../usecases/list/epic';
import { loadAdventureDetailsEpic } from "../usecases/details/epic";
import { subscribeToAdventureEpic } from "../usecases/subscribeToAdventure/subscribeToAdventure.epic";
import { loadAdventureScoreEpic } from "../usecases/score/epic";

export const adventureRootEpic = combineEpics(
    loadAdventureListEpic,
    loadAdventureDetailsEpic,
    subscribeToAdventureEpic,
    loadAdventureScoreEpic
)
