export const RETRIEVE_PROFILE = 'RETRIEVE_PROFILE';
export const RETRIEVE_PROFILE_FAILED = 'RETRIEVE_PROFILE_FAILED';
export const RETRIEVE_PROFILE_SUCCEEDED = 'RETRIEVE_PROFILE_SUCCEEDED';


export interface RetrieveProfileAction {
    type: typeof RETRIEVE_PROFILE;
}

export interface RetrieveProfileFailedAction {
    type: typeof RETRIEVE_PROFILE_FAILED;
    payload: string;
}

export interface RetrieveProfileSucceededAction {
    type: typeof RETRIEVE_PROFILE_SUCCEEDED;
}

export type retrieveProfileActionsTypes = RetrieveProfileAction | RetrieveProfileFailedAction | RetrieveProfileSucceededAction
