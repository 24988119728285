import { AdventureAgencyScore } from "./adventureAgencyScore";

export class AdventureAgencyScoreBuilder {
    protected _name!: string
    protected _region!: string
    protected _score!: number
    protected _rank!: number
    protected _turnover!: number
    protected _turnoverGoal!: number
    protected _code!: string
    protected _category?: 'A' | 'B' | 'C'
    protected _activatedClient?: number

    withName(name: string): AdventureAgencyScoreBuilder {
        this._name = name
        return this
    }

    withRegion(region: string): AdventureAgencyScoreBuilder {
        this._region = region
        return this
    }


    withScore(score: number): AdventureAgencyScoreBuilder {
        this._score = score
        return this
    }

    withRank(rank: number): AdventureAgencyScoreBuilder {
        this._rank = rank
        return this
    }

    withTurnover(turnover: number): AdventureAgencyScoreBuilder {
        this._turnover = turnover
        return this
    }

    withTurnoverGoal(turnoverGoal: number): AdventureAgencyScoreBuilder {
        this._turnoverGoal = turnoverGoal
        return this
    }

    withCode(code: string): AdventureAgencyScoreBuilder {
        this._code = code
        return this
    }

    withCategory(value: 'A' | 'B' | 'C'): AdventureAgencyScoreBuilder {
        this._category = value
        return this
    }

    withActivatedClient(value: number): AdventureAgencyScoreBuilder {
        this._activatedClient = value
        return this
    }

    build(): AdventureAgencyScore {
        return new AdventureAgencyScore(
            this._name,
            this._region,
            this._score,
            this._rank,
            this._turnover,
            this._turnoverGoal,
            this._code,
            this._category,
            this._activatedClient
        )
    }
}
