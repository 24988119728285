import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { AppState } from "../../../../redux-configuration/AppState";
import { loadSpeedQuizResultLoadingSelector, speedQuizResultSelector } from "../../../usecases/result/selectors";
import { loadSpeedQuizResult } from "../../../usecases/result/actions";
import { loadSpeedQuizDetails } from "../../../usecases/details/actions";
import SpeedQuizResultScore from "./components/score";
import SpeedQuizResultHeader from "./components/header";
import { SpeedQuizI18n } from "../../../config/i18n";
import LoadingContainer from "../../../../shared/adapters/primaries/loading/loading";
import { useParams } from "react-router-dom";
import { speedQuizDetailsSelector } from "../../../usecases/details/selectors";

export default function SpeedQuizResultContainer() {

    const dispatch = useDispatch()
    const {id} = useParams();

    const loading = useSelector((state: AppState) => loadSpeedQuizResultLoadingSelector(state))
    const result = useSelector((state: AppState) => speedQuizResultSelector(state))
    const speedQuizDetails = useSelector((state: AppState) => speedQuizDetailsSelector(state))

    useEffect(() => {
        if (id) {
            dispatch(loadSpeedQuizDetails(id))
            dispatch(loadSpeedQuizResult(id))
        }
    }, [])

    return (
        <div className={'speed-quiz-result'}>
            <div className={'dashboard-header'}>
                <h1>{SpeedQuizI18n.calculateScore}</h1>
            </div>

            <div className={'item text-center'}>
                <SpeedQuizResultHeader speedQuizDetails={speedQuizDetails}/>

                {loading && (
                    <div className={'loading-container result-item result-score mt-3'}>
                        <LoadingContainer/>
                    </div>
                )}

                {result && <SpeedQuizResultScore result={result}/>}
            </div>
        </div>
    )
}
