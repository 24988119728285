import { AvatarType } from "../../domain/entities/types";
import {
    UPDATE_AVATAR,
    UPDATE_AVATAR_FAILED,
    UPDATE_AVATAR_SUCCEEDED,
    UpdateAvatarAction,
    UpdateAvatarFailedAction,
    UpdateAvatarSucceededAction
} from "./updateAvatar.types";

export const updateProfileAvatar = (avatarId: AvatarType): UpdateAvatarAction => ({
    type   : UPDATE_AVATAR,
    payload: avatarId
})

export const updateAvatarFailed = (error: string): UpdateAvatarFailedAction => ({
    type   : UPDATE_AVATAR_FAILED,
    payload: error
})

export const updateAvatarSucceeded = (): UpdateAvatarSucceededAction => ({
    type: UPDATE_AVATAR_SUCCEEDED
})
