import { AdventureService } from '../../../domain/gateway/adventure.service';
import { Observable, throwError } from 'rxjs';
import { Adventure } from '../../../domain/entities/adventure';
import { AdventureMapper } from './mapper/adventure.mapper';
import { catchError, map } from 'rxjs/operators'
import { AdventureDto, AdventureScoreDto } from "./dto/adventure.dto";
import { SecuredHttpClient } from "../../../../shared/adapters/secondaries/securedHttpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/config/dependencies.factory";
import { AdventureScore } from "../../../domain/entities/score/adventureScore";

export class ApiAdventureService implements AdventureService {

    loadAdventureList(): Observable<Adventure[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/adventure'

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: AdventureDto[] }>(url)
            .pipe(
                map(response => AdventureMapper.mapAdventureList(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    loadAdventureScore(adventureId: string): Observable<AdventureScore> {
        const url = `${process.env.REACT_APP_API_URL}/v1/adventure/${adventureId}/score`
        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: AdventureScoreDto }>(url)
            .pipe(
                map(response => AdventureMapper.mapAdventureScoreDtoToAdventureScore(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    subscribeToAdventure(adventureId: string): Observable<void> {
        const url = `${process.env.REACT_APP_API_URL}/v1/adventure/${adventureId}/subscribe`
        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.message))
            )
    }

}
