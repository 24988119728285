import { SpeedQuizAnswer } from "../entity/answer";

export class SpeedQuizAnswerBuilder {
    protected _id!: string
    protected _text!: string
    protected _isCorrect!: boolean
    protected _isOpponentAnswer!: boolean

    withId(value: string): SpeedQuizAnswerBuilder {
        this._id = value
        return this
    }

    withText(value: string): SpeedQuizAnswerBuilder {
        this._text = value
        return this
    }

    withIsCorrect(value: boolean): SpeedQuizAnswerBuilder {
        this._isCorrect = value
        return this
    }

    withIsOpponentAnswer(value: boolean): SpeedQuizAnswerBuilder {
        this._isOpponentAnswer = value
        return this
    }

    build(): SpeedQuizAnswer {
        return new SpeedQuizAnswer(
            this._id,
            this._text,
            this._isCorrect,
            this._isOpponentAnswer
        )
    }
}
