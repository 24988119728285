import { combineEpics, Epic } from 'redux-observable';
import { requestPasswordEpic } from "../usecases/requestPassword/epic";
import { loginUserEpic } from "../usecases/login/epic";
import { logoutUserEpic } from "../usecases/logout/epic";
import { authenticateUserEpic } from "../usecases/authenticateUser/epic";

export const authenticationRootEpic: Epic = combineEpics(
    requestPasswordEpic,
    loginUserEpic,
    logoutUserEpic,
    authenticateUserEpic
)
