import {
    LOAD_ADVENTURE_LIST,
    LOAD_ADVENTURE_LIST_FAILED,
    LOAD_ADVENTURE_LIST_SUCCEEDED,
    LoadAdventureListActionTypes
} from './actionTypes';
import { LoadAdventureListState } from '../../config/state';

const initialState: LoadAdventureListState = {
    loading   : false,
    adventures: undefined,
    error     : undefined,
}
export const loadAdventureListReducer = (state = initialState, action: LoadAdventureListActionTypes): LoadAdventureListState => {
    switch (action.type) {
        case LOAD_ADVENTURE_LIST:
            return {
                loading   : true,
                adventures: undefined,
                error     : undefined,
            }
        case LOAD_ADVENTURE_LIST_FAILED:
            return {
                loading   : false,
                adventures: undefined,
                error     : action.payload,
            }
        case LOAD_ADVENTURE_LIST_SUCCEEDED:
            return {
                loading   : false,
                adventures: action.payload,
                error     : undefined,
            }
        default:
            return state
    }
}
