import { StoreProfileState } from "../../config/state";
import { SET_STORE_PROFILE, SetStoreProfileAction } from "./setStoreProfile.types";

const initialState: StoreProfileState = {
    profile: undefined
}

export const setStoreProfileReducer = (state = initialState, action: SetStoreProfileAction): StoreProfileState => {
    switch (action.type) {
        case SET_STORE_PROFILE:
            return {profile: action.payload}
        default:
            return state
    }
}
