import { from, mergeMap, Observable, of } from "rxjs";
import { MessageRepository } from "../../../domain/gateway/message.repository";

export class LocalStorageMessageRepository implements MessageRepository {

    saveMessage(messageId: string): Observable<void> {
        return this.findMessages()
            .pipe(
                mergeMap((ids) => {
                    const currentMessageId = ids.find(id => id === messageId)
                    if (!currentMessageId) {
                        const newArray = [...ids, messageId]
                        localStorage.setItem('messages', JSON.stringify(newArray))
                    }
                    return of(void 0)
                })
            )
    }

    findMessages(): Observable<string[]> {
        const getMessagesIdsPromise = new Promise<string[]>((resolve) => {
            const messages = localStorage.getItem('messages')
            if (messages) {
                resolve(JSON.parse(messages))
            } else {
                resolve([])
            }
        })
        return from(getMessagesIdsPromise)
    }

}
