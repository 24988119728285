import { MCQQuestion } from "../../../../domain/entity/MCQQuestion";
import { MCQQuestionBuilder } from "../../../../domain/entity/MCQQuestion.builder";
import { MCQResponse } from "../../../../domain/entity/MCQResponse";

export class MCQSpecifications {
    static shuffleQuestions(questions: MCQQuestion[]): MCQQuestion[] {
        for (let i = questions.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const shuffledQuestion = new MCQQuestionBuilder()
                .withId(questions[j].id)
                .withText(questions[j].text)
                .withPicture(questions[j].picture)
                .withDescription(questions[j].description)
                .withValidResponses(questions[j].validResponses)
                .withResponses(this.shuffleResponses(questions[j].responses))
                .build()
            const temp = questions[i];
            questions[i] = shuffledQuestion;
            questions[j] = temp;
        }
        return questions
    }

    static shuffleResponses(responses: MCQResponse[]): MCQResponse[] {
        for (let i = responses.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = responses[i];
            responses[i] = responses[j];
            responses[j] = temp;
        }
        return responses
    }
}
