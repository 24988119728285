import { Observable, of } from 'rxjs';
import { logoutUserFailed, logoutUserSucceeded } from './actions';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators'
import { AuthenticationRepository } from '../../domain/gateway/authentication.repository';
import { LOGOUT_USER, LogoutUserAction } from './actionTypes';
import { AppState } from "../../../redux-configuration/AppState";

export const logoutUserEpic: Epic = (action$: Observable<LogoutUserAction>,
                                     store: StateObservable<AppState>,
                                     {authenticationRepository}: {
                                         authenticationRepository: AuthenticationRepository;
                                     }) =>
    action$.pipe(
        ofType(LOGOUT_USER),
        switchMap(() => authenticationRepository.removeAuthenticationToken()
            .pipe(
                map(() => logoutUserSucceeded()),
                catchError((error: string) => of(logoutUserFailed(error)))
            )
        )
    )
