export class CollectiveScore {
    constructor(
        protected _code: string,
        protected _agencyName: string,
        protected _regionName: string,
        protected _regionLabel: string,
        protected _score: number,
        protected _rank: number
    ) {}

    get code(): string {
        return this._code;
    }

    get agencyName(): string {
        return this._agencyName;
    }

    get regionName(): string {
        return this._regionName;
    }

    get regionLabel(): string {
        return this._regionLabel;
    }

    get score(): number {
        return this._score;
    }

    get rank(): number {
        return this._rank;
    }
}
