import React from "react";
import { ScoreI18n } from "../../../config/i18n";
import GlobalScoreItem from "./item";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux-configuration/AppState";
import { agenciesScoreSelector, regionsScoreSelector } from "../../../usecases/selectors";
import { profileSelector } from "../../../../profile/usecases/profile.selector";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

export default function CollectiveScoreList() {

    const profile = useSelector((state: AppState) => profileSelector(state))
    const regions = useSelector((state: AppState) => regionsScoreSelector(state))
    const agencies = useSelector((state: AppState) => agenciesScoreSelector(state))

    let regionScoreIndex: HTMLDivElement | null = null
    let agencyScoreIndex: HTMLDivElement | null = null


    const userRegionScore = regions?.find(item => item.regionLabel === profile?.region)
    const userAgencyScore = agencies?.find(item => item.code === profile?.agencyCode)


    const handleScrollAction = (scoreIndex: HTMLDivElement | null) => {
        if (scoreIndex) {
            scoreIndex.scrollIntoView({
                behavior: "smooth",
                block   : "center",
            })

            const currentClassName = scoreIndex.getAttribute('class')
            scoreIndex.setAttribute('class', currentClassName + ' selected-item')
        }
    }

    return (
        <div className={'col-6'}>
            <div className={'item'}>
                <div className={'d-flex justify-content-between mb-4'}>
                    <h3 className={'score-title'}>{ScoreI18n.collectiveScore}</h3>
                </div>

                <Tabs>
                    <TabList className={'d-flex p-0'}>
                        <Tab className={'tab-header'}>{ScoreI18n.withRegion}</Tab>
                        <Tab className={'tab-header'}>{ScoreI18n.withAgency}</Tab>
                    </TabList>

                    <TabPanel>
                        {userRegionScore && <button className={'score-btn'}
                                                    onClick={() => handleScrollAction(regionScoreIndex)}>{ScoreI18n.you}</button>}

                        <div className={'overflow-scroll'}>
                            {regions?.map((item) =>
                                <GlobalScoreItem key={item.regionLabel}
                                                 isUser={item.regionLabel === profile?.region}
                                                 rank={item.rank}
                                                 title={item.regionLabel}
                                                 subTitle={item.regionName}
                                                 region={item.regionLabel}
                                                 score={item.score}
                                                 onRef={(ref) => regionScoreIndex = ref}/>
                            )}
                            {
                                regions?.length as number === 0 && <div className={'no-data'}>{ScoreI18n.no_data}</div>
                            }
                        </div>
                    </TabPanel>

                    <TabPanel>
                        {userAgencyScore && <button className={'score-btn'}
                                                    onClick={() => handleScrollAction(agencyScoreIndex)}>{ScoreI18n.you}</button>}

                        <div className={'overflow-scroll'}>
                            {agencies?.map((item) =>
                                <GlobalScoreItem key={item.code}
                                                 isUser={item.agencyName === profile?.agency}
                                                 rank={item.rank}
                                                 title={item.agencyName}
                                                 subTitle={item.regionLabel}
                                                 region={item.regionLabel}
                                                 score={item.score}
                                                 onRef={(ref) => agencyScoreIndex = ref}/>
                            )}
                            {
                                agencies?.length as number === 0 && <div className={'no-data'}>{ScoreI18n.no_data}</div>
                            }

                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}
