import { AdventureScore } from "../../domain/entities/score/adventureScore";

export const LOAD_ADVENTURE_SCORE = 'LOAD_ADVENTURE_SCORE'
export const LOAD_ADVENTURE_SCORE_FAILED = 'LOAD_ADVENTURE_SCORE_FAILED'
export const LOAD_ADVENTURE_SCORE_SUCCEEDED = 'LOAD_ADVENTURE_SCORE_SUCCEEDED'

export interface LoadAdventureScoreAction {
    type: typeof LOAD_ADVENTURE_SCORE;
    payload: string;
}

export interface LoadAdventureScoreFailedAction {
    type: typeof LOAD_ADVENTURE_SCORE_FAILED;
    payload: string;
}

export interface LoadAdventureScoreSucceededAction {
    type: typeof LOAD_ADVENTURE_SCORE_SUCCEEDED;
    payload: AdventureScore;
}

export type LoadAdventureScoreActionTypes =
    LoadAdventureScoreAction
    | LoadAdventureScoreFailedAction
    | LoadAdventureScoreSucceededAction
