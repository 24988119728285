import React from 'react';
import GalaxyIcon from "../../../../shared/adapters/primaries/icons/galaxy.icon";
import { Profile } from "../../../../profile/domain/entities/profile";
import { ScoreI18n } from "../../../config/i18n";

interface Props {
    profile: Profile;
    rank: number
}

const GlobalScoreUserInfo = (props: Props) => {
    return (
        <div className="score-info d-flex">
            <div className={'info-card'}>
                <GalaxyIcon className={'icon'} width={24}/>
                <p>{props.profile.region}</p>
            </div>

            <div className={'ms-3 info-card'}>
                <p>{props.profile.agency}</p>
            </div>

            {props.rank > 0 && (
                <div className={'ms-3 info-card'}>
                    <p>{props.rank} {props.rank === 1 ? ScoreI18n.ere : ScoreI18n.eme}</p>
                </div>
            )}
        </div>
    );
};

export default GlobalScoreUserInfo;
