import React, { useEffect, useState } from "react"
import { Adventure } from "../../domain/entities/adventure";
import { Profile } from "../../../profile/domain/entities/profile";
import { AdventureScore } from "../../domain/entities/score/adventureScore";
import LoadingContainer from "../../../shared/adapters/primaries/loading/loading";
import AdventureHeader from "./components/adventure.header";
import DashboardSection from "./components/dashboard.section";
import { RankingSection } from "./components/ranking.section";
import './adventure-details.css';
import '../../../dashboard/adapters/primaries/dashboard/components/modals/modal.css';
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { MCQ } from "../../../MCQ/domain/entity/MCQ";
import { AdventureI18n } from "../../config/i18n";
import { AdventureDescriptionModal } from "./components/modal/adventureDescription.modal";
import { AppEvent } from "../../../shared/domain/entities/logging/AppEvent";
import { AppEventBuilder } from "../../../shared/domain/entities/logging/appEvent.builder";
import { BeaconsType } from "../../../shared/domain/entities/logging/beacons.type";


interface Props {
    adventure: Adventure | undefined;
    profile: Profile | undefined;
    adventureScore: AdventureScore | undefined;
    mcq: MCQ | undefined;
    loadAdventure: (id: string) => void;
    log: (event: AppEvent) => void;
}

export const AdventureDetailsContainer = (props: Props) => {

    const router = useNavigate()
    const {adventureId} = useParams();

    const [showModal, setShowModal] = useState<boolean>(false)

    useEffect(() => {
        if (adventureId !== undefined) {
            if (props.adventure?.id !== adventureId) {
                props.loadAdventure(adventureId)
                const event = (new AppEventBuilder())
                    .withBeacon(BeaconsType.DISPLAY_ADVENTURE)
                    .withReferences([{key: 'adventureId', value: adventureId}])
                    .build()
                props.log(event)
            }
        } else {
            router('/404')
        }

    }, [])

    if (props.adventure && props.adventureScore && props.mcq) {
        if (props.mcq.canAccessAdventure) {
            return (
                <div className="adventure-details">
                    <div className="overlap-group">

                        <AdventureHeader adventure={props.adventure}/>

                        <div className={'adventure-data d-flex'}>
                            <DashboardSection adventure={props.adventure} adventureScore={props.adventureScore}
                                              profile={props.profile}
                            />
                            <div className={'d-flex flex-column justify-content-between'} style={{flex: 1}}>
                                <RankingSection missionType={props.adventure.type} profile={props.profile}
                                                agenciesScores={props.adventureScore?.agenciesScore}
                                                playersScores={props.adventureScore?.playersScore}
                                />

                                <div className={" d-flex flex-row btn-container gap-3"}>
                                    <button className={'large-blue-button'}
                                            onClick={() => redirectToMcq()}>{AdventureI18n.replayQcm}</button>
                                    <button className={'large-dark-button'}
                                            onClick={() => setShowModal(true)}>{AdventureI18n.readDescription}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showModal &&
                        <AdventureDescriptionModal onClose={() => setShowModal(false)} adventure={props.adventure}/>}
                </div>
            );
        }

        return <Navigate to={`/adventure/${props.adventure?.id}/mcq/${props.mcq?.id}`}/>
    }

    function redirectToMcq(): void {
        router(`/adventure/${props.adventure?.id}/mcq/${props.mcq?.id}`)
    }

    return <LoadingContainer/>
}
