import { combineReducers } from "redux";
import { loadSpeedQuizListReducer } from "../usecases/list/reducer";
import { loadSpeedQuizDetailsReducer } from "../usecases/details/reducer";
import { loadSpeedQuizQuestionsReducer } from "../usecases/questions/reducer";
import { sendSpeedQuizAnswersReducer } from "../usecases/send/reducer";
import { loadSpeedQuizResultReducer } from "../usecases/result/reducer";


export const speedQuizRootReducer = combineReducers({
    list     : loadSpeedQuizListReducer,
    details  : loadSpeedQuizDetailsReducer,
    questions: loadSpeedQuizQuestionsReducer,
    send     : sendSpeedQuizAnswersReducer,
    result   : loadSpeedQuizResultReducer
})
