import React from "react";
import { Adventure } from "../../../../../adventure/domain/entities/adventure";
import { SpeedQuiz } from "../../../../../speedQuiz/domain/entity/SpeedQuiz";
import { DashboardI18n } from "../../../../config/i18n";
import SpeedQuizItem from "./speedQuiz.item";
import AdventureItem from "./adventure.item";
import { Link } from "react-router-dom";

interface Props {
    adventures: Adventure[] | undefined;
    speedQuiz: SpeedQuiz[] | undefined;
    onSpeedQuizClick: (id: string) => void;
    onAdventureItemClick: (adventure: Adventure) => void;
}

export function DashboardMissionList(props: Props) {
    return (
        <div className={'col-6'}>
            <div className={'item'}>
                <div className={'d-flex justify-content-between mb-3'}>
                    <h3 className={'mission-title'}>{DashboardI18n.lastMissions}</h3>
                    <Link to={'/missions'} className={'mission-btn'}>{DashboardI18n.seeMore}</Link>
                </div>

                <div className={'overflow-scroll'}>
                    {props.speedQuiz?.map(item => <SpeedQuizItem key={item.id}
                                                                 speedQuiz={item}
                                                                 onClick={() => props.onSpeedQuizClick(item.id)}/>)}

                    {props.adventures?.map(item => <AdventureItem onClick={() => props.onAdventureItemClick(item)} key={item.id} adventure={item}/>)}
                </div>
            </div>
        </div>
    )
}
