import { combineReducers, Reducer } from 'redux';
import { AuthenticationState } from "./state";
import { requestPasswordReducer } from "../usecases/requestPassword/reducer";
import { loginUserReducer } from "../usecases/login/reducer";
import { logoutUserReducer } from "../usecases/logout/reducer";
import { authenticateUserReducer } from "../usecases/authenticateUser/reducer";

export const authenticationRootReducer: Reducer = combineReducers<AuthenticationState>({
    requestPassword : requestPasswordReducer,
    login           : loginUserReducer,
    logout          : logoutUserReducer,
    authenticateUser: authenticateUserReducer
})
