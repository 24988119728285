import React from 'react';
import { Badge } from "../../../domain/entities/badge/badge";
import { Link } from "react-router-dom";

export const BadgesList = (props: { badges: Badge[] }) => {
    return (
        <div className={"badges-list"}>
            <div className="hex-row">
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        <Link to={`/profile/?badge=${props.badges[0].id}`}>
                            <img alt={props.badges[0].name} src={props.badges[0].picture}/>
                        </Link>
                    </div>
                    <div className="bottom"/>
                </div>

                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        <Link to={`/profile/?badge=${props.badges[1].id}`}>
                            <img alt={props.badges[1].name} src={props.badges[1].picture}/>
                        </Link>
                    </div>
                    <div className="bottom"/>
                </div>

                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        <Link to={`/profile/?badge=${props.badges[2].id}`}>
                            <img alt={props.badges[2].name} src={props.badges[2].picture}/>
                        </Link>
                    </div>
                    <div className="bottom"/>
                </div>
            </div>

            <div className="hex-row even">
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        <Link to={`/profile/?badge=${props.badges[3].id}`}>
                            <img alt={props.badges[3].name} src={props.badges[3].picture}/>
                        </Link>
                    </div>
                    <div className="bottom"/>
                </div>

                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        <Link to={`/profile/?badge=${props.badges[4].id}`}>
                            <img alt={props.badges[4].name} src={props.badges[4].picture}/>
                        </Link>
                    </div>
                    <div className="bottom"/>
                </div>
            </div>

            <div className="hex-row">
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        <Link to={`/profile/?badge=${props.badges[5].id}`}>
                            <img alt={props.badges[5].name} src={props.badges[5].picture}/>
                        </Link>
                    </div>
                    <div className="bottom"/>
                </div>

                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        <Link to={`/profile/?badge=${props.badges[6].id}`}>
                            <img alt={props.badges[6].name} src={props.badges[6].picture}/>
                        </Link>
                    </div>
                    <div className="bottom"/>
                </div>

                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        <Link to={`/profile/?badge=${props.badges[7].id}`}>
                            <img alt={props.badges[7].name} src={props.badges[7].picture}/>
                        </Link>
                    </div>
                    <div className="bottom"/>
                </div>
            </div>

            <div className="hex-row even">
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        <Link to={`/profile/?badge=${props.badges[8].id}`}>
                            <img alt={props.badges[8].name} src={props.badges[8].picture}/>
                        </Link>
                    </div>
                    <div className="bottom"/>
                </div>

                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        <Link to={`/profile/?badge=${props.badges[9].id}`}>
                            <img alt={props.badges[9].name} src={props.badges[9].picture}/>
                        </Link>
                    </div>
                    <div className="bottom"/>
                </div>
            </div>
        </div>
    );
};
