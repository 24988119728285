import {
    CATCH_MESSAGE,
    CATCH_MESSAGE_FAILED,
    CATCH_MESSAGE_SUCCEEDED,
    CatchMessageAction,
    CatchMessageFailedAction,
    CatchMessageSucceededAction
} from "./actionTypes";

export const catchMessage = (messageId: string): CatchMessageAction => ({
    type   : CATCH_MESSAGE,
    payload: messageId
})

export const catchMessageFailed = (error: string): CatchMessageFailedAction => ({
    type   : CATCH_MESSAGE_FAILED,
    payload: error
})

export const catchMessageSucceeded = (): CatchMessageSucceededAction => ({
    type: CATCH_MESSAGE_SUCCEEDED
})
