import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AdventureAgencyScore } from "../../../../domain/entities/score/adventureAgencyScore";
import { Profile } from "../../../../../profile/domain/entities/profile";
import { AdventureI18n } from "../../../../config/i18n";
import { AgencyRankingItem } from "../../components/rankingItems/agencyRankingItem";

interface Props {
    agenciesScores: AdventureAgencyScore[] | undefined
    profile: Profile | undefined
}

export const AgencyStandardRanking = (props: Props) => {
    let currentProfileItemRef: HTMLDivElement | null = null

    const allAgencies = props.agenciesScores?.map((item, index) => {
        return <AgencyRankingItem key={item.code}
                                  item={item}
                                  rank={index + 1}
                                  profile={props.profile}
                                  onRef={(ref => currentProfileItemRef = ref)}/>
    })

    const myRegionAgency = props.agenciesScores?.filter((item) => item.region === props.profile?.region)
        .map((item, index) => {
            return <AgencyRankingItem key={item.code}
                                      rank={index + 1}
                                      item={item}
                                      profile={props.profile}
                                      onRef={(ref => currentProfileItemRef = ref)}/>
        })

    return (
        <div className={'data-section'}>
            <div className={'d-flex align-items-center w-100 h-100 justify-content-between'}>
                <h2>{AdventureI18n.rank}</h2>
                <a className={'you-btn'} onClick={() => handleAction()}>
                    {AdventureI18n.you}
                </a>
            </div>

            <Tabs className={'d-flex flex-column p-0 overflow-hidden flex-grow-1'}>
                <TabList className={'d-flex flex-row p-0'}>
                    <Tab className={'tab-header'}>{AdventureI18n.allAgencies}</Tab>
                    <Tab className={'tab-header'}>{AdventureI18n.myRegionAgencies}</Tab>
                </TabList>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {allAgencies}
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {myRegionAgency}
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );

    function handleAction() {
        if (currentProfileItemRef) {
            currentProfileItemRef.scrollIntoView({
                behavior: "smooth",
                block   : "center",
                inline  : "start"
            })
            const currentClassName = currentProfileItemRef.getAttribute('class')
            currentProfileItemRef.setAttribute('class', currentClassName + ' selected-item')
        }
    }
};
