import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import '../speedQuiz.css';
import { AppState } from "../../../../redux-configuration/AppState";
import { SpeedQuizRound } from "../../../domain/entity/round";
import { SpeedQuizQuestion } from "../../../domain/entity/question";
import { SpeedQuizUserAnswers } from "../../../domain/entity/userAnswers";
import { loadSpeedQuizQuestions } from "../../../usecases/questions/actions";
import { sendSpeedQuizAnswers } from "../../../usecases/send/actions";
import { SpeedQuizUserAnswersBuilder } from "../../../domain/builder/userAnswers.builder";
import { SpeedQuizAnswer } from "../../../domain/entity/answer";
import LoadingContainer from "../../../../shared/adapters/primaries/loading/loading";
import SpeedQuizQuestionsHeader from "./components/header";
import SpeedQuizQuestionProgressBar from "./components/progressBar";
import SpeedQuizQuestionText from "./components/question.text";
import SpeedQuizQuestionAnswers from "./components/question.answers";
import { useNavigate, useParams } from "react-router-dom";
import { sendSpeedQuizAnswersLoadingSelector, sendSpeedQuizAnswersSuccessSelector } from "../../../usecases/send/selectors";
import { loadSpeedQuizQuestionsLoadingSelector, speedQuizQuestionsSelector } from "../../../usecases/questions/selectors";
import { speedQuizDetailsSelector } from "../../../usecases/details/selectors";
import SpeedQuizDetailsModal from "../details/details.modal";

export default function SpeedQuizQuestionsContainer() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams();

    const success = useSelector((state: AppState) => sendSpeedQuizAnswersSuccessSelector(state))
    const isSendingAnswers = useSelector((state: AppState) => sendSpeedQuizAnswersLoadingSelector(state))
    const isLoadingQuestions = useSelector((state: AppState) => loadSpeedQuizQuestionsLoadingSelector(state))
    const questions = useSelector((state: AppState) => speedQuizQuestionsSelector(state))
    const speedQuizDetails = useSelector((state: AppState) => speedQuizDetailsSelector(state))

    const [currentRound, setCurrentRound] = useState<SpeedQuizRound | undefined>(undefined)
    const [currentQuestion, setCurrentQuestion] = useState<SpeedQuizQuestion | undefined>(undefined)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0)
    const [isTimeOver, setTimeIsOver] = useState<boolean>(false)
    const [answers, setAnswers] = useState<SpeedQuizUserAnswers[]>([])
    const [speedQuizId, setSpeedQuizId] = useState<string | undefined>(undefined)

    const getCurrentRoundQuestions = () => {
        const round = speedQuizDetails?.rounds.find(round => round.id === speedQuizDetails?.nextRoundId)

        if (round && id) {
            setCurrentRound(round)
            dispatch(loadSpeedQuizQuestions(id, round.id))
        }
    }

    useEffect(() => {
        getCurrentRoundQuestions()
    }, [])

    useEffect(() => {
        if (questions) {
            setCurrentQuestion(questions[0])
        }
    }, [questions])

    useEffect(() => {
        if (questions.length === answers.length && answers.length > 0 && currentRound && id) {
            dispatch(sendSpeedQuizAnswers(id, currentRound.id, answers))
        }
    }, [answers])

    useEffect(() => {
        if (success) {
            setSpeedQuizId(id);
        }
    }, [success])

    const getNextQuestion = (selectedAnswers: string[]) => {
        if (currentQuestion) {
            const currentQuestionAnswers = new SpeedQuizUserAnswersBuilder()
                .withQuestionId(currentQuestion.id)
                .withAnswers(selectedAnswers)

            if (selectedAnswers.length === 0) {
                const allAnswers = currentQuestion.answers.map((answer: SpeedQuizAnswer) => answer.id)
                currentQuestionAnswers.withAnswers(allAnswers)
            }

            setAnswers([...answers, currentQuestionAnswers.build()])

            if (questions[currentQuestionIndex + 1]) {
                setCurrentQuestion(questions[currentQuestionIndex + 1])
                setCurrentQuestionIndex(currentQuestionIndex + 1)
                setTimeIsOver(false)
            }
        }
    }

    return (
        <div className={'speed-quiz-question'}>
            {isSendingAnswers && <LoadingContainer/>}

            <SpeedQuizQuestionsHeader title={currentRound?.title}
                                      category={currentRound?.category}/>

            {isLoadingQuestions && (
                <div className={'loading-container item'}>
                    <LoadingContainer/>
                </div>
            )}

            {currentQuestion && (
                <div className={'item text-center'}>
                    <SpeedQuizQuestionProgressBar time={currentQuestion.time}
                                                  isTimeOver={isTimeOver}
                                                  setTimeIsOver={(): void => setTimeIsOver(true)}/>

                    <SpeedQuizQuestionText question={currentQuestion}/>

                    <SpeedQuizQuestionAnswers question={currentQuestion}
                                              isTimeOver={isTimeOver}
                                              opponent={speedQuizDetails?.opponent}
                                              onClick={(answers: string[]): void => getNextQuestion(answers)}/>
                </div>
            )}

            <SpeedQuizDetailsModal id={speedQuizId}
                                   onClose={() => {
                                       setSpeedQuizId(undefined)
                                       navigate('/dashboard')
                                   }}
                                   onClick={() => {
                                       getCurrentRoundQuestions()
                                       setSpeedQuizId(undefined)
                                       setCurrentQuestion(undefined)
                                       setAnswers([])
                                       setTimeIsOver(false)
                                       setCurrentQuestionIndex(0)
                                   }}/>
        </div>
    )
}
