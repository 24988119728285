import { AvatarType } from "../../../profile/domain/entities/types";

export class SpeedQuizPlayer {
    constructor(
        protected _name: string,
        protected _score: string,
        protected _email: string,
        protected _image: AvatarType,
        protected _category: string
    ) {}

    get name(): string {
        return this._name;
    }

    get score(): string {
        return this._score;
    }

    get email(): string {
        return this._email;
    }

    get image(): AvatarType {
        return this._image;
    }

    get category(): string {
        return this._category;
    }
}
