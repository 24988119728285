import { AvatarType } from "../../../profile/domain/entities/types";


export class PlayerScore {
    constructor(
       protected _email: string,
       protected _fullName: string,
       protected _agency: string,
       protected _region: string,
       protected _score: number,
       protected _avatar: AvatarType,
       protected _rank: number
    ){
    }

    get email(): string {
        return this._email;
    }

    get fullName(): string {
        return this._fullName;
    }

    get agency(): string {
        return this._agency;
    }

    get region(): string {
        return this._region;
    }

    get score(): number {
        return this._score;
    }

    get avatar(): AvatarType {
        return this._avatar;
    }

    get rank(): number {
        return this._rank;
    }

}
