import {
    LOAD_SPEED_QUIZ_QUESTIONS,
    LOAD_SPEED_QUIZ_QUESTIONS_FAILED,
    LOAD_SPEED_QUIZ_QUESTIONS_SUCCEEDED,
    LoadSpeedQuizQuestionsAction,
    LoadSpeedQuizQuestionsFailedAction,
    LoadSpeedQuizQuestionsSucceededAction
} from "./actionTypes";
import { SpeedQuizQuestion } from "../../domain/entity/question";

export const loadSpeedQuizQuestions = (speedQuizId: string, roundId: string): LoadSpeedQuizQuestionsAction => ({
    type   : LOAD_SPEED_QUIZ_QUESTIONS,
    payload: {speedQuizId, roundId}
})

export const loadSpeedQuizQuestionsFailed = (error: string): LoadSpeedQuizQuestionsFailedAction => ({
    type   : LOAD_SPEED_QUIZ_QUESTIONS_FAILED,
    payload: error
})

export const loadSpeedQuizQuestionsSucceeded = (questions: SpeedQuizQuestion[]): LoadSpeedQuizQuestionsSucceededAction => ({
    type   : LOAD_SPEED_QUIZ_QUESTIONS_SUCCEEDED,
    payload: questions
})
