import React from 'react';
import parse from "html-react-parser";
import { ApplicationContext } from "../../../../../shared/config/application.context";
import { Profile } from "../../../../../profile/domain/entities/profile";
import { AdventureScore } from "../../../../domain/entities/score/adventureScore";
import { AdventureAgencyScore } from "../../../../domain/entities/score/adventureAgencyScore";
import { AdventureI18n } from "../../../../config/i18n";
import { AdventureSpecifications } from "../../specifications";
import { AdventureBonus } from "./bonus";
import { agencyCategories } from "../../constants";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    profile: Profile | undefined;
    adventureScore: AdventureScore | undefined;
}

const InterAgenceAdventureDashboard = (props: Props) => {
    const getAgencyScore = (): AdventureAgencyScore | undefined => {
        const agencyCode = props.profile?.agencyCode
        return props.adventureScore?.agenciesScore.find(item => item.code === agencyCode)
    }

    const agencyScore = getAgencyScore();
    const category = agencyScore?.category
    if (agencyScore && category !== undefined) {
        return (
            <React.Fragment>
                <div className={'flex-box'}>
                    <div className={'box-adventure-info'}>
                        <p>{AdventureI18n.turnover}</p>
                        <strong>{AdventureSpecifications.formatNumber(agencyScore.turnover, ' ', ' €')}</strong>
                        <span> sur {AdventureSpecifications.formatNumber(agencyScore.turnoverGoal, ' ', ' €')} en {moment().subtract(1, 'year').format('Y')}</span>
                    </div>

                    <div className={'box-adventure-info'}>
                        <p>{AdventureI18n.evolution}</p>
                        <strong>+ {calculateEvolution()} %</strong>
                        {parse(renderPrimeText())}
                    </div>

                    <div className={'box-100'}>
                        <div className={'adventure-progress'}>
                            <div className="progress-title">
                                <span>{AdventureI18n.result_interAgence(moment().subtract(1, 'year').year())}</span>
                            </div>

                            <div className="progressbar">
                                <div className={'progress-bg'}>
                                    <div className="actual-progress"
                                         style={{
                                             width: AdventureSpecifications.agencyScorePercentage(agencyScore)
                                                 .replace(' ', '')
                                         }}/>
                                </div>
                                <div className="actual-progress-val">
                                    {AdventureSpecifications.agencyScorePercentage(agencyScore)}
                                </div>
                            </div>
                            <div className={'d-flex justify-content-between fex-row progress-bar-values'}>
                                <p>{AdventureSpecifications.formatNumber(agencyScore.turnover, ' ', ' €')}</p>
                                <p>{AdventureSpecifications.formatNumber(agencyScore.turnoverGoal, ' ', ' €')}</p>
                            </div>
                        </div>
                    </div>

                    <AdventureBonus category={category}
                                    value={calculateEvolution()}
                                    activatedClient={agencyScore.activatedClient}/>

                    <div className={'box-adventure-info'}>
                        <p>{AdventureI18n.cm}</p>
                        <strong>{agencyScore.activatedClient}</strong>
                        <span>{parse(renderBoostText())}</span>
                    </div>


                    <div className={'box-100 p-3'}>
                        <p className={'paragraph'}>{parse(AdventureI18n.primeDescription(getPrimes(category)))}</p>
                    </div>

                    <div className={'box-100 p-3'}>
                        <p className={'paragraph'}>{parse(AdventureI18n.boostDescription(getBoost(category)))}</p>
                    </div>
                </div>
            </React.Fragment>
        )
    } else {
        return (
            <div className={'box-100 px-3 py-5'}>
                <p>{parse(AdventureI18n.notInTheGame)}</p>
            </div>
        );
    }

    function calculateEvolution(): number {
        if (agencyScore && agencyScore.score >= 1) {
            return (agencyScore.score * 10 * 10) - 100
        }
        return 0
    }

    function renderPrimeText(): string {
        const score = calculateEvolution();

        if (10 <= score && score < 15) {
            return `<span class="text-success">${AdventureI18n.prime10}<span/>`
        } else if (15 <= score && score < 20) {
            return `<span class="text-success">${AdventureI18n.prime15}<span/>`
        } else if (20 <= score) {
            return `<span class="text-success">${AdventureI18n.prime20}<span/>`
        }
        return `<span>${AdventureI18n.prime0}<span/>`
    }

    function renderBoostText(): string {
        const clients = agencyScore?.activatedClient ?? 0
        const score = calculateEvolution();
        const category = agencyScore?.category ?? '';

        if ((category === 'A' || category === 'B' || category === 'C') && score >= 10) {
            if (agencyCategories[category].boostMin[0] <= clients && clients < agencyCategories[category].boostMax[0]) {
                return `<span class="text-success">${AdventureI18n.boost10}</span>`
            } else if (agencyCategories[category].boostMin[1] <= clients && clients < agencyCategories[category].boostMax[1]) {
                return `<span class="text-success">${AdventureI18n.boost15}</span>`
            } else if (agencyCategories[category].boostMin[2] <= clients) {
                return `<span class="text-success">${AdventureI18n.boost20}</span>`
            }
        }
        return `<span>${AdventureI18n.boost0}</span>`
    }

    function getBoost(cat: 'A' | 'B' | 'C'): { boostMin: number[]; boostMax: number[]; } {
        return {boostMin: agencyCategories[cat].boostMin, boostMax: agencyCategories[cat].boostMax}
    }

    function getPrimes(cat: 'A' | 'B' | 'C'): number[] {
        return agencyCategories[cat].prime
    }
};

export default InterAgenceAdventureDashboard;
