import { MCQResponse } from "./MCQResponse";

export class MCQResponseBuilder {
    protected _id!: number
    protected _text!: string
    protected _isCorrect!: boolean

    withId(value: number): MCQResponseBuilder {
        this._id = value
        return this
    }

    withText(value: string): MCQResponseBuilder {
        this._text = value
        return this
    }

    withIsCorrect(value: boolean): MCQResponseBuilder {
        this._isCorrect = value
        return this
    }

    build(): MCQResponse {
        return new MCQResponse(
            this._id,
            this._text,
            this._isCorrect
        )
    }
}
