import { LoadAdventureScoreState } from "../../config/state";
import {
    LOAD_ADVENTURE_SCORE,
    LOAD_ADVENTURE_SCORE_FAILED,
    LOAD_ADVENTURE_SCORE_SUCCEEDED,
    LoadAdventureScoreActionTypes
} from "./actionTypes";

const initialState: LoadAdventureScoreState = {
    isLoading: false,
    error    : undefined,
    score    : undefined
}
export const loadAdventureScoreReducer = (state = initialState, action: LoadAdventureScoreActionTypes): LoadAdventureScoreState => {
    switch (action.type) {
        case LOAD_ADVENTURE_SCORE:
            return {
                isLoading: true,
                error    : undefined,
                score    : undefined
            }
        case LOAD_ADVENTURE_SCORE_FAILED:
            return {
                isLoading: false,
                error    : action.payload,
                score    : undefined
            }
        case LOAD_ADVENTURE_SCORE_SUCCEEDED:
            return {
                isLoading: false,
                error    : undefined,
                score    : action.payload
            }
        default:
            return state
    }
}
