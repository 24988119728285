import { AvatarType } from "../../../../profile/domain/entities/types";
import { AdventurePlayerScore } from "./adventurePlayerScore";

export class AdventurePlayerScoreBuilder {
    protected _rank!: number;
    protected _email!: string;
    protected _fullName!: string;
    protected _avatar!: AvatarType;
    protected _agency!: string;
    protected _region!: string;
    protected _turnover!: number;
    protected _turnoverGoal!: number;
    protected _activatedClient!: number;
    protected _activatedClientGoal!: number;


    withRank(rank: number): AdventurePlayerScoreBuilder {
        this._rank = rank
        return this
    }

    withEmail(email: string): AdventurePlayerScoreBuilder {
        this._email = email
        return this
    }

    withFullName(fullName: string): AdventurePlayerScoreBuilder {
        this._fullName = fullName
        return this
    }

    withAvatar(avatar: AvatarType): AdventurePlayerScoreBuilder {
        this._avatar = avatar
        return this
    }

    withAgency(agency: string): AdventurePlayerScoreBuilder {
        this._agency = agency
        return this
    }

    withRegion(region: string): AdventurePlayerScoreBuilder {
        this._region = region
        return this
    }

    withTurnover(turnover: number): AdventurePlayerScoreBuilder {
        this._turnover = turnover
        return this
    }

    withTurnoverGoal(turnoverGoal: number): AdventurePlayerScoreBuilder {
        this._turnoverGoal = turnoverGoal
        return this
    }

    withActivatedClient(activatedClient: number): AdventurePlayerScoreBuilder {
        this._activatedClient = activatedClient
        return this
    }

    withActivatedClientGoal(activatedClientGoal: number): AdventurePlayerScoreBuilder {
        this._activatedClientGoal = activatedClientGoal
        return this
    }

    build(): AdventurePlayerScore {
        return new AdventurePlayerScore(this._rank,
            this._email,
            this._fullName,
            this._avatar,
            this._agency,
            this._region,
            this._turnover,
            this._turnoverGoal,
            this._activatedClient,
            this._activatedClientGoal)
    }
}
