import React from "react";
import { SpeedQuizSpecifications } from "../../../../../speedQuiz/adapters/primaries/specifications";
import { SpeedQuiz } from "../../../../../speedQuiz/domain/entity/SpeedQuiz";
import { findAvatar } from "../../../../../profile/config/findAvatar";
import { DashboardI18n } from "../../../../config/i18n";
import { RocketIcon } from "../../../../../shared/adapters/primaries/icons/rocket.icon";
import { CalendarIcon } from "../../../../../shared/adapters/primaries/icons/calendar.icon";

interface Props {
    speedQuiz: SpeedQuiz;
    onClick: () => void;
}

export default function MissionSpeedQuizItem(props: Props) {
    return (
        <div className={'mission-item d-flex align-items-center'}>
            {props.speedQuiz.opponent.image ?
                <img src={findAvatar(props.speedQuiz.opponent.image).image}
                     alt={'SpeedQuiz'}
                     width={110}
                     height={110}/> :
                <img src={props.speedQuiz.backgroundImage}
                     alt={'SpeedQuiz'}
                     width={110}
                     height={110}/>}

            <div className={'content'}>
                <h2>{props.speedQuiz.title}</h2>

                <div className={'d-flex'}>
                    <div className={'mission-score d-flex align-items-center'}>
                        <RocketIcon isSelected={() => true} width={20} height={17}/>
                        <span>{props.speedQuiz.user.score}</span>
                    </div>

                    <div className={'mission-date d-flex align-items-center'}>
                        <CalendarIcon/>
                        <span>{SpeedQuizSpecifications.getSpeedQuizCountDownTimer(props.speedQuiz.endDate)}</span>
                    </div>
                </div>

                <div className={'d-flex align-items-center mt-3'}>
                    <span className={'progress-text'}>{SpeedQuizSpecifications.minutesLeftInPercentage(props.speedQuiz)}</span>

                    <div className={'mission-progress-bar'}>
                        <div className={'mission-progress'} style={{width: SpeedQuizSpecifications.minutesLeftInPercentage(props.speedQuiz)}}></div>
                    </div>
                </div>
            </div>

            <button onClick={() => props.onClick()}>{DashboardI18n.start}</button>
        </div>
    )
}
