import React from 'react';

const PinIcon = (props: { className?: string }) => {
    return (
        <div className={props.className}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_403_1919" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
                    <rect x="0.954102" y="0.244141" width="24" height="24" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_403_1919)">
                    <path d="M12.9539 20.0189C14.7872 18.4523 16.2664 16.7981 17.3914 15.0564C18.5164 13.3148 19.0789 11.8189 19.0789 10.5689C19.0789 8.73561 18.4997 7.21895 17.3414 6.01895C16.1831 4.81895 14.7206 4.21895 12.9539 4.21895C11.1872 4.21895 9.72474 4.81895 8.56641 6.01895C7.40807 7.21895 6.82891 8.73561 6.82891 10.5689C6.82891 11.8189 7.39141 13.3148 8.51641 15.0564C9.64141 16.7981 11.1206 18.4523 12.9539 20.0189ZM12.9539 20.5689C12.8206 20.5689 12.6872 20.5481 12.5539 20.5064C12.4206 20.4648 12.2956 20.3939 12.1789 20.2939C11.5789 19.7439 10.9289 19.0898 10.2289 18.3314C9.52891 17.5731 8.87474 16.7689 8.26641 15.9189C7.65807 15.0689 7.14974 14.1856 6.74141 13.2689C6.33307 12.3523 6.12891 11.4523 6.12891 10.5689C6.12891 8.56894 6.78307 6.89811 8.09141 5.55645C9.39974 4.21478 11.0206 3.54395 12.9539 3.54395C14.8872 3.54395 16.5081 4.21478 17.8164 5.55645C19.1247 6.89811 19.7789 8.56894 19.7789 10.5689C19.7789 11.4523 19.5747 12.3481 19.1664 13.2564C18.7581 14.1648 18.2539 15.0481 17.6539 15.9064C17.0539 16.7648 16.4039 17.5689 15.7039 18.3189C15.0039 19.0689 14.3539 19.7189 13.7539 20.2689C13.6432 20.3689 13.5174 20.4439 13.3766 20.4939C13.2357 20.5439 13.0948 20.5689 12.9539 20.5689ZM12.9583 11.8939C13.372 11.8939 13.7247 11.7466 14.0164 11.452C14.3081 11.1574 14.4539 10.8032 14.4539 10.3895C14.4539 9.9758 14.3066 9.62311 14.012 9.33144C13.7174 9.03978 13.3632 8.89395 12.9495 8.89395C12.5358 8.89395 12.1831 9.04125 11.8914 9.33587C11.5997 9.63049 11.4539 9.98465 11.4539 10.3984C11.4539 10.8121 11.6012 11.1648 11.8958 11.4564C12.1904 11.7481 12.5446 11.8939 12.9583 11.8939Z" fill="white"/>
                </g>
            </svg>
        </div>
    );
};

export default PinIcon;
