import { AppState } from "../../../redux-configuration/AppState";
import { Epic, ofType, StateObservable } from "redux-observable";
import { Observable, of } from "rxjs";
import { LOGIN_USER, LoginUserAction } from "./actionTypes";
import { AuthenticationService } from "../../domain/gateway/authentication.service";
import { AuthenticationRepository } from "../../domain/gateway/authentication.repository";
import { catchError, concatMap, switchMap } from "rxjs/operators";
import { authenticateUser } from "../authenticateUser/actions";
import { loginUserFailed, loginUserSucceeded } from "./actions";
import { BeaconsType } from "../../../shared/domain/entities/logging/beacons.type";
import { logEvent } from "../../../shared/usecases/logging/logging.action";
import { AppEventBuilder } from "../../../shared/domain/entities/logging/appEvent.builder";

export const loginUserEpic: Epic = (action$: Observable<LoginUserAction>,
                                    store: StateObservable<AppState>,
                                    {authenticationService, authenticationRepository}: {
                                        authenticationService: AuthenticationService;
                                        authenticationRepository: AuthenticationRepository;
                                    }) =>
    action$.pipe(
        ofType(LOGIN_USER),
        switchMap(action => authenticationService.signInUser(action.payload.email, action.payload.password)
            .pipe(
                concatMap((token: string) => authenticationRepository.saveAuthenticationToken(token)
                    .pipe(
                        concatMap(() => [
                            authenticateUser(),
                            logEvent(
                                (new AppEventBuilder())
                                    .withBeacon(BeaconsType.EVENT_LOGIN_WEB_SUCCESS)
                                    .withValue(action.payload.email)
                                    .withSecured(false)
                                    .build()
                            ),
                            loginUserSucceeded()
                        ]),
                        catchError((error: string) => of(loginUserFailed(error)))
                    )
                ), catchError(error => of(loginUserFailed(error)))
            )
        )
    )
