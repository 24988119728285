import { from, Observable } from "rxjs";
import axios from "axios";

export class ObservableAjaxHttpClient {

    get<R>(url: string): Observable<R> {
        const promise = new Promise<R>((resolve, reject) => {
            axios.get<R>(url)
                .then((rs) => resolve(rs.data))
                .catch((reason) => reject(reason))
        })
        return from(promise)
    }

    post<T>(url: string, body?: FormData): Observable<T> {
        const promise = new Promise<T>((resolve, reject) => {
            axios.post<T>(url, body)
                .then((rs) => resolve(rs.data))
                .catch((reason) => reject(reason))
        })
        return from(promise)
    }
}
