import { AdventurePlayerScore } from "./adventurePlayerScore";
import { AdventureAgencyScore } from "./adventureAgencyScore";
import { AdventureScore } from "./adventureScore";

export class AdventureScoreBuilder {
    protected _playersScore!: AdventurePlayerScore[];
    protected _agenciesScore!: AdventureAgencyScore[];
    protected _totalTurnover!: number;
    protected _totalTurnoverGoal!: number;
    protected _totalActivatedClient!: number;
    protected _totalActivatedClientGoal!: number;

    withPlayersScore(playersScore: AdventurePlayerScore[]): AdventureScoreBuilder {
        this._playersScore = playersScore
        return this
    }

    withAgenciesScore(agenciesScore: AdventureAgencyScore[]): AdventureScoreBuilder {
        this._agenciesScore = agenciesScore
        return this
    }

    withTotalTurnover(turnover: number): AdventureScoreBuilder {
        this._totalTurnover = turnover
        return this
    }

    withTotalTurnoverGoal(turnoverGoal: number): AdventureScoreBuilder {
        this._totalTurnoverGoal = turnoverGoal
        return this
    }

    withTotalActivatedClient(activatedClient: number): AdventureScoreBuilder {
        this._totalActivatedClient = activatedClient
        return this
    }

    withTotalActivatedClientGoal(activatedClientGoal: number): AdventureScoreBuilder {
        this._totalActivatedClientGoal = activatedClientGoal
        return this
    }

    build(): AdventureScore {
        return new AdventureScore(this._playersScore, this._agenciesScore, this._totalTurnover, this._totalTurnoverGoal, this._totalActivatedClient, this._totalActivatedClientGoal)
    }
}
