import { SpeedQuizService } from '../../domain/gateway/speedQuiz.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { LOAD_SPEED_QUIZ_QUESTIONS, LoadSpeedQuizQuestionsAction } from './actionTypes';
import { loadSpeedQuizQuestionsFailed, loadSpeedQuizQuestionsSucceeded } from './actions';
import { SpeedQuizQuestionsState } from "../../config/state";
import { SpeedQuizQuestion } from "../../domain/entity/question";


export const loadSpeedQuizQuestionsEpic: Epic = (action$: Observable<LoadSpeedQuizQuestionsAction>,
                                                 store: StateObservable<SpeedQuizQuestionsState>,
                                                 {speedQuizService}: { speedQuizService: SpeedQuizService }) =>
    action$.pipe(
        ofType(LOAD_SPEED_QUIZ_QUESTIONS),
        switchMap(action =>
            speedQuizService.loadSpeedQuizQuestions(action.payload.speedQuizId, action.payload.roundId)
                .pipe(
                    map((questions: SpeedQuizQuestion[]) => loadSpeedQuizQuestionsSucceeded(questions)),
                    catchError(err => of(loadSpeedQuizQuestionsFailed(err)))
                )
        )
    );
