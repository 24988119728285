import React from "react"
import { SpeedQuizRound } from "../../../../domain/entity/round";
import { SpeedQuizResponseType } from "../../../../domain/types";

interface Props {
    round: SpeedQuizRound
}

export default function SpeedQuizRoundItem(props: Props) {

    function renderAnswerItem(answer: SpeedQuizResponseType, key: string) {
        let answerClassName = "rectangle"
        if (answer === 'good') {
            answerClassName = answerClassName + " green"
        } else if (answer === 'wrong') {
            answerClassName = answerClassName + " red"
        }
        return <div key={key} className={answerClassName}/>
    }

    const roundClassName = props.round.status ? "round-details active" : "round-details"

    return (
        <div className="round d-flex justify-content-between align-items-center mt-5 mb-3 text-center">
            <div className="round-user">
                {props.round.userResponse.map((item, index) => renderAnswerItem(item, 'user-' + index))}
            </div>

            <div className={roundClassName}>
                <p>{props.round.title}</p>
                <span>{props.round.category}</span>
            </div>

            <div className="round-opponent">
                {props.round.opponentResponse.map((item, index) => renderAnswerItem(item, 'opponent-' + index))}
            </div>
        </div>
    )
}
