import { SpeedQuizUserAnswers } from "../entity/userAnswers";

export class SpeedQuizUserAnswersBuilder {
    protected _questionId!: string
    protected _answers!: string[]

    withQuestionId(value: string): SpeedQuizUserAnswersBuilder {
        this._questionId = value
        return this
    }

    withAnswers(value: string[]): SpeedQuizUserAnswersBuilder {
        this._answers = value
        return this
    }

    build(): SpeedQuizUserAnswers {
        return new SpeedQuizUserAnswers(
            this._questionId,
            this._answers
        )
    }
}
