import { LoadTeamMateState } from '../../config/state';
import {
    LOAD_TEAM_MATE,
    LOAD_TEAM_MATE_FAILED,
    LOAD_TEAM_MATE_SUCCEEDED,
    LoadTeamMateActionTypes
} from "./actionTypes";

const initialState: LoadTeamMateState = {
    loading : false,
    teamMate: undefined,
    error   : undefined
}

export const loadTeamMateReducer = (state = initialState, action: LoadTeamMateActionTypes): LoadTeamMateState => {
    switch (action.type) {
        case LOAD_TEAM_MATE:
            return {
                loading : true,
                teamMate: undefined,
                error   : undefined
            }
        case LOAD_TEAM_MATE_FAILED:
            return {
                loading : false,
                teamMate: undefined,
                error   : action.payload
            }
        case LOAD_TEAM_MATE_SUCCEEDED:
            return {
                loading : false,
                teamMate: action.payload,
                error   : undefined
            }
        default:
            return state;
    }
}
