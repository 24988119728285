import React from 'react';
import { RegionBackground } from "../../../../../shared/adapters/primaries/region.background";
import { Profile } from "../../../../../profile/domain/entities/profile";
import { AdventureAgencyScore } from "../../../../domain/entities/score/adventureAgencyScore";

interface Props {
    profile: Profile | undefined;
    item: AdventureAgencyScore;
    rank: number;
    onRef: (itemRef: HTMLDivElement | null) => void
}

export const AgencyRankingItem = (props: Props) => {

    return (
        <div className={'ranking-item d-flex align-items-center mt-3'} ref={(r) => {
            if (props.profile?.agencyCode === props.item.code) {
                props.onRef(r)
            }
        }}>
            <p className={'rank'}>{props.rank}</p>

            <RegionBackground region={props.item.region}/>

            <div className={"ranking-info mx-3"}>
                <p>{props.item.name}</p>
                <span>{props.item.region}</span>
            </div>

            <p className={'score'}>{(props.item.score * 100).toFixed(2)}%</p>
        </div>
    );
};
