import { SpeedQuizQuestion } from "../../domain/entity/question";

export const LOAD_SPEED_QUIZ_QUESTIONS = 'LOAD_SPEED_QUIZ_QUESTIONS'
export const LOAD_SPEED_QUIZ_QUESTIONS_FAILED = 'LOAD_SPEED_QUIZ_QUESTIONS_FAILED'
export const LOAD_SPEED_QUIZ_QUESTIONS_SUCCEEDED = 'LOAD_SPEED_QUIZ_QUESTIONS_SUCCEEDED'
export interface LoadSpeedQuizQuestionsAction {
    type: typeof LOAD_SPEED_QUIZ_QUESTIONS;
    payload: {
        speedQuizId: string;
        roundId: string
    };
}

export interface LoadSpeedQuizQuestionsFailedAction {
    type: typeof LOAD_SPEED_QUIZ_QUESTIONS_FAILED;
    payload: string;
}

export interface LoadSpeedQuizQuestionsSucceededAction {
    type: typeof LOAD_SPEED_QUIZ_QUESTIONS_SUCCEEDED;
    payload: SpeedQuizQuestion[];
}

export type LoadSpeedQuizQuestionsActionTypes =
    LoadSpeedQuizQuestionsAction
    | LoadSpeedQuizQuestionsFailedAction
    | LoadSpeedQuizQuestionsSucceededAction
