export const MCQI18n = {
    onlyOneAnswer       : '\n\n(1 seule réponse possible)',
    manyAnswers         : '\n\n(Plusieurs réponses possibles)',
    start               : 'Commencer Le QCM',
    submit              : 'Envoyer ',
    continue            : 'Continuer',
    validate            : 'Valider',
    doMCQAgain          : 'Refaire le Quiz',
    earnedPoints        : 'Points gangnés',
    bonusPoints         : 'Points bonus',
    question            : 'Question',
    result              : 'Résultat',
    congrats            : 'Félicitations',
    youLost             : 'C\'est perdu',
    perfectScore        : 'WOW, C\'est le score parfait !!',
    losingMessage1      : 'Pas de panique vous pouvez recommencer ce quiz pour améliorer votre score.',
    losingMessage2      : 'N\'hésitez pas à relire les instructions de la mission, des indices vous y attendent peut-etre.',
    winningMessage1     : 'Vous maitrisez à présent les prérequis pour mener à bien la mission.',
    winningMessage2     : 'Vous pouvez améliorer votre score à tout moment pour gagner plus de points',
    perfectScoreMessage1: 'Vous maitrisez à présent les prérequis pour mener à bien la mission.',
    perfectScoreMessage2: 'Vous avez gagné des points bonus pour votre performance exemplaire.',
};
