export const AdventureI18n = {
    finished          : 'Terminé',
    redPoint          : 'blue points',
    from              : 'Du',
    to                : 'au',
    participate       : 'participer',
    turnover          : 'Chiffre d’affaires',
    turnoverGoal      : 'Objectif Chiffre d’affaires',
    cm                : 'Clients réactivés',
    cmGoal            : 'Objectif clients mvts',
    of                : 'sur',
    result            : 'Résultat / Objectif',
    result_interAgence: (year: string): string => `Résultat / CA ${year}`,
    you               : 'Vous',
    rank              : 'Classement',
    yourResult        : 'Votre résultat',
    evolution         : `Évolution ${new Date().getFullYear()}`,
    prime0            : '*Prime agence à gagner',
    prime10           : '*Prime agence attribuée (+10%)',
    prime15           : '*Prime agence attribuée (+15%)',
    prime20           : '*Prime agence attribuée (+20%)',
    boost0            : '*Boost prime agence à gagner',
    boost10           : '*Boost prime agence de +10%',
    boost15           : '*Boost prime agence de +15%',
    boost20           : '*Boost prime agence de +20%',
    estimation        : 'Estimation prime agence',
    depends           : 'Dépend de l\'évolution',
    allAgencies       : 'Toutes les agences',
    myRegionAgencies  : 'Agences de ma région',
    allTCI            : 'Tous les TCI',
    myRegionTCI       : 'TCI de ma région',
    replayQcm         : 'Refaire le Quiz',
    readDescription   : 'Lire la description',
    back              : 'Retour',
    category         : (category?: string): string => `Agence CAT. " ${category} "`,
    notInTheGame      : 'Votre établissement ne fait pas partie de cette aventure<br/> si vous trouvez que cette information est fausse<br/>merci de contacter l`équipe technique',
    primeDescription  : (primes: number[]): string =>
        '*PRIME : si vous augmentez significativement le chiffre d’affaires de votre agence, une prime agence sera attribuée:<br/>' +
        `- ${primes[0]}€ pour une augmentation de +10%<br/>` +
        `- ${primes[1]}€ pour une augmentation de +15%<br/>` +
        `- ${primes[2]}€ pour une augmentation de +20%<br/>`,
    boostDescription  : (arg: { boostMin: number[], boostMax: number[] }): string =>
        '*BOOST : vos gains sont boostés en fonction du nombre de clients réactivés (clients dont le CA ou le nombre de devis est égal à 0 depuis le 1ier janvier)<br/>' +
        `- de ${arg.boostMin[0]} à ${arg.boostMax[0]} clients = +10%<br/>` +
        `- de ${arg.boostMin[1]} à ${arg.boostMax[1]} Clients = +15%<br/>` +
        `- à partir de ${arg.boostMin[2]} clients = +20%`
}
