import React, { useEffect, useState } from "react";
import { SpeedQuizQuestion } from "../../../../domain/entity/question";
import { SpeedQuizPlayer } from "../../../../domain/entity/player";
import SpeedQuizAnswerItem from "./answer.item";
import { SpeedQuizI18n } from "../../../../config/i18n";


interface Props {
    question: SpeedQuizQuestion;
    isTimeOver: boolean;
    opponent: SpeedQuizPlayer | undefined;
    onClick: (answers: string[]) => void
}

export default function SpeedQuizQuestionAnswers(props: Props) {

    const [selectedAnswers, setSelectedAnswers] = useState<string[]>([])

    const onAnswerClick = (answerId: string) => {
        if (props.question.numberOfCorrectAnswers === 1 || selectedAnswers.length === 0) {
            setSelectedAnswers([answerId])
        } else {
            const currentAnswers = [...selectedAnswers]
            const index = currentAnswers.indexOf(answerId)

            if (index > -1) {
                currentAnswers.splice(index, 1)
                setSelectedAnswers(currentAnswers)
            } else {
                setSelectedAnswers([...currentAnswers, answerId])
            }
        }
    }

    useEffect(() => {
        if (!props.isTimeOver && selectedAnswers.length > 0) {
            setSelectedAnswers([])
        }
    }, [props.isTimeOver])

    const enabled = props.isTimeOver || selectedAnswers.length > 0
    const className = enabled ? '' : ' disabled'

    return (
        <div>
            <div className={'question-item'}>
                {props.question.answers.map(answer =>
                    <SpeedQuizAnswerItem key={answer.id}
                                         answer={answer}
                                         isTimeOver={props.isTimeOver}
                                         selectedAnswers={selectedAnswers}
                                         opponentImage={props.opponent?.image}
                                         onClick={(): void => onAnswerClick(answer.id)}/>
                )}
            </div>

            <button disabled={!enabled}
                    className={'large-blue-button mt-3' + className}
                    onClick={(): void => props.onClick(selectedAnswers)}>
                {SpeedQuizI18n.next}
            </button>
        </div>
    )
}
