import React from 'react';
import { badgeList } from "../../../../profile/config/badges.list";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BadgeDetailsModal } from "../../../../profile/adapters/primaries/badge/badgeDetailsModal";
import { BadgeBuilder } from "../../../../profile/domain/entities/badge/badge.builder";
import { Badge } from "../../../../profile/domain/entities/badge/badge";

export const TeamMateBadgesList = (props: { badgeIds: string[] | undefined }) => {

    const [searchParams] = useSearchParams()
    const router = useNavigate()

    const badgeType = badgeList.find((item) => item.id === searchParams.get('badge'))
    let badge: Badge | undefined
    if (badgeType) {
        badge = (new BadgeBuilder())
            .withId(badgeType.id)
            .withIsEncrypted(false)
            .withDescription(badgeType.description)
            .withName(badgeType.title)
            .withPicture(badgeType.picture)
            .build()
    }

    return (
        <div className={"mt-4 m-auto"}>
            <div className="hex-row">
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        {
                            props.badgeIds?.find((id) => id === badgeList[0].id) ?
                                <img width={100} height={100} alt={badgeList[0].title} src={badgeList[0].picture}
                                     className={'images-mm'}/>
                                :
                                undefined
                        }
                    </div>
                    <div className="bottom"/>
                </div>
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        {
                            props.badgeIds?.find((id) => id === badgeList[1].id) ?
                                <img width={100} height={100} alt={badgeList[1].title} src={badgeList[1].picture}
                                     className={'images-mm'}/> :
                                undefined
                        }
                    </div>
                    <div className="bottom"/>
                </div>
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        {
                            props.badgeIds?.find((id) => id === badgeList[2].id) ?
                                <img width={100} height={100} alt={badgeList[2].title} src={badgeList[2].picture}
                                     className={'images-mm'}/> :
                                undefined
                        }
                    </div>
                    <div className="bottom"/>
                </div>
            </div>
            <div className="hex-row even">
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        {
                            props.badgeIds?.find((id) => id === badgeList[3].id) ?
                                <img width={100} height={100} alt={badgeList[3].title} src={badgeList[3].picture}
                                     className={'images-mm'}/> :
                                undefined
                        }
                    </div>
                    <div className="bottom"/>
                </div>
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        {
                            props.badgeIds?.find((id) => id === badgeList[4].id) ?
                                <img width={100} height={100} alt={badgeList[4].title} src={badgeList[4].picture}
                                     className={'images-mm'}/> :
                                undefined
                        }
                    </div>
                    <div className="bottom"/>
                </div>
            </div>
            <div className="hex-row">
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        {
                            props.badgeIds?.find((id) => id === badgeList[5].id) ?
                                <img width={100} height={100} alt={badgeList[5].title} src={badgeList[5].picture}
                                     className={'images-mm'}/> :
                                undefined
                        }
                    </div>
                    <div className="bottom"/>
                </div>
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        {
                            props.badgeIds?.find((id) => id === badgeList[6].id) ?
                                <img width={100} height={100} alt={badgeList[6].title} src={badgeList[6].picture}
                                     className={'images-mm'}/> :
                                undefined
                        }
                    </div>
                    <div className="bottom"/>
                </div>
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        {
                            props.badgeIds?.find((id) => id === badgeList[7].id) ?
                                <img width={100} height={100} alt={badgeList[7].title} src={badgeList[7].picture}
                                     className={'images-mm'}/> :
                                undefined
                        }
                    </div>
                    <div className="bottom"/>
                </div>
            </div>
            <div className="hex-row even">
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        {
                            props.badgeIds?.find((id) => id === badgeList[8].id) ?
                                <img width={100} height={100} alt={badgeList[8].title} src={badgeList[8].picture}
                                     className={'images-mm'}/> :
                                undefined
                        }
                    </div>
                    <div className="bottom"/>
                </div>
                <div className="hex">
                    <div className="top"/>
                    <div className="middle">
                        {
                            props.badgeIds?.find((id) => id === badgeList[9].id) ?
                                <img width={100} height={100} alt={badgeList[9].title} src={badgeList[9].picture}
                                     className={'images-mm'}/> :
                                undefined
                        }
                    </div>
                    <div className="bottom"/>
                </div>
            </div>
            {
                searchParams.get('badge') && badge &&
                <BadgeDetailsModal onClose={() => router(-1)} badge={badge}/>
            }
        </div>
    );
};
