import React from "react"

interface Props {
    width: number;
    height: number;
    isSelected: () => boolean;
}

export function DashboardIcon(props: Props) {
    const color = props.isSelected() ? '#FFF' : '#979797'

    return (
        <svg width={props.width} height={props.height} viewBox={`0 0 46 34`}>
            <g fill={'#222222'} fillRule="evenodd" stroke={color}>
                <g transform="translate(11 14)">
                    <rect strokeWidth={1.5} width={24} height={18} rx={2}/>
                    <rect strokeWidth={1.3} x={9} y={2} width={6} height={1} rx={0.5}/>
                    <rect strokeWidth={1.3} x={9} y={5} width={6} height={1} rx={0.5}/>
                    <rect strokeWidth={1.3} x={9} y={8} width={6} height={1} rx={0.5}/>
                    <circle cx={12} cy={13} r={2}/>
                    <circle fill={color}
                            stroke={color}
                            fillRule="nonzero" cx={5} cy={13} r={1}/>
                    <circle fill={color}
                            stroke={color}
                            fillRule="nonzero" cx={20} cy={13} r={1}/>
                </g>
            </g>
        </svg>
    )
}
