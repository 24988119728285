import {
    LOAD_MCQ,
    LOAD_MCQ_FAILED,
    LOAD_MCQ_SUCCEEDED,
    LoadMCQAction,
    LoadMCQFailedAction,
    LoadMCQSucceededAction
} from "./actionTypes";
import { MCQ } from "../../domain/entity/MCQ";

export const loadMCQ = (id: string): LoadMCQAction => ({
    type   : LOAD_MCQ,
    payload: id
})

export const loadMCQFailed = (error: string): LoadMCQFailedAction => ({
    type   : LOAD_MCQ_FAILED,
    payload: error
})

export const loadMCQSucceeded = (data: MCQ): LoadMCQSucceededAction => ({
    type   : LOAD_MCQ_SUCCEEDED,
    payload: data
})
