import { AdventureRepository } from '../../../domain/gateway/adventure.repository';
import { Observable, from, mergeMap, of } from 'rxjs';


export class SqlAdventureRepository implements AdventureRepository {
    saveAdventureLocally(adventureId: string): Observable<void> {
        return this.getSavedAdventuresIds()
            .pipe(mergeMap((ids) => {
                    const advId = ids.find((item) => item === adventureId)
                    if (advId === undefined) {
                        const newArray = [...ids, adventureId]
                        localStorage.setItem('adventures', JSON.stringify(newArray))
                    }
                    return of(void 0)
                })
            )
    }

    getSavedAdventuresIds(): Observable<string[]> {
        const getSavedAdventuresIds = new Promise<string[]>((resolve) => {
            const adventuresString = localStorage.getItem('adventures')
            if (adventuresString) {
                resolve(JSON.parse(adventuresString))
            } else {
                resolve([])
            }
        })
        return from(getSavedAdventuresIds)
    }
}
