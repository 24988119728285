import {
    LOAD_ADVENTURE_LIST,
    LOAD_ADVENTURE_LIST_FAILED,
    LOAD_ADVENTURE_LIST_SUCCEEDED,
    LoadAdventureListAction,
    LoadAdventureListFailedAction,
    LoadAdventureListSucceededAction
} from './actionTypes';
import { Adventure } from '../../domain/entities/adventure';

export const loadAdventureList = (): LoadAdventureListAction => ({
    type: LOAD_ADVENTURE_LIST
})

export const loadAdventureListFailed = (error: string): LoadAdventureListFailedAction => ({
    type   : LOAD_ADVENTURE_LIST_FAILED,
    payload: error
})

export const loadAdventureListSucceeded = (adventures: Adventure[]): LoadAdventureListSucceededAction => ({
    type   : LOAD_ADVENTURE_LIST_SUCCEEDED,
    payload: adventures
})
