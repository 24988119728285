import { AdventureStatusType, AdventureType } from '../types';
import { Adventure } from './adventure';

export class AdventureBuilder {
    protected _id!: string
    protected _label!: string
    protected _description!: string
    protected _starDate!: Date
    protected _endDate!: Date
    protected _picture!: string
    protected _status!: AdventureStatusType
    protected _earns!: number
    protected _type!: AdventureType

    withId(id: string): AdventureBuilder {
        this._id = id
        return this
    }

    withLabel(label: string): AdventureBuilder {
        this._label = label
        return this
    }

    withDescription(description: string): AdventureBuilder {
        this._description = description
        return this
    }

    withStartDate(startDate: Date): AdventureBuilder {
        this._starDate = startDate
        return this
    }

    withEndDate(endDate: Date): AdventureBuilder {
        this._endDate = endDate
        return this
    }

    withPicture(picture: string): AdventureBuilder {
        this._picture = picture
        return this
    }

    withStatus(status: AdventureStatusType): AdventureBuilder {
        this._status = status
        return this
    }

    withEarns(earns: number): AdventureBuilder {
        this._earns = earns
        return this
    }

    withType(type: AdventureType): AdventureBuilder {
        this._type = type
        return this
    }

    build(): Adventure {
        return new Adventure(
            this._id,
            this._label,
            this._description,
            this._starDate,
            this._endDate,
            this._picture,
            this._status,
            this._earns,
            this._type
        )
    }
}
