import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { AppState } from "../../../../redux-configuration/AppState";
import { profileSelector } from "../../../../profile/usecases/profile.selector";
import { playersScoreSelector } from "../../../usecases/selectors";
import { ScoreI18n } from "../../../config/i18n";
import GlobalScoreItem from "./item";
import { findAvatar } from "../../../../profile/config/findAvatar";

export default function IndividualScoreList() {

    const profile = useSelector((state: AppState) => profileSelector(state))
    const players = useSelector((state: AppState) => playersScoreSelector(state))

    const userScore = players?.find(item => item.email === profile?.email)

    let userScoreIndex: HTMLDivElement | null = null

    const handleAction = () => {
        if (userScoreIndex) {
            userScoreIndex.scrollIntoView({
                behavior: "smooth",
                block   : "center",
            })
            const currentClassName = userScoreIndex.getAttribute('class')
            userScoreIndex.setAttribute('class', currentClassName + ' selected-item')
        }
    }

    return (
        <div className={'col-6'}>
            <div className={'item'}>
                <div className={'d-flex justify-content-between mb-4'}>
                    <h3 className={'score-title'}>{ScoreI18n.individualScore}</h3>
                    {userScore && <button className={'score-btn'}
                                          onClick={() => handleAction()}>{ScoreI18n.you}</button>}
                </div>

                <div className={'overflow-scroll'}>
                    {players?.map((item) =>
                        <GlobalScoreItem key={item.email}
                                         isUser={item.email === profile?.email}
                                         rank={item.rank}
                                         title={item.fullName}
                                         subTitle={item.region + ' | ' + item.agency}
                                         region={item.region}
                                         score={item.score}
                                         onRef={(ref) => userScoreIndex = ref}>

                            <NavLink className={'mx-3'} to={`/team/${item.email}`}>
                                <img width={60} className={"img-fluid"} src={findAvatar(item.avatar).image}
                                     alt={'teammate-avatar'}/>
                            </NavLink>
                        </GlobalScoreItem>
                    )}
                    {
                        players?.length as number === 0 && <div className={'no-data'}>{ScoreI18n.no_data}</div>
                    }
                </div>
            </div>
        </div>
    )
}
