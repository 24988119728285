import { Epic, ofType, StateObservable } from 'redux-observable';
import { mergeMap, Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { SUBSCRIBE_TO_ADVENTURE, SubscribeToAdventureAction } from './subscribeToAdventure.types';
import { subscribeToAdventureFailed, subscribeToAdventureSucceeded } from './subscribeToAdventure.actions';
import { HandleRequestState } from "../../../redux-configuration/AppState";
import { AdventureRepository } from "../../domain/gateway/adventure.repository";
import { AdventureService } from "../../domain/gateway/adventure.service";

export const subscribeToAdventureEpic: Epic = (action$: Observable<SubscribeToAdventureAction>,
                                               store: StateObservable<HandleRequestState>,
                                               {adventureRepository, adventureService}: {
                                                   adventureRepository: AdventureRepository,
                                                   adventureService: AdventureService
                                               }) =>
    action$.pipe(
        ofType(SUBSCRIBE_TO_ADVENTURE),
        switchMap(action => adventureRepository.saveAdventureLocally(action.payload)
            .pipe(
                mergeMap(() => adventureService.subscribeToAdventure(action.payload)
                    .pipe(
                        map(() => subscribeToAdventureSucceeded()),
                        catchError((error: string) => of(subscribeToAdventureFailed(error)))
                    )
                ),
                catchError((error: string) => of(subscribeToAdventureFailed(error)))
            )
        )
    )
