import { ApplicationContext } from "../../../shared/config/application.context";
import { SpeedQuiz } from "../../domain/entity/SpeedQuiz";

const moment = ApplicationContext.getInstance().momentJs()

export class SpeedQuizSpecifications {
    static getSpeedQuizCountDownTimer(endDate: Date): string {

        const now = moment();
        const countDownDate = moment(endDate);

        const difference = countDownDate.diff(now);
        const diffDuration = moment.duration(difference);

        const days = diffDuration.days() > 0 ? diffDuration.days() + 'J ' : '';
        const hours = diffDuration.hours() > 0 ? diffDuration.hours() + 'H ' : '';
        const minutes = diffDuration.minutes() > 0 ? diffDuration.minutes() + 'MIN ' : '';

        if (difference < 0)
            return 'Terminé'

        return days + hours + minutes + 'RESTANT'
    }

    static minutesLeftInPercentage(speedQuiz: SpeedQuiz): string {
        const end = moment(speedQuiz.endDate)
        const start = moment(end)
            .subtract(5, 'days')
            .set('hour', 10)
            .set('minute', 0);

        const today = moment()

        const actualRest = end.diff(today, 'minutes')
        const totalDays = end.diff(start, 'minutes')

        const minutesLeft = ((1 - (actualRest / totalDays)) * 100)

        return Math.min(minutesLeft, 100).toFixed(0) + "%"
    }
}
