import { MCQResponse } from "./MCQResponse";
import { MCQQuestion } from "./MCQQuestion";

export class MCQQuestionBuilder {
    protected _id!: number
    protected _text!: string
    protected _description!: string
    protected _picture!: string
    protected _validResponses!: number
    protected _responses!: MCQResponse[]

    withId(value: number): MCQQuestionBuilder {
        this._id = value
        return this
    }

    withText(value: string): MCQQuestionBuilder {
        this._text = value
        return this
    }

    withDescription(value: string): MCQQuestionBuilder {
        this._description = value
        return this
    }

    withPicture(value: string): MCQQuestionBuilder {
        this._picture = value
        return this
    }

    withValidResponses(value: number): MCQQuestionBuilder {
        this._validResponses = value
        return this
    }

    withResponses(value: MCQResponse[]): MCQQuestionBuilder {
        this._responses = value
        return this
    }

    build(): MCQQuestion {
        return new MCQQuestion(
            this._id,
            this._text,
            this._description,
            this._picture,
            this._validResponses,
            this._responses
        )
    }
}
