export class Message {
    constructor(
        protected _id: string,
        protected _title: string,
        protected _text: string,
        protected _banner: string
    ) {
    }
    get id(): string {
        return this._id;
    }
    get title(): string {
        return this._title;
    }

    get text(): string {
        return this._text;
    }

    get banner(): string {
        return this._banner;
    }
}