import React from "react";

interface Props {
    title: string | undefined;
    category: string | undefined;
}

export default function SpeedQuizQuestionsHeader(props: Props) {
    return (
        <div className={'dashboard-header d-flex align-items-center justify-content-between'}>
            <h1>{props.title}</h1>

            <div className={'subtitle'}>
                <span className={'gradient-text'}>{props.category}</span>
            </div>
        </div>
    )
}
