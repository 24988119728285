import {
    RETRIEVE_BADGES,
    RETRIEVE_BADGES_FAILED,
    RETRIEVE_BADGES_SUCCEEDED,
    RetrieveBadgesAction,
    RetrieveBadgesFailedAction,
    RetrieveBadgesSucceededAction
} from "./loadBadges.types";
import { Badge } from "../../domain/entities/badge/badge";

export const retrieveBadges = (): RetrieveBadgesAction => ({
    type: RETRIEVE_BADGES
})

export const retrieveBadgesFailed = (error: string): RetrieveBadgesFailedAction => ({
    type   : RETRIEVE_BADGES_FAILED,
    payload: error
})

export const retrieveBadgesSucceeded = (badges: Badge[]): RetrieveBadgesSucceededAction => ({
    type   : RETRIEVE_BADGES_SUCCEEDED,
    payload: badges
})
