import { Adventure } from "../../domain/entities/adventure";

export const LOAD_ADVENTURE_DETAILS = 'LOAD_ADVENTURE_DETAILS';
export const LOAD_ADVENTURE_DETAILS_FAILED = 'LOAD_ADVENTURE_DETAILS_FAILED';
export const LOAD_ADVENTURE_DETAILS_SUCCEEDED = 'LOAD_ADVENTURE_DETAILS_SUCCEEDED'

export interface LoadAdventureDetailsAction {
    type: typeof LOAD_ADVENTURE_DETAILS;
    payload: string;
}

export interface LoadAdventureDetailsFailedAction {
    type: typeof LOAD_ADVENTURE_DETAILS_FAILED;
    payload: string;
}

export interface LoadAdventureDetailsSucceededAction {
    type: typeof LOAD_ADVENTURE_DETAILS_SUCCEEDED;
    payload: Adventure;
}

export type LoadAdventureDetailsActionTypes =
    LoadAdventureDetailsAction
    | LoadAdventureDetailsFailedAction
    | LoadAdventureDetailsSucceededAction
