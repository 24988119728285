import { SpeedQuizQuestionsState } from "../../config/state";
import {
    LOAD_SPEED_QUIZ_QUESTIONS,
    LOAD_SPEED_QUIZ_QUESTIONS_FAILED,
    LOAD_SPEED_QUIZ_QUESTIONS_SUCCEEDED,
    LoadSpeedQuizQuestionsActionTypes
} from "./actionTypes";

const initialState: SpeedQuizQuestionsState = {
    questions: [],
    loading  : false,
    error    : undefined
}

export const loadSpeedQuizQuestionsReducer = (state = initialState, action: LoadSpeedQuizQuestionsActionTypes): SpeedQuizQuestionsState => {
    switch (action.type) {
        case LOAD_SPEED_QUIZ_QUESTIONS:
            return {
                loading  : true,
                questions: [],
                error    : undefined
            }

        case LOAD_SPEED_QUIZ_QUESTIONS_FAILED:
            return {
                loading  : false,
                questions: [],
                error    : action.payload
            }

        case LOAD_SPEED_QUIZ_QUESTIONS_SUCCEEDED:
            return {
                loading  : false,
                questions: action.payload,
                error    : undefined
            }

        default:
            return state
    }
}
