import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { AppState } from "../../../../redux-configuration/AppState";
import { loadSpeedQuizDetails } from "../../../usecases/details/actions";
import { findAvatar } from "../../../../profile/config/findAvatar";
import { SpeedQuizI18n } from "../../../config/i18n";
import SpeedQuizRoundItem from "./components/round.item";
import SpeedQuizModalParticipateButton from "./components/button";
import { loadSpeedQuizDetailsLoadingSelector, speedQuizDetailsSelector } from "../../../usecases/details/selectors";
import LoadingContainer from "../../../../shared/adapters/primaries/loading/loading";
import { AppEventBuilder } from "../../../../shared/domain/entities/logging/appEvent.builder";
import { BeaconsType } from "../../../../shared/domain/entities/logging/beacons.type";
import { logEvent } from "../../../../shared/usecases/logging/logging.action";
import { SpeedQuizStatusType } from "../../../domain/types";

interface Props {
    id: string | undefined;
    status?: SpeedQuizStatusType;
    onClose: () => void;
    onClick?: () => void;
}

export default function SpeedQuizDetailsModal(props: Props) {

    const dispatch = useDispatch()

    const loading = useSelector((state: AppState) => loadSpeedQuizDetailsLoadingSelector(state))
    const speedQuizDetails = useSelector((state: AppState) => speedQuizDetailsSelector(state))

    useEffect(() => {
        if (props.id) {
            dispatch(loadSpeedQuizDetails(props.id))
            const event = (new AppEventBuilder())
                .withBeacon(BeaconsType.DISPLAY_SPEED_QUIZ)
                .withReferences([{key: "speedQuizId", value: props.id}])
                .build()
            dispatch(logEvent(event))
        }
    }, [props.id])

    const className = props.id ? ' show' : ''
    const currentRound = speedQuizDetails?.rounds.find(round => round.status === false)

    return (
        <div className={"modal modal-details speed-quiz-details" + className} tabIndex={-1} role="dialog"
             onClick={(): void => props.onClose()}>
            <div className="modal-dialog" role="document">
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <button type="button" className="close" onClick={(): void => props.onClose()}>
                        <span/><span/>
                    </button>

                    <div className="modal-header d-flex align-items-center justify-content-around">
                        <div className={'text-center'}>
                            <img src={findAvatar(speedQuizDetails?.user.image).image}
                                 alt={'Profile'}
                                 width={80}
                                 height={80}/>
                            <p>{SpeedQuizI18n.you}</p>
                        </div>

                        <b>{speedQuizDetails?.user.score} : {speedQuizDetails?.opponent.score}</b>

                        <div className={'text-center'}>
                            <img src={findAvatar(speedQuizDetails?.opponent.image).image}
                                 alt={'Adversaire'}
                                 width={80}
                                 height={80}/>
                            <p>{speedQuizDetails?.opponent.name}</p>
                        </div>
                    </div>

                    <div className="modal-body">
                        {loading && (
                            <div className={'loading-container'}>
                                <LoadingContainer/>
                            </div>
                        )}

                        <h5 className={'mt-4'}>{speedQuizDetails?.message}</h5>

                        {currentRound &&
                            <p className={'subtitle'}>{SpeedQuizI18n.getStarted}<strong>{currentRound.title}</strong>
                            </p>}

                        {speedQuizDetails?.rounds.map(round => <SpeedQuizRoundItem key={round.id} round={round}/>)}
                    </div>

                    {speedQuizDetails && props.status !== 'archived' &&
                        <div className="modal-footer">
                            <SpeedQuizModalParticipateButton speedQuizDetails={speedQuizDetails}
                                                             onClose={(): void => props.onClose()}
                                                             onClick={(): void => props.onClick?.()}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
