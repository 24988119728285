export const SAVE_MESSAGE = 'SAVE_MESSAGE'
export const SAVE_MESSAGE_FAILED = 'SAVE_MESSAGE_FAILED'
export const SAVE_MESSAGE_SUCCEEDED = 'SAVE_MESSAGE_SUCCEEDED'

export interface SaveMessageAction {
    type: typeof SAVE_MESSAGE;
    payload: string;
}

export interface SaveMessageFailedAction {
    type: typeof SAVE_MESSAGE_FAILED;
    payload: string;
}

export interface SaveMessageSucceededAction {
    type: typeof SAVE_MESSAGE_SUCCEEDED;
}

export type SaveMessageActionTypes =
    SaveMessageAction
    | SaveMessageFailedAction
    | SaveMessageSucceededAction
