import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { LOAD_MCQ, LoadMCQAction } from "./actionTypes";
import { LoadMCQState } from "../../config/state";
import { MCQService } from "../../domain/gateway/MCQ.service";
import { MCQ } from "../../domain/entity/MCQ";
import { loadMCQFailed, loadMCQSucceeded } from "./actions";

export const loadMCQEpic: Epic = (action$: Observable<LoadMCQAction>,
                                  store: StateObservable<LoadMCQState>,
                                  {mCQService}: { mCQService: MCQService }) =>
    action$.pipe(
        ofType(LOAD_MCQ),
        switchMap(action => mCQService.getMCQ(action.payload)
            .pipe(
                map((data: MCQ) => loadMCQSucceeded(data)),
                catchError(err => of(loadMCQFailed(err)))
            )
        )
    )
