import React from "react";
import { SpeedQuizDetails } from "../../../../domain/entity/details";
import { SpeedQuizI18n } from "../../../../config/i18n";
import { Link, useLocation } from "react-router-dom";

interface Props {
    speedQuizDetails: SpeedQuizDetails;
    onClose: () => void;
    onClick: () => void;
}

export default function SpeedQuizModalParticipateButton(props: Props) {

    const pathname = useLocation().pathname;

    if (props.speedQuizDetails.nextRoundStatus === 'game_over') {
        return (
            <Link to={"/speed-quiz/" + props.speedQuizDetails?.id + "/result"} className={'large-blue-button'}>
                {SpeedQuizI18n.consult}
            </Link>
        )
    }
    else if (props.speedQuizDetails.nextRoundStatus === 'ready' && props.speedQuizDetails.nextRoundId && props.speedQuizDetails.nextRoundId !== "") {
        if (pathname.includes('speed-quiz')) {
            return <button className={'large-blue-button'}
                           onClick={(): void => props.onClick()}>{SpeedQuizI18n.next}</button>
        }

        return (
            <Link to={"/speed-quiz/" + props.speedQuizDetails?.id} className={'large-blue-button'}>
                {SpeedQuizI18n.next}
            </Link>
        )
    }
    else if (props.speedQuizDetails.nextRoundStatus === 'waiting' ||
        (props.speedQuizDetails.nextRoundStatus === 'ready' && props.speedQuizDetails.nextRoundId === "")) {
        return (
            <Link to={""} className={'large-blue-button disabled'}>
                {SpeedQuizI18n.next}
            </Link>
        )
    }
    else {
        return <button className={'large-blue-button'}
                       onClick={(): void => props.onClose()}>{SpeedQuizI18n.finish}</button>
    }
}
