import {
    REQUEST_PASSWORD,
    REQUEST_PASSWORD_FAILED,
    REQUEST_PASSWORD_SUCCEEDED,
    RequestPasswordAction,
    RequestPasswordFailedAction,
    RequestPasswordSucceededAction
} from './actionTypes';

export const requestPassword = (email: string): RequestPasswordAction => ({
    type   : REQUEST_PASSWORD,
    payload: email
})

export const requestPasswordFailed = (error: string): RequestPasswordFailedAction => ({
    type   : REQUEST_PASSWORD_FAILED,
    payload: error
})

export const requestPasswordSucceeded = (): RequestPasswordSucceededAction => ({
    type: REQUEST_PASSWORD_SUCCEEDED
})
