import { combineEpics } from "redux-observable";
import { loadSpeedQuizListEpic } from "../usecases/list/epic";
import { loadSpeedQuizDetailsEpic } from "../usecases/details/epic";
import { loadSpeedQuizQuestionsEpic } from "../usecases/questions/epic";
import { sendSpeedQuizAnswersEpic } from "../usecases/send/epic";
import { loadSpeedQuizResultEpic } from "../usecases/result/epic";


export const speedQuizRootEpic = combineEpics(
    loadSpeedQuizListEpic,
    loadSpeedQuizDetailsEpic,
    loadSpeedQuizQuestionsEpic,
    sendSpeedQuizAnswersEpic,
    loadSpeedQuizResultEpic
)
