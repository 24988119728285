import React, { JSX, useEffect, useState } from "react";
import '../dashboard/components/modals/modal.css';
import './missions.css';
import { Profile } from "../../../../profile/domain/entities/profile";
import { Adventure } from "../../../../adventure/domain/entities/adventure";
import { SpeedQuiz } from "../../../../speedQuiz/domain/entity/SpeedQuiz";
import LoadingContainer from "../../../../shared/adapters/primaries/loading/loading";
import DashboardHeader from "../dashboard/components/header";
import { DashboardI18n } from "../../../config/i18n";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import StartAdventureModal from "../dashboard/components/modals/adventure/startAdventure.modal";
import { SpeedQuizStatusType } from "../../../../speedQuiz/domain/types";
import MissionSpeedQuizItem from "./components/speedQuiz.item";
import MissionAdventureItem from "./components/adventure.item";
import SpeedQuizDetailsModal from "../../../../speedQuiz/adapters/primaries/details/details.modal";
import { useNavigate } from "react-router-dom";

interface ElementWithDate {
    item: SpeedQuiz | Adventure;
    startDate: Date
}

interface Props {
    loading: boolean;
    profile: Profile | undefined
    adventures: Adventure[] | undefined;
    speedQuizList: SpeedQuiz[] | undefined;
    loadAdventures: () => void;
    loadSpeedQuizList: () => void;
}

export function MissionsContainer(props: Props) {

    const router = useNavigate()

    const [selectedAdventure, setSelectedAdventure] = useState<Adventure | undefined>(undefined)
    const [selectedSpeedQuiz, setSelectedSpeedQuiz] = useState<SpeedQuiz | undefined>(undefined)

    useEffect(() => {
        props.loadAdventures()
        props.loadSpeedQuizList()
    }, [])

    if (!props.adventures || !props.speedQuizList) {
        return (
            <div className={'missions'}>
                <DashboardHeader title={DashboardI18n.missions}/>

                <div className={'loading-container'}>
                    <LoadingContainer/>
                </div>
            </div>
        )
    }

    return (
        <div className={'missions'}>
            <DashboardHeader title={DashboardI18n.missions}/>

            <Tabs>
                <TabList className={'d-flex flex-row p-0'}>
                    <Tab className={'tab-header'}>{DashboardI18n.inProgress}</Tab>
                    <Tab className={'tab-header'}>{DashboardI18n.new}</Tab>
                    <Tab className={'tab-header'}>{DashboardI18n.archived}</Tab>
                </TabList>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {getMissionsByStatus('inprogress').length > 0 ?
                            getMissionsByStatus('inprogress').map((item) => item) :
                            <p className={'empty-list'}>{DashboardI18n.noMission}</p>}
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {getMissionsByStatus('new').length > 0 ?
                            getMissionsByStatus('new').map((item) => item) :
                            <p className={'empty-list'}>{DashboardI18n.noMission}</p>}
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {getMissionsByStatus('archived').length > 0 ?
                            getMissionsByStatus('archived').map((item) => item) :
                            <p className={'empty-list'}>{DashboardI18n.noMission}</p>}
                    </div>
                </TabPanel>
            </Tabs>

            {selectedAdventure && <StartAdventureModal onClose={() => setSelectedAdventure(undefined)}
                                                       adventure={selectedAdventure}/>}

            {selectedSpeedQuiz && <SpeedQuizDetailsModal id={selectedSpeedQuiz.id}
                                                         status={selectedSpeedQuiz.status}
                                                         onClose={() => setSelectedSpeedQuiz(undefined)}/>}
        </div>
    );

    function getMissionsByStatus(status: SpeedQuizStatusType): JSX.Element[] {

        const missionsSQ = props.speedQuizList?.filter((item: SpeedQuiz) => item.status === status)
            .map((item): ElementWithDate => ({item, startDate: item.startDate()})) as Array<ElementWithDate>

        const missionsAdventure = props.adventures?.filter((item: Adventure) => item.status === status).map((item): ElementWithDate => ({
            item,
            startDate: item.starDate
        })) as Array<ElementWithDate>

        return ([...missionsAdventure, ...missionsSQ])
            .sort((a, b) => a.startDate.getTime() < b.startDate.getTime() ? 1 : -1)
            .map((obj): JSX.Element => {
                if (obj.item instanceof SpeedQuiz) {
                    return <MissionSpeedQuizItem key={obj.item.id}
                                                 onClick={() => setSelectedSpeedQuiz(obj.item as SpeedQuiz)}
                                                 speedQuiz={obj.item}/>
                }
                return <MissionAdventureItem key={obj.item.id}
                                             onClick={() => {
                                                 if (obj.item.status === "new") {
                                                     setSelectedAdventure(obj.item as Adventure)
                                                 } else {
                                                     router(`/adventure/${obj.item.id}`)
                                                 }
                                             }}
                                             adventure={obj.item}/>
            })
    }
}
