import { AvatarType } from "../../../../profile/domain/entities/types";

export class AdventurePlayerScore {
    constructor(
        protected _rank: number,
        protected _email: string,
        protected _fullName: string,
        protected _avatar: AvatarType,
        protected _agency: string,
        protected _region: string,
        protected _turnover: number,
        protected _turnoverGoal: number,
        protected _activatedClient: number,
        protected _activatedClientGoal: number
    ) {
    }

    get rank(): number {
        return this._rank;
    }

    rankSuffix(): string {
        switch (this._rank) {
            case 1:
                return " er";
            default:
                return " ème";
        }
    }
    get email(): string {
        return this._email;
    }

    get fullName(): string {
        return this._fullName;
    }

    get avatar(): AvatarType {
        return this._avatar;
    }

    get agency(): string {
        return this._agency;
    }

    get region(): string {
        return this._region;
    }

    get turnover(): number {
        return this._turnover;
    }

    get turnoverGoal(): number {
        return this._turnoverGoal;
    }

    get activatedClient(): number {
        return this._activatedClient;
    }

    get activatedClientGoal(): number {
        return this._activatedClientGoal;
    }

    score(): number {
        return (
            (this.turnover / this.turnoverGoal) + (this.activatedClient / this.activatedClientGoal)
        ) / 2
    }
}
