import { AUTHENTICATE_USER, AuthenticateUserAction } from "./actionTypes";
import { Epic, ofType, StateObservable } from "redux-observable";
import { Observable, of } from "rxjs";
import { AuthenticateUserState } from "../../config/state";
import { AuthenticationRepository } from "../../domain/gateway/authentication.repository";
import { catchError, map, switchMap } from "rxjs/operators";
import { userIsAuthenticated, userIsNotAuthenticated } from "./actions";

export const authenticateUserEpic: Epic = (action$: Observable<AuthenticateUserAction>,
                                           store: StateObservable<AuthenticateUserState>,
                                           {authenticationRepository}: { authenticationRepository: AuthenticationRepository }) =>
    action$.pipe(
        ofType(AUTHENTICATE_USER),
        switchMap(() => authenticationRepository.getAuthenticationToken()
            .pipe(
                map((token: string) => userIsAuthenticated(token)),
                catchError(() => of(userIsNotAuthenticated()))
            )
        )
    )
