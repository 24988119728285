export const ScoreI18n = {
    score           : 'Score',
    individualScore : 'Score individuel',
    collectiveScore : 'Score collectif',
    eme             : 'ème',
    ere             : 'ier',
    you             : 'vous',
    withRegion      : 'Par région',
    withAgency      : 'Par agence',

    top100          : 'Top 100',
    classification  : 'Classement',
    myRegionAgencies: 'Agences de ma région',
    regions         : 'Régions',
    agencies        : 'Agences',
    no_data : 'Les scores ne sont pas encore calculés'
}
