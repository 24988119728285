import { ProfileDto } from "../dto/profileDto";
import { Profile } from "../../../../domain/entities/profile";
import { ProfileBuilder } from "../../../../domain/entities/profile.builder";
import { BadgeIdType } from "../../../../domain/entities/types";
import { Badge } from "../../../../domain/entities/badge/badge";
import { badgeList } from "../../../../config/badges.list";
import { BadgeType } from "../../../../domain/types/badge.type";
import { BadgeBuilder } from "../../../../domain/entities/badge/badge.builder";
import { ProfileI18n } from "../../../../config/i18n";

export class ProfileMapper {
    static mapProfileDtoToProfile(profileDto: ProfileDto): Profile {
        const profile = new ProfileBuilder()
            .withEmail(profileDto.email)
            .withFirstName(profileDto.firstName)
            .withLastName(profileDto.lastName)
            .withRegion(profileDto.regionLabel)
            .withAgency(profileDto.agencyName)
            .withAgencyCode(profileDto.agencyCode)
            .withScore(profileDto.score)
            .withType(profileDto.type)
            .withNetwork(profileDto.network)

        if (profileDto.avatar)
            profile.withAvatar(profileDto.avatar)
        return profile.build()
    }

    static mapBadges(wonBadges: BadgeIdType[]): Badge[] {
        return badgeList.map((item: BadgeType) => {
            const badge = wonBadges.find(wonBadge => wonBadge === item.id)
            return new BadgeBuilder()
                .withId(item.id)
                .withName(badge ? item.title : ProfileI18n.titleEncrypted)
                .withDescription(item.description)
                .withPicture(badge ? item.picture : require("../../../../../assets/img/badge/UNKNOWN.png"))
                .withIsEncrypted(!badge)
                .build()
        })
    }
}
