export const SUBSCRIBE_TO_ADVENTURE = 'SUBSCRIBE_TO_ADVENTURE'
export const SUBSCRIBE_TO_ADVENTURE_FAILED = 'SUBSCRIBE_TO_ADVENTURE_FAILED'
export const SUBSCRIBE_TO_ADVENTURE_SUCCEEDED = 'SUBSCRIBE_TO_ADVENTURE_SUCCEEDED'

export interface SubscribeToAdventureAction {
    type: typeof SUBSCRIBE_TO_ADVENTURE;
    payload: string;
}
export  interface SubscribeToAdventureFailedAction {
    type: typeof SUBSCRIBE_TO_ADVENTURE_FAILED;
    payload: string;
}

export  interface SubscribeToAdventureSucceededAction {
    type: typeof SUBSCRIBE_TO_ADVENTURE_SUCCEEDED;
}

export type SubscribeToAdventureActionsTypes = SubscribeToAdventureAction | SubscribeToAdventureFailedAction | SubscribeToAdventureSucceededAction
