import { combineEpics } from "redux-observable";
import { loadMessagesEpic } from "../usecases/load/epic";
import { saveMessageEpic } from "../usecases/save/epic";
import { catchMessageEpic } from "../usecases/catch/epic";

export const messageRootEpic = combineEpics(
    loadMessagesEpic,
    saveMessageEpic,
    catchMessageEpic
)
