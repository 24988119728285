import { SpeedQuizResponseType } from "../types";

export class SpeedQuizRound {
    constructor(
        protected _id: string,
        protected _title: string,
        protected _status: boolean,
        protected _category: string,
        protected _userResponse: SpeedQuizResponseType[],
        protected _opponentResponse: SpeedQuizResponseType[]
    ) {}


    get id(): string {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get status(): boolean {
        return this._status;
    }

    get category(): string {
        return this._category;
    }

    get userResponse(): SpeedQuizResponseType[] {
        return this._userResponse;
    }

    get opponentResponse(): SpeedQuizResponseType[] {
        return this._opponentResponse;
    }
}
