import { LoadMCQState } from "../../config/state";
import { LOAD_MCQ, LOAD_MCQ_FAILED, LOAD_MCQ_SUCCEEDED, LoadMCQActionTypes } from "./actionTypes";

const initialState: LoadMCQState = {
    loading: false,
    data   : undefined,
    error  : undefined
}

export const loadMCQReducer = (state = initialState, action: LoadMCQActionTypes): LoadMCQState => {
    switch (action.type) {
        case LOAD_MCQ:
            return {
                loading: true,
                data   : undefined,
                error  : undefined
            }
        case LOAD_MCQ_FAILED:
            return {
                loading: false,
                data   : undefined,
                error  : action.payload
            }
        case LOAD_MCQ_SUCCEEDED:
            return {
                loading: false,
                data   : action.payload,
                error  : undefined
            }
        default:
            return state
    }
};
