import React, { useEffect, useState } from 'react';
import { RootNavigationContainer } from "./Navigations/adapters/primaries/rootNavigationContainer";
import { Provider } from "react-redux";
import { reduxStore } from "./redux-configuration/store.redux";
import { isAuthenticatedSelector } from "./authentication/usecases/authenticateUser/selector";
import { authenticateUser } from "./authentication/usecases/authenticateUser/actions";
import { retrieveProfile } from "./profile/usecases/retrieveProfile/retrieveProfile.actions";
import { Unsubscribe } from "redux";
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/theme.css';
import OnlyDesktop from "./shared/adapters/primaries/onlyDesktop";

const store = reduxStore()

function App() {

    let unsubscribe: Unsubscribe;

    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined)
    const [isMobile, setIsMobile] = useState<boolean>(isSmallDevice())

    useEffect(() => {
            readinessListener()
                .finally(() => {
                    setIsAuthenticated(hasSession())
                    unsubscribe()
                })
            store.dispatch(authenticateUser())

            window.addEventListener('resize', handleResize);

            return (() => {
                window.removeEventListener('resize', handleResize)
            })
        },
        []
    )


    if (isMobile) {
        return <OnlyDesktop/>
    }

    return (
        <Provider store={store}>
            <RootNavigationContainer isAuthenticated={isAuthenticated}/>
        </Provider>
    );


    function readinessListener(): Promise<void> {
        return new Promise<void>((resolve: () => void) => {
            let eventCounter = 0
            unsubscribe = store.subscribe(() => {
                if (eventCounter === 0 && hasFinishAuthenticating()) {
                    eventCounter++
                    if (hasSession()) {
                        initiateActions()
                    }
                    resolve()
                } else if (eventCounter === 1 && hasSession() && hasFinishLoadingProfile()) {
                    ++eventCounter
                    resolve()
                }
            })
        })
    }

    function hasFinishAuthenticating(): boolean {
        return isAuthenticatedSelector(store.getState()) !== undefined
    }

    function hasSession(): boolean {
        return isAuthenticatedSelector(store.getState()) === true && isAuthenticatedSelector(store.getState()) !== undefined
    }

    function hasFinishLoadingProfile(): boolean {
        return store.getState().profile.storeProfile.profile !== undefined
    }

    function initiateActions(): void {
        store.dispatch(retrieveProfile())
    }

    function handleResize() {
        const {innerWidth} = window;
        if (innerWidth < 600) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    function isSmallDevice() {
        const {innerWidth} = window;
        return innerWidth < 600
    }
}

export default App;
