export const CATCH_MESSAGE = 'CATCH_MESSAGE'
export const CATCH_MESSAGE_FAILED = 'CATCH_MESSAGE_FAILED'
export const CATCH_MESSAGE_SUCCEEDED = 'CATCH_MESSAGE_SUCCEEDED'

export interface CatchMessageAction {
    type: typeof CATCH_MESSAGE;
    payload: string;
}

export interface CatchMessageFailedAction {
    type: typeof CATCH_MESSAGE_FAILED;
    payload: string;
}

export interface CatchMessageSucceededAction {
    type: typeof CATCH_MESSAGE_SUCCEEDED;
}

export type CatchMessageActionTypes =
    CatchMessageAction
    | CatchMessageFailedAction
    | CatchMessageSucceededAction
