import { Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs'
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { MessageService } from '../../domain/gateway/message.service';
import { LOAD_MESSAGES, LoadMessagesAction } from './actionTypes';
import { loadMessagesFailed, loadMessagesSucceeded } from './actions';
import { Message } from '../../domain/entities/message';
import { MessageState } from '../../config/state';
import { MessageRepository } from "../../domain/gateway/message.repository";

export const loadMessagesEpic: Epic = (action$: Observable<LoadMessagesAction>,
                                       store: StateObservable<MessageState>,
                                       {messageService, messageRepository}: {
                                           messageService: MessageService;
                                           messageRepository: MessageRepository
                                       }) =>
    action$.pipe(
        ofType(LOAD_MESSAGES),
        switchMap(() => messageService.loadMessages()
            .pipe(
                concatMap((messages: Message[]) => messageRepository.findMessages()
                    .pipe(
                        map((messagesIds: string[]) => loadMessagesSucceeded(getUnreadMessages(messages, messagesIds))),
                        catchError((error: string) => of(loadMessagesFailed(error)))
                    )
                ), catchError((error: string) => of(loadMessagesFailed(error)))
            )
        )
    );

const getUnreadMessages = (messages: Message[], messagesIds: string[]): Message[] => {
    const unreadMessages: Message[] = []

    messages.map(message => {
        if (!messagesIds.find(id => id === message.id)) {
            unreadMessages.push(message)
        }
    })

    return unreadMessages
}
