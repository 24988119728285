import React, { useEffect, useState } from "react";

interface Props {
    time: number;
    isTimeOver: boolean;
    setTimeIsOver: () => void;
}

export default function SpeedQuizQuestionProgressBar(props: Props) {

    const [counter, setCounter] = useState<number>(props.time)

    useEffect(() => {
        if (counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000)
        } else {
            setTimeout(() => props.setTimeIsOver(), 1000)
        }
    }, [counter])

    useEffect(() => {
        if (!props.isTimeOver && counter === 0) {
            setCounter(props.time)
        }
    }, [props.isTimeOver])

    return (
        <div className={'progress-bar'}>
            <div className={'progress'} style={{width: counter * 100 / props.time + '%'}}/>
        </div>
    )
}
