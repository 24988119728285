import React, { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AdventurePlayerScore } from "../../../../domain/entities/score/adventurePlayerScore";
import { AdventureAgencyScore } from "../../../../domain/entities/score/adventureAgencyScore";
import { Profile } from "../../../../../profile/domain/entities/profile";
import { AdventureI18n } from "../../../../config/i18n";
import { AgencyRankingItem } from "../../components/rankingItems/agencyRankingItem";
import { ItinerantRankingItem } from "../../components/rankingItems/itinerantRankingItem";

interface Props {
    playersScores: AdventurePlayerScore[] | undefined
    agenciesScores: AdventureAgencyScore[] | undefined
    profile: Profile | undefined
}

export const SupportStandardRanking = (props: Props) => {

    const [showYouBtn, setShowYouBtn] = useState<boolean>(false)

    let currentProfileItemRef: HTMLDivElement | null = null

    const allItinerant = props.playersScores?.map((item) => {
        return <ItinerantRankingItem key={item.email} onRef={(ref) => currentProfileItemRef = ref}
                                     profile={props.profile}
                                     playerScore={item}/>
    })

    const myRegionItinerant = props.playersScores?.filter((item) => item.region === props.profile?.region).map((item) => {
        return <ItinerantRankingItem key={item.email} onRef={(ref) => currentProfileItemRef = ref}
                                     profile={props.profile}
                                     playerScore={item}/>
    })

    const allAgencies = props.agenciesScores?.map((item, index) => {
        return <AgencyRankingItem key={item.code}
                                  item={item}
                                  rank={index + 1}
                                  profile={props.profile}
                                  onRef={(ref => currentProfileItemRef = ref)}/>
    })

    const myRegionAgencies = props.agenciesScores?.filter((item) => item.region === props.profile?.region)
        .map((item, index) => {
            return <AgencyRankingItem key={item.code}
                                      rank={index + 1}
                                      item={item}
                                      profile={props.profile}
                                      onRef={(ref => currentProfileItemRef = ref)}/>
        })


    return (
        <div className={'data-section'}>
            <div className={'d-flex align-items-center justify-content-between'}>
                <h2>{AdventureI18n.rank}</h2>
                {showYouBtn &&
                    <a className={'you-btn'} onClick={() => handleAction()}>
                        {AdventureI18n.you}
                    </a>
                }
            </div>

            <Tabs className={'d-flex flex-column p-0 overflow-hidden flex-grow-1'}>
                <TabList className={'d-flex flex-row p-0'}>
                    <Tab onClick={() => setShowYouBtn(false)}
                         className={'tab-header'}>{AdventureI18n.allTCI}</Tab>
                    <Tab onClick={() => setShowYouBtn(false)}
                         className={'tab-header'}>{AdventureI18n.myRegionTCI}</Tab>
                    <Tab onClick={() => setShowYouBtn(true)}
                         className={'tab-header'}>{AdventureI18n.allAgencies}</Tab>
                    <Tab onClick={() => setShowYouBtn(true)}
                         className={'tab-header'}>{AdventureI18n.myRegionAgencies}</Tab>
                </TabList>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {allItinerant}
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {myRegionItinerant}
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {allAgencies}
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className={'overflow-scroll list'}>
                        {myRegionAgencies}
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );

    function handleAction() {
        if (currentProfileItemRef) {
            currentProfileItemRef.scrollIntoView({
                behavior: "smooth",
                block   : "center",
                inline  : "start"
            })
            const currentClassName = currentProfileItemRef.getAttribute('class')
            currentProfileItemRef.setAttribute('class', currentClassName + ' selected-item')
        }
    }
}
