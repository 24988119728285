import React from "react";
import './login.css'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingContainer from "../../../shared/adapters/primaries/loading/loading";
import { AuthenticationI18n } from "../../config/i18n";

interface Props {
    loading: boolean;
    passwordSuccess: boolean | undefined;
    passwordError: string | undefined;
    loginSuccess: boolean | undefined;
    loginError: string | undefined;
    requestPassword: (email: string) => void;
    login: (email: string, code: string) => void;
}

export default function LoginContainer(props: Props) {

    const router = useNavigate()

    const [email, setEmail] = useState<string>('')
    const [emailInputError, setEmailInputError] = useState<boolean>(false)

    const [password, setPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState<boolean>(false)

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const isValidEmail = (email: string): boolean => {
        const expression = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        return expression.test(email.toLowerCase());
    }

    useEffect(() => {
        if (props.passwordError) {
            switch (props.passwordError) {
                case '400':
                    setErrorMessage(AuthenticationI18n.request_password_400)
                    setEmailInputError(true)
                    break;
                case '406':
                    setErrorMessage(AuthenticationI18n.request_password_406)
                    setEmailInputError(true)
                    break;
                default:
                    setErrorMessage('Server error')
                    setEmailInputError(true)
                    break;
            }
        } else {
            setErrorMessage(undefined)
        }
    }, [props.passwordError])

    useEffect(() => {
        if (props.loginError === '401') {
            setErrorMessage('Veuillez vérifier votre mot de passe')
        } else if (props.loginError) {
            setErrorMessage('Une erreur serveur est survenue')
        } else {
            setErrorMessage(undefined)
        }
    }, [props.loginError])

    useEffect(() => {
        if (props.loginSuccess) {
            router('/login/onboarding')
        }
    }, [props.loginSuccess])

    function ask4Password() {
        if (isValidEmail(email)) {
            props.requestPassword(email)
        } else if (email.length === 0) {
            setErrorMessage(AuthenticationI18n.empty_email)
            setEmailInputError(true)
        } else {
            setErrorMessage(AuthenticationI18n.invalid_email)
            setEmailInputError(true)
        }
    }

    function login() {
        if (password.length === 8) {
            props.login(email, password)
        } else if (password.length === 0) {
            setErrorMessage(AuthenticationI18n.empty_code_error)
            setPasswordError(true)
        } else {
            setErrorMessage(AuthenticationI18n.code_error)
            setPasswordError(true)
        }
    }

    const emailError = emailInputError ? 'error' : undefined;
    const passwordInputError = passwordError ? 'error' : undefined;

    return (
        <div className={'d-flex align-items-center login-page'}>
            <div className="login-container">
                <img src={require('../../../assets/img/login/login-header.png')}
                     alt={'login-header'}
                     className={'img-fluid bg-transparent'}/>

                <div className="login-form">
                    {props.loading && <LoadingContainer/>}

                    <h1>{AuthenticationI18n.title}</h1>
                    <h2>{AuthenticationI18n.subTitle}</h2>

                    <input type="email"
                           value={email}
                           disabled={props.passwordSuccess}
                           className={`form-control ${emailError}`}
                           onFocus={() => {
                               setEmailInputError(false)
                               setErrorMessage(undefined)
                           }}
                           onChange={(e) => setEmail(e.target.value)}
                           placeholder={AuthenticationI18n.email_address}/>

                    {props.passwordSuccess && (
                        <div className="login-input">
                            <input type="password"
                                   name="password"
                                   value={password}
                                   placeholder={AuthenticationI18n.password}
                                   className={`form-control ${passwordInputError}`}
                                   onFocus={() => {
                                       setPasswordError(false)
                                       setErrorMessage(undefined)
                                   }}
                                   onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                    )}

                    <div className={'d-flex align-items-start flex-row text-start mb-2'}>
                        <span style={{
                            fontSize   : '80%',
                            fontStyle  : 'italic',
                            paddingLeft: '0.5rem'
                        }}>{AuthenticationI18n.valid_email}</span>
                    </div>


                    {errorMessage && <p className={'error'}>{errorMessage}</p>}

                    {
                        props.passwordSuccess ?
                            <button className={'large-blue-button'} onClick={() => login()}>
                                {AuthenticationI18n.sign_in}
                            </button> :
                            <button className={'large-blue-button'} onClick={() => ask4Password()}>
                                {AuthenticationI18n.ask_for_password}
                            </button>
                    }
                </div>
            </div>
        </div>
    )
}
