import { combineReducers } from 'redux';
import { AppState } from './AppState';
import { authenticationRootReducer } from "../authentication/config/rootReducer";
import { profileRootReducer } from "../profile/config/reducers";
import { messageRootReducer } from "../message/config/reducer";
import { scoreReducer } from "../score/usecases/reducer";
import { speedQuizRootReducer } from "../speedQuiz/config/reducer";
import { loadTeamMateReducer } from "../teamMate/usecases/load/reducer";
import { MCQReducer } from "../MCQ/config/reducer";
import { adventureRootReducer } from "../adventure/config/reducers";
import { DESTROY_USER_SESSION, DestroyUserSessionAction } from "../authentication/usecases/logout/actionTypes";
import { loggingReducer } from "../shared/usecases/logging/logging.reducer";


const appReducer = combineReducers<AppState>({
    authentication: authenticationRootReducer,
    profile       : profileRootReducer,
    message       : messageRootReducer,
    score         : scoreReducer,
    speedQuiz     : speedQuizRootReducer,
    teamMate      : loadTeamMateReducer,
    MCQ           : MCQReducer,
    adventure     : adventureRootReducer,
    logging       : loggingReducer
})

export const reduxReducer = (state: AppState | undefined, action: DestroyUserSessionAction) => {

    // Clear all data in redux store to initial.
    if (action.type === DESTROY_USER_SESSION) {
        state = undefined;
    }

    return appReducer(state, action);
};
