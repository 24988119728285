export const ProfileI18n = {
    myProfile              : 'Mon profil',
    myBadges               : 'Mes badges',
    availableSoon          : 'Disponible prochainement',
    chooseAvatar           : 'Choisir un avatar',
    avatarChoice           : 'Choix d\'avatar',
    choose                 : 'Choisir',
    avatars                : {
        fun  : {
            title      : 'Fun-monaute',
            description: 'Un Mars et ca repart !'
        },
        foot : {
            title      : 'Foot-monaute',
            description: 'Ramenez la soucoupe à la maison !'
        },
        rock : {
            title      : 'Rock-monaute',
            description: 'Pink Floyd et Aerosmith n\'ont plus de secret pour lui.'
        },
        dream: {
            title      : 'Dream-monaute',
            description: 'La tête dans les étoiles, mais les pieds sur terre.'
        },
        love : {
            title      : 'Love-monaute',
            description: 'Pour toi j\'irais décrocher la lune'
        },
        pix  : {
            title      : 'Pix-monaute',
            description: 'Moteur, action, SATURNE !'
        },
        dab  : {
            title      : 'Dab-monaute',
            description: 'Danse avec les stars !'
        },
        ride : {
            title      : 'Ride-monaute',
            description: '"Houston... houston... nous avons un problème. Ride-monaute skate encore sur les anneaux de Saturne."'
        }
    },
    badgeEncrypted         : 'Ce badge semble encodé, il doit bien avoir un moyen pour le décrypter ...',
    badgeWine              : 'Bravo, vous avez obtenu un nouveau badge',
    continue               : 'Continuer',
    showProfile            : 'Voir mon profil',
    titleEncrypted         : '\\/3 |_\'1|\\=1\\|37',
    unknownBadgeDescription: 'Vous devez mettre a jour l\'application pour avoir ce nouveau badge',
    closeAccount           : 'Supprimer mon compte',
    subjectCloseAccount    : 'Demande de suppression de compte ( Sonepar\'s Adventure )',
    confirmCloseAccount    : 'ATTENTION : la suppression de votre compte est définitive et sera effective d\'ici 30 jours : un email de confirmation vous sera envoyé. Êtes-vous sure de vouloir supprimer votre compte ?',
    cancel                 : 'Annuler',
    ok                     : 'OK'
}
