import { Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { CATCH_MESSAGE, CatchMessageAction } from "./actionTypes";
import { MessageService } from "../../domain/gateway/message.service";
import { catchMessageFailed, catchMessageSucceeded } from "./actions";
import { AppState } from "../../../redux-configuration/AppState";

export const catchMessageEpic: Epic = (action$: Observable<CatchMessageAction>,
                                       store: StateObservable<AppState>,
                                       {messageService}: { messageService: MessageService }) =>
    action$.pipe(
        ofType(CATCH_MESSAGE),
        switchMap(action => messageService.catchMessage(action.payload)
            .pipe(
                map(() => catchMessageSucceeded()),
                catchError((error: string) => of(catchMessageFailed(error)))
            )
        )
    )
