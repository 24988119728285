import { TeamMate } from './teamMate';
import { AvatarType } from "../../../profile/domain/entities/types";

export class TeamMateBuilder {
    protected _email!: string
    protected _firstName!: string
    protected _lastName!: string
    protected _agency!: string
    protected _agencyCode!: string
    protected _region!: string
    protected _score!: number
    protected _avatar!: AvatarType
    protected _badges!: string[]

    withEmail(email: string): TeamMateBuilder {
        this._email = email
        return this
    }

    withFirstName(firstName: string): TeamMateBuilder {
        this._firstName = firstName
        return this;
    }

    withLastName(lastName: string): TeamMateBuilder {
        this._lastName = lastName
        return this;
    }

    withAgency(agency: string): TeamMateBuilder {
        this._agency = agency
        return this
    }

    withAgencyCode(agencyCode: string): TeamMateBuilder {
        this._agencyCode = agencyCode
        return this
    }

    withRegion(region: string): TeamMateBuilder {
        this._region = region
        return this
    }

    withScore(score: number): TeamMateBuilder {
        this._score = score
        return this
    }

    withAvatar(avatar: AvatarType): TeamMateBuilder {
        this._avatar = avatar
        return this
    }

    withBadges(badges: string[]): TeamMateBuilder {
        this._badges = badges
        return this
    }

    build(): TeamMate {
        return new TeamMate(
            this._email,
            this._firstName,
            this._lastName,
            this._agency,
            this._agencyCode,
            this._region,
            this._score,
            this._avatar,
            this._badges
        )
    }
}
