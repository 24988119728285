import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../redux-configuration/AppState";
import TeamMateContainer from "./teamMate.container";
import { loadTeamMate } from "../../usecases/load/actions";
import { loadTeamMateLoadingSelector, teamMateSelector } from "../../usecases/load/selector";
import { TeamMate } from "../../domain/entity/teamMate";
import { LoadTeamMateActionTypes } from "../../usecases/load/actionTypes";
import { AppEvent } from "../../../shared/domain/entities/logging/AppEvent";
import { logEvent } from "../../../shared/usecases/logging/logging.action";
import { LogEventAction } from "../../../shared/usecases/logging/analytics.types";

interface StateToPropsType {
    loading: boolean;
    teamMate: TeamMate | undefined;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading : loadTeamMateLoadingSelector(state),
    teamMate: teamMateSelector(state),
})

interface DispatchToPropsType {
    loadTeamMate: (email: string) => void;
    log: (event: AppEvent) => void;
}


const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadTeamMate: (email: string): LoadTeamMateActionTypes => dispatch(loadTeamMate(email)),
    log         : (event: AppEvent): LogEventAction => dispatch(logEvent(event))
})


export const TeamMatePage = connect(mapStateToProps, mapDispatchToProps)(TeamMateContainer)
