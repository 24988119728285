import React, { useEffect } from "react";
import { Message } from "../../../../../../message/domain/entities/message";
import { AdventureI18n } from "../../../../../../adventure/config/i18n";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { catchMessage } from "../../../../../../message/usecases/catch/actions";
import { saveMessage } from "../../../../../../message/usecases/save/actions";
import { AppEventBuilder } from "../../../../../../shared/domain/entities/logging/appEvent.builder";
import { BeaconsType } from "../../../../../../shared/domain/entities/logging/beacons.type";
import { logEvent } from "../../../../../../shared/usecases/logging/logging.action";

interface Props {
    message: Message | undefined;
    onClose: () => void;
}

export default function MessageDetailsModal(props: Props) {

    const dispatch = useDispatch()

    useEffect(() => {
        if (props.message) {
            const event = (new AppEventBuilder())
                .withBeacon(BeaconsType.DISPLAY_NEWS)
                .withReferences([{key: 'newsId', value: props.message?.id}])
                .build()
            logEvent(event)

            dispatch(catchMessage(props.message?.id))
        }
    }, [props.message])

    const onReadMessage = () => {
        if (props.message) {
            dispatch(saveMessage(props.message.id))
            props.onClose()
        }
    }

    const className = props.message ? ' show' : ''

    return (
        <div className={"modal modal-details message-details" + className} tabIndex={-1} role="dialog" onClick={(): void => props.onClose()}>
            <div className="modal-dialog" role="document">
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <button type="button" className="close" onClick={(): void => props.onClose()}>
                        <span/><span/>
                    </button>

                    <div className="modal-header">
                        <h5>{props.message?.title}</h5>
                    </div>

                    <div className="modal-body">
                        <div className={'my-3'}>{props.message && parse(props.message.text)}</div>
                    </div>

                    <div className="modal-footer">
                        <button className={'large-blue-button'} onClick={() => onReadMessage()}>
                            {AdventureI18n.participate}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
