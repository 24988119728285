import { ProfileI18n } from './i18n';
import { Avatar } from "../domain/entities/avatar";
import { AvatarType } from "../domain/entities/types";

export const avatars: Array<Avatar> = [
    {
        id         : 'fun-monaute',
        title      : ProfileI18n.avatars.fun.title,
        description: ProfileI18n.avatars.fun.description,
        image      : require("../../assets/img/avatar/fun-monaute.png")
    },
    {
        id         : 'foot-monaute',
        title      : ProfileI18n.avatars.foot.title,
        description: ProfileI18n.avatars.foot.description,
        image      : require("../../assets/img/avatar/foot-monaute.png")
    },
    {
        id         : 'rock-monaute',
        title      : ProfileI18n.avatars.rock.title,
        description: ProfileI18n.avatars.rock.description,
        image      : require("../../assets/img/avatar/rock-monaute.png")
    },
    {
        id         : 'dream-monaute',
        title      : ProfileI18n.avatars.dream.title,
        description: ProfileI18n.avatars.dream.description,
        image      : require("../../assets/img/avatar/dream-monaute.png")
    },
    {
        id         : 'love-monaute',
        title      : ProfileI18n.avatars.love.title,
        description: ProfileI18n.avatars.love.description,
        image      : require("../../assets/img/avatar/love-monaute.png")
    },
    {
        id         : 'pix-monaute',
        title      : ProfileI18n.avatars.pix.title,
        description: ProfileI18n.avatars.pix.description,
        image      : require("../../assets/img/avatar/pix-monaute.png")
    },
    {
        id         : 'dab-monaute',
        title      : ProfileI18n.avatars.dab.title,
        description: ProfileI18n.avatars.dab.description,
        image      : require("../../assets/img/avatar/dab-monaute.png")
    },
    {
        id         : 'ride-monaute',
        title      : ProfileI18n.avatars.ride.title,
        description: ProfileI18n.avatars.ride.description,
        image      : require("../../assets/img/avatar/ride-monaute.png")
    }
]
export const findAvatar = (id: AvatarType | undefined): Avatar => {

    return avatars.find((item) => item.id === id) ?? {
        id         : 'redCaptain-monaute',
        title      : '',
        description: '',
        image      : require("../../assets/img/avatar/default-monaute.png")
    }
}
