import {
    LOAD_ADVENTURE_DETAILS,
    LOAD_ADVENTURE_DETAILS_FAILED,
    LOAD_ADVENTURE_DETAILS_SUCCEEDED,
    LoadAdventureDetailsAction,
    LoadAdventureDetailsFailedAction,
    LoadAdventureDetailsSucceededAction
} from './actionTypes';
import { Adventure } from '../../domain/entities/adventure';

export const loadAdventureDetails = (id: string): LoadAdventureDetailsAction => ({
    type   : LOAD_ADVENTURE_DETAILS,
    payload: id
})

export const loadAdventureDetailsFailed = (error: string): LoadAdventureDetailsFailedAction => ({
    type   : LOAD_ADVENTURE_DETAILS_FAILED,
    payload: error
})

export const loadAdventureDetailsSucceeded = (adventures: Adventure): LoadAdventureDetailsSucceededAction => ({
    type   : LOAD_ADVENTURE_DETAILS_SUCCEEDED,
    payload: adventures
})
