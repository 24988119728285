import { SpeedQuizListState } from "../../config/state";
import {
    LOAD_SPEED_QUIZ_LIST,
    LOAD_SPEED_QUIZ_LIST_FAILED,
    LOAD_SPEED_QUIZ_LIST_SUCCEEDED,
    LoadSpeedQuizListActionTypes
} from "./actionTypes";

const initialState: SpeedQuizListState = {
    loading  : false,
    speedQuiz: undefined,
    error    : undefined
}

export const loadSpeedQuizListReducer = (state = initialState, action: LoadSpeedQuizListActionTypes): SpeedQuizListState => {
    switch (action.type) {
        case LOAD_SPEED_QUIZ_LIST:
            return {
                loading  : true,
                speedQuiz: undefined,
                error    : undefined
            }
        case LOAD_SPEED_QUIZ_LIST_FAILED:
            return {
                loading  : false,
                speedQuiz: undefined,
                error    : action.payload
            }
        case LOAD_SPEED_QUIZ_LIST_SUCCEEDED:
            return {
                loading  : false,
                speedQuiz: action.payload,
                error    : undefined
            }
        default:
            return state
    }
}
