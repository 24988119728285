import React from "react";
import { PlayerScore } from "../../../../../score/domain/entities/playerScore";
import { Profile } from "../../../../../profile/domain/entities/profile";
import { DashboardI18n } from "../../../../config/i18n";
import DashboardBadgesCarousel from "./badges.carousel";
import { useSelector } from "react-redux";
import { badgesSelector } from "../../../../../profile/usecases/retrieveBadges/loadBadges.selectors";
import { AppState } from "../../../../../redux-configuration/AppState";
import { regionsScoreSelector } from "../../../../../score/usecases/selectors";
import { Adventure } from "../../../../../adventure/domain/entities/adventure";
import { SpeedQuiz } from "../../../../../speedQuiz/domain/entity/SpeedQuiz";

interface Props {
    profile: Profile | undefined
    playersScore: PlayerScore[] | undefined;
    adventures: Adventure[] | undefined;
    speedQuiz: SpeedQuiz[] | undefined;
}

export function DashboardStats(props: Props) {

    const regionsScore = useSelector((state: AppState) => regionsScoreSelector(state))
    const badges = useSelector((state: AppState) => badgesSelector(state))

    const userBadges = badges?.filter(item => !item.isEncrypted)
    const userScore = props.playersScore?.find(player => player.email === props.profile?.email)
    const regionScore = regionsScore?.find(region => region.regionLabel === props.profile?.region)

    const userRank = userScore && userScore.rank > 0 ? <p className={'rank'}>{userScore.rank}<span>éme</span></p> : <p>-</p>
    const regionRank = regionScore && regionScore.rank > 0 ? <p className={'rank'}>{regionScore.rank}<span>éme</span></p> : <p>-</p>

    const completedMissions = (props.adventures ? props.adventures.length : 0) + (props.speedQuiz ? props.speedQuiz.length : 0)

    return (
        <div className={'col-6 stats'}>
            <div className={'item'}>
                <h3 className={'mission-title mb-4'}>{DashboardI18n.statistics}</h3>

                <div className={'row overflow-scroll'}>
                    <div className={'col-6 d-flex flex-column'}>
                        <div className={'dark-item mt-3 flex-grow-1'}>
                            <h4 className={'mb-5'}>{DashboardI18n.individualRank}</h4>
                            {userRank}
                        </div>

                        <div className={'blue-item mt-3 flex-grow-1'}>
                            <h4 className={'mb-5'}>{DashboardI18n.collectiveRank}</h4>
                            {regionRank}
                        </div>
                    </div>

                    <div className={'col-6 d-flex flex-column'}>
                        <div className={'blue-item mt-3 flex-grow-1'}>
                            <h4>{DashboardI18n.completedMissions}</h4>
                            <p>{completedMissions}</p>
                        </div>

                        {userBadges && userBadges.length > 0 &&
                            <DashboardBadgesCarousel badges={userBadges}
                                                     totalBadges={badges ? badges.length : 0}/>}
                    </div>
                </div>
            </div>
        </div>

    )
}
