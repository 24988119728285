import { SpeedQuiz } from "../../../../domain/entity/SpeedQuiz";
import { SpeedQuizBuilder } from "../../../../domain/builder/SpeedQuiz.builder";
import { ApplicationContext } from "../../../../../shared/config/application.context";
import { SpeedQuizDTO } from "../dto/speedQuiz.dto";
import { SpeedQuizPlayer } from "../../../../domain/entity/player";
import { SpeedQuizPlayerBuilder } from "../../../../domain/builder/player.builder";
import { SpeedQuizDetailsDTO, SpeedQuizRoundDTO } from "../dto/details.dto";
import { SpeedQuizDetails } from "../../../../domain/entity/details";
import { SpeedQuizRound } from "../../../../domain/entity/round";
import { SpeedQuizRoundBuilder } from "../../../../domain/builder/round.builder";
import { SpeedQuizDetailsBuilder } from "../../../../domain/builder/details.builder";
import { SpeedQuizQuestionDTO } from "../dto/question.dto";
import { SpeedQuizQuestion } from "../../../../domain/entity/question";
import { SpeedQuizAnswer } from "../../../../domain/entity/answer";
import { SpeedQuizAnswerBuilder } from "../../../../domain/builder/answer.builder";
import { SpeedQuizQuestionBuilder } from "../../../../domain/builder/question.builder";
import { SpeedQuizResultDTO } from "../dto/result.dto";
import { SpeedQuizResult } from "../../../../domain/entity/result";
import { SpeedQuizResultBuilder } from "../../../../domain/builder/result.builder";


const moment = ApplicationContext.getInstance().momentJs()

export class SpeedQuizMapper {
    static mapSpeedQuizDTO(speedQuizList: SpeedQuizDTO[]): SpeedQuiz[] {
        return speedQuizList.map(speedQuiz => {
            const user: SpeedQuizPlayer = new SpeedQuizPlayerBuilder()
                .withScore(speedQuiz.currentScore.toString())
                .build()

            const opponent: SpeedQuizPlayerBuilder = new SpeedQuizPlayerBuilder()
                .withName(speedQuiz.opponentName)
                .withScore(speedQuiz.opponentScore.toString())
                .withImage(speedQuiz.opponentAvatarPicture)
                .withCategory(speedQuiz.opponentRegionLabel)

            if (speedQuiz.opponentName === 'Red C.')
                opponent.withName('Capitaine RED')
                    .withImage('redCaptain-monaute')

            return new SpeedQuizBuilder()
                .withId(speedQuiz.id)
                .withStatus(speedQuiz.state)
                .withTitle(speedQuiz.title.replace(/Red C./ig, 'Capitaine RED'))
                .withMessage(speedQuiz.message.replace(/Red C./ig, 'Capitaine RED'))
                .withBackgroundImage(speedQuiz.backgroundPicture)
                .withUser(user)
                .withOpponent(opponent.build())
                .withEndDate(moment(speedQuiz.endGameDateTime))
                .build()
        })
    }

    static mapSpeedQuizDetailsDTO(speedQuizDetails: SpeedQuizDetailsDTO, id: string): SpeedQuizDetails {
        const user: SpeedQuizPlayer = new SpeedQuizPlayerBuilder()
            .withScore(speedQuizDetails.myScore.toString())
            .withImage(speedQuizDetails.myAvatarPicture)
            .withCategory(speedQuizDetails.myRegionLabel)
            .build()

        const opponent: SpeedQuizPlayerBuilder = new SpeedQuizPlayerBuilder()
            .withName(speedQuizDetails.opponentName)
            .withEmail(speedQuizDetails.opponentEmail)
            .withScore(speedQuizDetails.opponentScore.toString())
            .withImage(speedQuizDetails.opponentAvatarPicture)
            .withCategory(speedQuizDetails.opponentRegionLabel)

        if (speedQuizDetails.opponentName === 'Red C.')
            opponent.withName('Capitaine RED')
                .withImage('redCaptain-monaute')

        speedQuizDetails.gameRounds.sort((a, b) => a.step < b.step ? -1 : 1)

        const rounds: SpeedQuizRound[] = speedQuizDetails.gameRounds.map((round: SpeedQuizRoundDTO) =>
            new SpeedQuizRoundBuilder()
                .withId(round.id)
                .withTitle(round.title.replace(/Red C./ig, 'Capitaine RED'))
                .withStatus(round.highLight)
                .withCategory(round.category)
                .withUserResponse(round.responses)
                .withOpponentResponse(round.opponentResponse)
                .build()
        )

        return new SpeedQuizDetailsBuilder()
            .withId(id)
            .withMessage(speedQuizDetails.message)
            .withUser(user)
            .withOpponent(opponent.build())
            .withNextRoundId(speedQuizDetails.buttonNexStepRoundId)
            .withNextRoundStatus(speedQuizDetails.buttonNextStepState)
            .withRounds(rounds)
            .build()
    }

    static mapSpeedQuizQuestionsDTO(speedQuizQuestions: SpeedQuizQuestionDTO[]): SpeedQuizQuestion[] {
        return speedQuizQuestions.map(question => {

            const responses: SpeedQuizAnswer[] = question.responses.map(response =>
                new SpeedQuizAnswerBuilder()
                    .withId(response.id)
                    .withText(response.textResponse)
                    .withIsCorrect(response.isValid)
                    .withIsOpponentAnswer(response.opponentResponse)
                    .build()
            )

            return new SpeedQuizQuestionBuilder()
                .withId(question.id)
                .withText(question.textQuestion)
                .withTime(+question.delay)
                .withNumberOfCorrectAnswers(question.numberValidResponses)
                .withAnswers(responses)
                .build()
        })
    }

    static mapSpeedQuizResultDTO(speedQuizResult: SpeedQuizResultDTO): SpeedQuizResult {
        return new SpeedQuizResultBuilder()
            .withMessage(speedQuizResult.message)
            .withTotalPoints(speedQuizResult.wonPoints.TotalWonPoints)
            .withEarnedPoints(speedQuizResult.wonPoints.duelWonPoints)
            .withBonusPoints(speedQuizResult.wonPoints.bonusWonPoints)
            .build()
    }

}
