import { Message } from './message';

export class MessageBuilder {
    protected _id: string
    protected _title: string
    protected _text: string
    protected _banner: string

    constructor() {
        this._id = ''
        this._title = ''
        this._text = ''
        this._banner = ''
    }

    withId(id: string): MessageBuilder {
        this._id = id
        return this
    }

    withTitle(title: string): MessageBuilder {
        this._title = title
        return this
    }

    withText(text: string): MessageBuilder {
        this._text = text
        return this
    }

    withBanner(banner: string): MessageBuilder {
        this._banner = banner
        return this
    }

    build(): Message {
        return new Message(
            this._id,
            this._title,
            this._text,
            this._banner
        )
    }
}
