import { ScoreService } from "../../../domain/gateway/scoreService";
import { Score } from "../../../domain/entities/score";
import { Observable, throwError } from 'rxjs';
import { ScoreMapper } from "./mapper/score.mapper";
import { catchError, map } from 'rxjs/operators'
import { SecuredHttpClient } from "../../../../shared/adapters/secondaries/securedHttpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/config/dependencies.factory";
import { ScoreDTO } from "./dto/score.dto";


export class ApiScoreService implements ScoreService {
    loadScore(): Observable<Score> {
        const url = process.env.REACT_APP_API_URL + '/v1/score'

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post<{ data: ScoreDTO }>(url)
            .pipe(
                map(response => ScoreMapper.mapScoreDTOToScore(response.data)),
                catchError(err => throwError(err.status.toString())))
    }
}
