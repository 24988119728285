import { SpeedQuiz } from "./SpeedQuiz";
import { SpeedQuizRoundStatusType, SpeedQuizStatusType } from "../types";
import { SpeedQuizPlayer } from "./player";
import { SpeedQuizRound } from "./round";

export class SpeedQuizDetails extends SpeedQuiz {
    constructor(
        protected _id: string,
        protected _status: SpeedQuizStatusType,
        protected _title: string,
        protected _message: string,
        protected _backgroundImage: string,
        protected _user: SpeedQuizPlayer,
        protected _opponent: SpeedQuizPlayer,
        protected _endDate: Date,
        private _nextRoundId: string,
        private _nextRoundStatus: SpeedQuizRoundStatusType,
        private _rounds: SpeedQuizRound[]
    ) {
        super(_id, _status, _title, _message, _backgroundImage, _user, _opponent, _endDate)
    }

    get nextRoundId(): string {
        return this._nextRoundId;
    }

    get nextRoundStatus(): SpeedQuizRoundStatusType {
        return this._nextRoundStatus;
    }

    get rounds(): SpeedQuizRound[] {
        return this._rounds;
    }
}
