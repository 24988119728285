import React from 'react';

const GalaxyIcon = (props: { className?: string; width: number; }) => {
    return (
        <div className={props.className}>
            <svg width={props.width} height={props.width} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="9.74601" cy="5.99248" rx="0.882726" ry="0.876761" fill="white"/>
                <ellipse cx="16.8993" cy="22.5309" rx="0.472565" ry="0.469372" fill="white"/>
                <ellipse cx="21.7685" cy="13.4476" rx="0.518505" ry="0.515001" fill="white"/>
                <ellipse cx="15.5234" cy="9.48619" rx="0.518505" ry="0.515001" fill="white"/>
                <ellipse cx="6.46186" cy="16.8519" rx="0.518505" ry="0.515001" fill="white"/>
                <ellipse cx="20.6557" cy="7.66443" rx="0.658628" ry="0.654177" fill="white"/>
                <path d="M6.03327 7.59856L6.15199 7.81428C6.16141 7.8314 6.17558 7.84548 6.19282 7.85484L6.41 7.97292C6.44329 7.99076 6.46404 8.0253 6.46404 8.06287C6.46404 8.10043 6.44329 8.13497 6.41 8.15281L6.19282 8.27073C6.17558 8.28009 6.16141 8.29416 6.15199 8.31129L6.03327 8.527C6.0153 8.56006 5.98053 8.58067 5.94271 8.58067C5.90489 8.58067 5.87011 8.56006 5.85215 8.527L5.73343 8.31129C5.72395 8.29415 5.70972 8.28008 5.69243 8.27073L5.47591 8.15167C5.44262 8.13383 5.42188 8.09929 5.42188 8.06172C5.42188 8.02415 5.44262 7.98961 5.47591 7.97177L5.69309 7.85369C5.71038 7.84435 5.72461 7.83027 5.73409 7.81313L5.85281 7.59742C5.87108 7.56481 5.9058 7.54469 5.94338 7.54492C5.98095 7.54516 6.01541 7.56572 6.03327 7.59856Z" fill="white"/>
                <path d="M17.972 5.06145L18.1115 5.31478C18.1226 5.3349 18.1392 5.35147 18.1594 5.36253L18.4146 5.50187C18.4533 5.52295 18.4773 5.56329 18.4773 5.60711C18.4773 5.65093 18.4533 5.69127 18.4146 5.71235L18.1594 5.85087C18.1392 5.86193 18.1226 5.8785 18.1115 5.89863L17.972 6.15196C17.9508 6.19046 17.9102 6.21441 17.866 6.21441C17.8218 6.21441 17.7811 6.19046 17.76 6.15196L17.6205 5.89863C17.6094 5.8785 17.5927 5.86193 17.5724 5.85087L17.318 5.71153C17.2797 5.6904 17.2559 5.65027 17.2559 5.6067C17.2559 5.56314 17.2797 5.523 17.318 5.50187L17.5731 5.36335C17.5933 5.35229 17.61 5.33573 17.6211 5.31559L17.7606 5.06226C17.7818 5.02401 17.8221 5.00017 17.8661 5C17.91 4.99983 17.9506 5.02336 17.972 5.06145Z" fill="white"/>
                <path d="M8.45851 21.5148L8.61378 21.7967C8.62609 21.8191 8.6446 21.8376 8.66713 21.8499L8.951 22.0041C8.9941 22.0275 9.02091 22.0724 9.02091 22.1212C9.02091 22.17 8.9941 22.2149 8.951 22.2383L8.66713 22.3925C8.64461 22.4047 8.6261 22.4231 8.61378 22.4455L8.45851 22.7274C8.43502 22.7703 8.38979 22.7971 8.34062 22.7971C8.29144 22.7971 8.24621 22.7703 8.22272 22.7274L8.06745 22.4455C8.05508 22.4231 8.03651 22.4047 8.01394 22.3925L7.73007 22.2383C7.68696 22.2149 7.66016 22.17 7.66016 22.1212C7.66016 22.0724 7.68696 22.0275 7.73007 22.0041L8.01394 21.8499C8.03651 21.8376 8.05508 21.8191 8.06745 21.7967L8.22272 21.5148C8.24628 21.4719 8.29149 21.4453 8.34062 21.4453C8.38974 21.4453 8.43495 21.4719 8.45851 21.5148Z" fill="white"/>
                <path d="M21.7917 20.4987L22.0377 20.9457C22.0572 20.9812 22.0866 21.0104 22.1223 21.0298L22.5723 21.2751C22.6407 21.3121 22.6832 21.3833 22.6832 21.4607C22.6832 21.5381 22.6407 21.6093 22.5723 21.6463L22.1223 21.8916C22.0866 21.9111 22.0573 21.9403 22.0377 21.9757L21.7917 22.4227C21.7539 22.49 21.6824 22.5318 21.6047 22.5318C21.5271 22.5318 21.4556 22.49 21.4178 22.4227L21.1708 21.9757C21.1512 21.9402 21.1219 21.911 21.0861 21.8916L20.6361 21.6463C20.5678 21.6092 20.5254 21.538 20.5254 21.4607C20.5254 21.3834 20.5678 21.3122 20.6361 21.2751L21.0861 21.0298C21.1219 21.0104 21.1513 20.9812 21.1708 20.9457L21.4178 20.4987C21.4556 20.4314 21.5271 20.3896 21.6047 20.3896C21.6824 20.3896 21.7539 20.4314 21.7917 20.4987Z" fill="white"/>
                <ellipse cx="11.9935" cy="18.5661" rx="0.747378" ry="0.742328" fill="white"/>
                <path d="M23.7662 17.9754L23.9059 18.2289C23.9169 18.249 23.9335 18.2655 23.9538 18.2765L24.2088 18.4152C24.2476 18.4362 24.2717 18.4765 24.2717 18.5204C24.2717 18.5643 24.2476 18.6046 24.2088 18.6256L23.9538 18.7642C23.9335 18.7752 23.9169 18.7918 23.9059 18.8119L23.7662 19.0652C23.7451 19.1038 23.7045 19.1277 23.6603 19.1277C23.6161 19.1277 23.5755 19.1038 23.5543 19.0652L23.4149 18.8119C23.4037 18.7918 23.3871 18.7752 23.3668 18.7642L23.1117 18.6256C23.073 18.6046 23.0488 18.5643 23.0488 18.5204C23.0488 18.4765 23.073 18.4362 23.1117 18.4152L23.3668 18.2765C23.387 18.2655 23.4037 18.249 23.4149 18.2289L23.5543 17.9754C23.5755 17.937 23.6162 17.9131 23.6603 17.9131C23.7044 17.9131 23.745 17.937 23.7662 17.9754Z" fill="white"/>
                <path d="M14.5732 16.1937C13.9099 16.1937 13.3264 15.9893 13.0012 15.6035C12.6898 15.2341 12.6719 14.7513 12.951 14.2788C13.3071 13.6766 14.153 13.4789 14.6443 13.7108C14.9377 13.8493 15.1799 14.1875 14.9799 14.7212C14.95 14.8046 14.8772 14.8656 14.7894 14.8808C14.7016 14.8959 14.6124 14.8629 14.5559 14.7944C14.4995 14.7259 14.4846 14.6324 14.517 14.55C14.6303 14.2476 14.4915 14.1818 14.432 14.1539C14.145 14.0181 13.5902 14.1666 13.3768 14.5274C13.2022 14.8228 13.203 15.0789 13.3799 15.2885C13.7125 15.6828 14.6524 15.8717 15.6495 15.5052C17.2302 14.924 17.9908 13.6603 17.9243 12.7122C17.8655 11.8718 17.2223 11.2761 16.113 11.0349C15.9798 11.0059 15.8954 10.875 15.9247 10.7427C15.9539 10.6103 16.0856 10.5265 16.2189 10.5556C17.5416 10.8432 18.343 11.617 18.4171 12.6782C18.5094 13.9962 17.4174 15.3785 15.8209 15.9654C15.4217 16.1137 14.9993 16.191 14.5732 16.1937Z" fill="white"/>
                <path d="M14.1688 17.8624C13.6861 17.8647 13.2052 17.8027 12.7391 17.6781C11.6341 17.3768 10.8564 16.6414 10.6585 15.7108C10.4556 14.7562 10.8752 13.7748 11.8094 13.0182C12.9515 12.0931 14.8547 11.5681 16.1326 12.4152C16.4624 12.6287 16.6637 12.991 16.6696 13.3819C16.6682 14.0024 16.3872 14.5897 15.9038 14.9827C15.8348 15.0385 15.741 15.0531 15.6582 15.0211C15.5753 14.989 15.5162 14.9152 15.5034 14.8278C15.4906 14.7404 15.5261 14.6529 15.5964 14.5987C15.9546 14.302 16.166 13.8658 16.1761 13.4026C16.1753 13.1686 16.0559 12.9509 15.8585 12.8233C14.8162 12.133 13.14 12.5737 12.1208 13.3985C11.3355 14.035 10.9789 14.8406 11.1417 15.6093C11.3006 16.3568 11.9467 16.9533 12.8696 17.2048C14.8892 17.7553 16.9647 16.7469 16.9861 16.7366C17.1081 16.676 17.2565 16.7252 17.3174 16.8464C17.3784 16.9676 17.3289 17.115 17.2069 17.1755C16.2541 17.6142 15.2189 17.8483 14.1688 17.8624Z" fill="white"/>
                <path d="M18.2255 16.5382C18.1192 16.5382 18.0248 16.4707 17.9912 16.3705C17.9576 16.2703 17.9923 16.16 18.0773 16.0967C19.0969 15.335 19.7557 14.4642 20.0912 13.4338C20.1178 13.3496 20.1878 13.286 20.2746 13.2673C20.3614 13.2486 20.4516 13.2776 20.5109 13.3433C20.5702 13.409 20.5894 13.5013 20.5612 13.5849C20.1935 14.7144 19.4779 15.6642 18.3739 16.489C18.3311 16.521 18.279 16.5383 18.2255 16.5382Z" fill="white"/>
                <path d="M9.5047 13.3796C9.40839 13.3796 9.32088 13.3239 9.28054 13.2371C9.24019 13.1502 9.25435 13.048 9.31683 12.9752C10.7546 11.2996 13.3391 10.3311 14.8514 10.478C14.9408 10.4845 15.0197 10.5387 15.0574 10.6194C15.0951 10.7002 15.0858 10.7949 15.0331 10.8669C14.9804 10.9389 14.8925 10.9769 14.8035 10.9662C13.4441 10.8353 11.011 11.7574 9.69274 13.2936C9.64577 13.3482 9.57704 13.3797 9.5047 13.3796Z" fill="white"/>
                <path d="M10.9479 19.9049C10.915 19.9049 10.8825 19.8983 10.8522 19.8856C9.61133 19.367 8.71165 18.4726 8.31647 17.367C7.92854 16.2786 8.05022 15.0578 8.65929 13.929C8.70034 13.8506 8.78067 13.8002 8.86958 13.797C8.95849 13.7939 9.04224 13.8385 9.08882 13.9137C9.13541 13.989 9.13763 14.0834 9.09464 14.1607C8.55127 15.1662 8.44111 16.2467 8.78179 17.2027C9.12872 18.1762 9.93192 18.9689 11.043 19.4326C11.1515 19.4778 11.2128 19.5928 11.1895 19.7074C11.1662 19.822 11.0647 19.9043 10.947 19.9041L10.9479 19.9049Z" fill="white"/>
                <path d="M13.8707 20.3719C13.2745 20.3758 12.6805 20.3005 12.1044 20.1481C12.0174 20.1266 11.949 20.06 11.9256 19.9741C11.9022 19.8882 11.9274 19.7964 11.9916 19.7343C12.0558 19.6722 12.1488 19.6495 12.2346 19.6749C14.3451 20.2473 17.0963 19.6219 19.7832 17.9585C19.8581 17.9122 19.9522 17.909 20.03 17.9502C20.1079 17.9915 20.1577 18.0708 20.1607 18.1584C20.1637 18.246 20.1194 18.3285 20.0445 18.3749C17.9323 19.6826 15.7685 20.3719 13.8707 20.3719Z" fill="white"/>
                <path d="M20.4573 12.5864H20.4512C20.3857 12.5848 20.3235 12.5575 20.2783 12.5104C20.2331 12.4633 20.2086 12.4002 20.2102 12.3352C20.2369 11.2476 19.8479 10.3245 19.0839 9.66579C17.8752 8.62287 15.8494 8.27289 13.3802 8.68077C13.2925 8.69667 13.203 8.66438 13.1459 8.59628C13.0889 8.52818 13.0733 8.43484 13.1051 8.35208C13.1368 8.26931 13.211 8.20998 13.2992 8.19684C15.9163 7.7641 18.0858 8.15464 19.4086 9.29586C20.2869 10.0537 20.7353 11.1089 20.7048 12.3474C20.7013 12.4806 20.5915 12.5867 20.4573 12.5864Z" fill="white"/>
                <path d="M5.24802 14.0786C5.16145 14.0786 5.08119 14.0336 5.03647 13.96C4.99176 13.8863 4.98912 13.7948 5.02952 13.7188C6.48624 10.9642 10.028 8.93217 12.0245 8.52462C12.1121 8.5041 12.204 8.53255 12.2644 8.59887C12.3247 8.66519 12.344 8.75887 12.3146 8.84339C12.2853 8.92791 12.212 8.98987 12.1233 9.00511C10.2236 9.3932 6.8526 11.3265 5.46619 13.9473C5.42362 14.0278 5.33964 14.0784 5.24802 14.0786Z" fill="white"/>
                <path d="M4.83106 19.9743C4.75219 19.9744 4.67803 19.937 4.6315 19.8737C3.61688 18.4946 3.97913 15.8566 4.6855 14.5725C4.75252 14.4571 4.89997 14.4159 5.01769 14.4797C5.13541 14.5435 5.1804 14.6889 5.11905 14.8073C4.49072 15.9503 4.14362 18.3789 5.0303 19.5843C5.08515 19.6588 5.0932 19.7577 5.05114 19.84C5.00908 19.9223 4.92403 19.9743 4.83106 19.9743Z" fill="white"/>
                <path d="M21.0685 17.6117C20.9618 17.6117 20.8672 17.5438 20.8337 17.4432C20.8003 17.3426 20.8357 17.2321 20.9214 17.1691C22.277 16.1728 24.1596 13.6676 24.0993 11.2181C24.0972 11.1304 24.1423 11.0483 24.2177 11.0027C24.293 10.957 24.3872 10.9548 24.4646 10.9968C24.5421 11.0388 24.5912 11.1187 24.5933 11.2063C24.6568 13.8315 22.6559 16.5042 21.2147 17.5634C21.1724 17.5947 21.1211 17.6116 21.0685 17.6117Z" fill="white"/>
                <path d="M24.3574 10.5524H24.3449C24.2087 10.5456 24.1038 10.4304 24.1106 10.2951C24.1195 9.5606 23.9474 8.83503 23.6094 8.18166C23.543 8.06329 23.5858 7.91388 23.705 7.84795C23.8241 7.78201 23.9746 7.82452 24.041 7.94289C24.4241 8.67652 24.6176 9.4933 24.6039 10.3197C24.5972 10.45 24.4888 10.5523 24.3574 10.5524Z" fill="white"/>
            </svg>
        </div>
    );
};

export default GalaxyIcon;
