import { MCQ } from "../../domain/entity/MCQ";

export const LOAD_MCQ = 'LOAD_MCQ'
export const LOAD_MCQ_FAILED = 'LOAD_MCQ_FAILED'
export const LOAD_MCQ_SUCCEEDED = 'LOAD_MCQ_SUCCEEDED'

export interface LoadMCQAction {
    type: typeof LOAD_MCQ;
    payload: string;
}

export interface LoadMCQFailedAction {
    type: typeof LOAD_MCQ_FAILED;
    payload: string;
}

export interface LoadMCQSucceededAction {
    type: typeof LOAD_MCQ_SUCCEEDED;
    payload: MCQ;
}

export type LoadMCQActionTypes =
    LoadMCQAction
    | LoadMCQFailedAction
    | LoadMCQSucceededAction
