import { Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { TeamMateService } from '../../domain/gateway/teamMate.service';
import { LoadTeamMateState } from '../../config/state';
import { TeamMate } from '../../domain/entity/teamMate';
import { LOAD_TEAM_MATE, LoadTeamMateAction } from "./actionTypes";
import { loadTeamMateFailed, loadTeamMateSucceeded } from "./actions";

export const loadTeamMateEpic: Epic = (action$: Observable<LoadTeamMateAction>,
                                       store: StateObservable<LoadTeamMateState>,
                                       {teamMateService}: { teamMateService: TeamMateService }) =>
    action$.pipe(
        ofType(LOAD_TEAM_MATE),
        switchMap((action) => teamMateService.loadTeamMate(action.payload)
            .pipe(
                map((teamMate: TeamMate) => loadTeamMateSucceeded(teamMate)),
                catchError((error: string) => of(loadTeamMateFailed(error)))
            )
        )
    )
