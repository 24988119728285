import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { authenticationRootEpic } from "../authentication/config/rootEpic";
import { profileRootEpics } from "../profile/config/epics";
import { messageRootEpic } from "../message/config/epic";
import { scoreEpic } from "../score/usecases/epic";
import { speedQuizRootEpic } from "../speedQuiz/config/epic";
import { loadTeamMateEpic } from "../teamMate/usecases/load/epic";
import { MCQEpic } from "../MCQ/config/epic";
import { adventureRootEpic } from "../adventure/config/epics";
import { authenticationEpicDependencies } from "../authentication/config/epic.dependencies";
import { profileEpicsDependencies } from "../profile/config/dependencies.redux";
import { messageEpicDependencies } from "../message/config/epic.dependencies";
import { scoreEpicsDependencies } from "../score/config/epic.dependencies";
import { speedQuizEpicDependencies } from "../speedQuiz/config/epic.dependencies";
import { teamMateEpicDependencies } from "../teamMate/config/epic.dependencies";
import { MCQEpicDependencies } from "../MCQ/config/epic.dependencies";
import { adventureEpicDependencies } from "../adventure/config/epic.dependencies";
import { CommonEpicsDependencies } from "../shared/config/dependencies.redux";
import { appLoggerEpic } from "../shared/usecases/logging/logging.epic";


export const rootEpics = combineEpics(
    authenticationRootEpic,
    profileRootEpics,
    messageRootEpic,
    scoreEpic,
    speedQuizRootEpic,
    loadTeamMateEpic,
    MCQEpic,
    adventureRootEpic,
    appLoggerEpic
)

export const epicsMiddleware = createEpicMiddleware({
    dependencies: {
        ...authenticationEpicDependencies,
        ...profileEpicsDependencies,
        ...messageEpicDependencies,
        ...scoreEpicsDependencies,
        ...speedQuizEpicDependencies,
        ...teamMateEpicDependencies,
        ...MCQEpicDependencies,
        ...adventureEpicDependencies,
        ...CommonEpicsDependencies
    }
})
