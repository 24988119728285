import { MCQAnswerType } from "../../domain/type/MCQAnswer.type";
import { MCQScore } from "../../domain/entity/MCQScore";

export const SEND_MCQ_ANSWERS = 'SEND_MCQ_ANSWERS'
export const SEND_MCQ_ANSWERS_FAILED = 'SEND_MCQ_ANSWERS_FAILED'
export const SEND_MCQ_ANSWERS_SUCCEEDED = 'SEND_MCQ_ANSWERS_SUCCEEDED'

export interface SendMCQAnswersAction {
    type: typeof SEND_MCQ_ANSWERS;
    payload: {
        mcqId: number;
        adventureId: string;
        answers: MCQAnswerType[];
    };
}

export interface SendMCQAnswersFailedAction {
    type: typeof SEND_MCQ_ANSWERS_FAILED;
    payload: string;
}

export interface SendMCQAnswersSucceededAction {
    type: typeof SEND_MCQ_ANSWERS_SUCCEEDED;
    payload: MCQScore;
}

export type SendMCQAnswersActionTypes =
    SendMCQAnswersAction
    | SendMCQAnswersFailedAction
    | SendMCQAnswersSucceededAction
