import { SpeedQuizDetailsState } from "../../config/state";
import {
    LOAD_SPEED_QUIZ_DETAILS,
    LOAD_SPEED_QUIZ_DETAILS_FAILED,
    LOAD_SPEED_QUIZ_DETAILS_SUCCEEDED,
    LoadSpeedQuizDetailsActionTypes
} from "./actionTypes";

const initialState: SpeedQuizDetailsState = {
    loading  : false,
    speedQuiz: undefined,
    error    : undefined
}

export const loadSpeedQuizDetailsReducer = (state = initialState, action: LoadSpeedQuizDetailsActionTypes): SpeedQuizDetailsState => {
    switch (action.type) {
        case LOAD_SPEED_QUIZ_DETAILS:
            return {
                loading  : true,
                speedQuiz: undefined,
                error    : undefined
            }

        case LOAD_SPEED_QUIZ_DETAILS_FAILED:
            return {
                loading  : false,
                speedQuiz: undefined,
                error    : action.payload
            }

        case LOAD_SPEED_QUIZ_DETAILS_SUCCEEDED:
            return {
                loading  : false,
                speedQuiz: action.payload,
                error    : undefined
            }

        default:
            return state
    }
}
