import { AvatarType } from "../../domain/entities/types";

export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_AVATAR_FAILED = 'UPDATE_AVATAR_FAILED'
export const UPDATE_AVATAR_SUCCEEDED = 'UPDATE_AVATAR_SUCCEEDED'

export interface UpdateAvatarAction {
    type: typeof UPDATE_AVATAR;
    payload: AvatarType;
}

export interface UpdateAvatarFailedAction {
    type: typeof UPDATE_AVATAR_FAILED;
    payload: string;
}

export interface UpdateAvatarSucceededAction {
    type: typeof UPDATE_AVATAR_SUCCEEDED;
}

export type UpdateAvatarActionsTypes = UpdateAvatarAction | UpdateAvatarFailedAction | UpdateAvatarSucceededAction
