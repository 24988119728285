import { CATCH_MESSAGE, CATCH_MESSAGE_FAILED, CATCH_MESSAGE_SUCCEEDED, CatchMessageActionTypes, } from "./actionTypes";
import { HandleRequestState } from "../../../redux-configuration/AppState";

const initialState: HandleRequestState = {
    loading: false,
    success: undefined,
    error  : undefined
};
export const catchMessageReducer = (state = initialState, action: CatchMessageActionTypes): HandleRequestState => {
    switch (action.type) {
        case CATCH_MESSAGE:
            return {
                loading: true,
                success: undefined,
                error  : undefined
            }
        case CATCH_MESSAGE_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        case CATCH_MESSAGE_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        default:
            return state;
    }
}
