import React, { useEffect } from "react";
import "./teamMate.css"
import { useNavigate, useParams } from "react-router-dom";
import LoadingContainer from "../../../shared/adapters/primaries/loading/loading";
import { RegionBackground } from "../../../shared/adapters/primaries/region.background";
import { findAvatar } from "../../../profile/config/findAvatar";
import GalaxyIcon from "../../../shared/adapters/primaries/icons/galaxy.icon";
import PinIcon from "../../../shared/adapters/primaries/icons/pin.icon";
import { TeamMate } from "../../domain/entity/teamMate";
import ScoreIcon from "../../../shared/adapters/primaries/icons/score.icon";
import { TeamMateBadgesList } from "./components/teamMate.badges.list";
import { BackIcon } from "../../../shared/adapters/primaries/icons/back.icon";
import { AppEvent } from "../../../shared/domain/entities/logging/AppEvent";
import { BeaconsType } from "../../../shared/domain/entities/logging/beacons.type";
import { AppEventBuilder } from "../../../shared/domain/entities/logging/appEvent.builder";

interface Props {
    loading: boolean;
    teamMate: TeamMate | undefined;
    loadTeamMate: (email: string) => void;
    log: (event: AppEvent) => void;
}

export default function TeamMateContainer(props: Props) {

    const router = useNavigate()

    const {email} = useParams();

    useEffect(() => {
        if (email !== undefined) {
            props.loadTeamMate(email)
            const event = (new AppEventBuilder())
                .withBeacon(BeaconsType.DISPLAY_TEAM_MATE)
                .withReferences([{key: 'teamMateEmail', value: email}])
                .build()
            props.log(event)
        } else {
            router('/profile')
        }
    }, [])


    if (props.loading) {
        return <LoadingContainer/>
    }

    return (
        <div className="team-dashboard">
            <div className="overlap-group">
                <div className={'adventure-data'}>
                    <div className={'data-section'}>
                        <div className={"d-flex flex-row align-items-start"}>
                            <BackIcon onClick={() => router(-1)}/>
                        </div>
                        <div className={'d-flex flex-column justify-content-center align-items-center mt-5'}>
                            <RegionBackground styles={{width: 150, height: 150, padding: 5, borderRadius: 75}}
                                              region={props.teamMate?.region}>
                                <div className={"position-relative"}>
                                    <img className={'profile-image'} width={140} height={140}
                                         src={findAvatar(props.teamMate?.avatar).image}
                                         alt={'avatar'}/>
                                </div>
                            </RegionBackground>
                            <div className={'d-flex flex-row gap-2 mt-3 text-md-center'}>
                                <ScoreIcon width={20}/> {props.teamMate?.score}
                            </div>
                            <div className={"mt-3 w-100"}>
                                <div
                                    className={'profile-metadata d-flex flex-column justify-content-start align-items-center mt-3'}>
                                    <h4>{props.teamMate?.firstName} {props.teamMate?.lastName} </h4>
                                </div>
                                <div className={"d-flex flex-row justify-content-evenly w-100 gap-2 mt-3"}>
                                    <div
                                        className={"frame d-flex flex-row gap-2 justify-content-center align-items-center"}>
                                        <GalaxyIcon width={29}/>
                                        <h6 className={"m-0 w-100"}>{props.teamMate?.region}</h6>
                                    </div>
                                    <div
                                        className={"frame d-flex flex-row gap-2 justify-content-center align-items-center"}>
                                        <PinIcon/>
                                        <h6 className={"m-0 w-100"}>{props.teamMate?.agency}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'profile-section-header'}>
                            <TeamMateBadgesList badgeIds={props.teamMate?.badges}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
