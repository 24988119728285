import { Epic, ofType, StateObservable } from 'redux-observable';
import { concatMap, Observable, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { AuthenticationService } from '../../domain/gateway/authentication.service';
import { requestPasswordFailed, requestPasswordSucceeded } from "./actions";
import { REQUEST_PASSWORD, RequestPasswordAction } from "./actionTypes";
import { HandleRequestState } from "../../../redux-configuration/AppState";
import { BeaconsType } from "../../../shared/domain/entities/logging/beacons.type";
import { logEvent } from "../../../shared/usecases/logging/logging.action";
import { AppEventBuilder } from "../../../shared/domain/entities/logging/appEvent.builder";

export const requestPasswordEpic: Epic = (action$: Observable<RequestPasswordAction>,
                                          store: StateObservable<HandleRequestState>,
                                          { authenticationService }: { authenticationService: AuthenticationService }) =>
    action$.pipe(
        ofType(REQUEST_PASSWORD),
        switchMap(
            action => authenticationService.requestPassword(action.payload)
                .pipe(
                    concatMap(() => [
                        logEvent(
                            (new AppEventBuilder())
                                .withBeacon(BeaconsType.EVENT_LOGIN_ASK_FOR_PASSWORD)
                                .withSecured(false)
                                .withValue(action.payload)
                                .build()
                        ),
                        requestPasswordSucceeded()
                    ]),
                    catchError(error => of(requestPasswordFailed(error)))
                )
        )
    )
