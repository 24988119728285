import { SpeedQuizAnswer } from "./answer";

export class SpeedQuizQuestion {
    constructor(
        protected _id: string,
        protected _text: string,
        protected _time: number,
        protected _image: string,
        protected _numberOfCorrectAnswers: number,
        protected _answers: SpeedQuizAnswer[]
    ) {}


    get id(): string {
        return this._id;
    }

    get text(): string {
        return this._text;
    }

    get time(): number {
        return this._time;
    }

    get image(): string {
        return this._image;
    }

    get numberOfCorrectAnswers(): number {
        return this._numberOfCorrectAnswers;
    }

    get answers(): SpeedQuizAnswer[] {
        return this._answers;
    }
}
