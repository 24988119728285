import React, { useEffect, useState } from 'react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import parse from 'html-react-parser';
import './onBoarding.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { AuthenticationI18n } from "../../../config/i18n";
import LoadingContainer from "../../../../shared/adapters/primaries/loading/loading";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';


export default function OnboardingContainer() {

    const [notReady, setNotReady] = useState<boolean>(true)
    const [width, setWidth] = useState<string>("25%")
    const [activeSlide, setActiveSlide] = useState<number>(0)

    const slides: Array<{ img: string; description: string }> = [
        {img: require('../../../../assets/img/story/story_0.jpeg'), description: AuthenticationI18n.welcome},
        {img: require('../../../../assets/img/story/story_1.jpeg'), description: AuthenticationI18n.explanation},
        {img: require('../../../../assets/img/story/story_2.jpeg'), description: AuthenticationI18n.ready},
        {img: require('../../../../assets/img/story/story_3.jpeg'), description: ''}
    ]

    useEffect(() => {
        setNotReady(false)
    }, [])

    if (notReady) {
        return <LoadingContainer/>
    }

    const swiperOption = {
        effect         : 'coverflow',
        centeredSlides : true,
        slidesPerView  : 1.75,
        coverflowEffect: {
            rotate      : 0,
            stretch     : 120,
            depth       : 400,
            modifier    : 2,
            slideShadows: true,
        },
        navigation     : {
            enabled: true,
            nextEl : '.swiper-button-next',
            prevEl : '.swiper-button-prev',
        },
        modules        : [Pagination, EffectCoverflow, Navigation]
    }

    return (
        <div className={'container-fluid'}>
            <div className={'d-flex flex-column align-items-center justify-content-center container onboarding-page '}>
                <div className="onboarding-header">
                    <img src={require('../../../../assets/img/login/login-header.png')} alt={'login-header'}
                         className={'img-fluid bg-transparent'}/>
                </div>

                <div className={'onboarding-swiper'}>
                    <Swiper
                        {...swiperOption}
                        className="swiper_container"
                        onSlideChange={(e) => {
                            setActiveSlide(e.activeIndex)
                            setWidth(((e.activeIndex + 1) * 25) + "%")
                        }}>

                        {slides.map((item, index) =>
                            <SwiperSlide key={index}>
                                <img className={'img-fluid slide-image'}
                                     src={item.img}
                                     alt="slide_image"/>
                            </SwiperSlide>
                        )}

                        <div className="slider-controler">
                            <div className="swiper-button-prev slider-arrow"></div>
                            <div className="swiper-button-next slider-arrow"></div>
                        </div>
                    </Swiper>

                    <div className="onboarding-text">
                        {activeSlide !== 3 ?
                            <p>{parse(slides[activeSlide].description)}</p> :
                            <button className={'large-blue-button'}
                                    onClick={() => window.location.href = '/dashboard'}>
                                {AuthenticationI18n.start}
                            </button>
                        }
                    </div>

                    <div className={'d-flex flex-row align-items-center justify-content-center'}>
                        <div className="swiper-progress-bar">
                            <div className="progress" style={{width}}/>
                        </div>

                        <div className="swiper-counter">
                            <span>{activeSlide + 1} / 4</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
