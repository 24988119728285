import {
    LOAD_ADVENTURE_DETAILS,
    LOAD_ADVENTURE_DETAILS_FAILED,
    LOAD_ADVENTURE_DETAILS_SUCCEEDED,
    LoadAdventureDetailsActionTypes
} from './actionTypes';
import { LoadAdventureDetailsState } from '../../config/state';

const initialState: LoadAdventureDetailsState = {
    adventure: undefined,
    loading  : false,
    error    : undefined
}

export const loadAdventureDetailsReducer = (state = initialState, action: LoadAdventureDetailsActionTypes): LoadAdventureDetailsState => {
    switch (action.type) {
        case LOAD_ADVENTURE_DETAILS:
            return {
                adventure: undefined,
                loading  : true,
                error    : undefined
            }
        case LOAD_ADVENTURE_DETAILS_FAILED:
            return {
                adventure: undefined,
                loading  : false,
                error    : action.payload
            }
        case LOAD_ADVENTURE_DETAILS_SUCCEEDED:
            return {
                adventure: action.payload,
                loading  : false,
                error    : undefined
            }
        default:
            return state
    }
}
