import React from 'react';
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { Adventure } from "../../../../domain/entities/adventure";
import { ApplicationContext } from "../../../../../shared/config/application.context";
import { AdventureI18n } from "../../../../config/i18n";

const moment = ApplicationContext.getInstance().momentJs();

interface Props {
    adventure: Adventure;
    onClose: () => void;
}

export const AdventureDescriptionModal = (props: Props) => {

    return (
        <div className={"modal modal-details adventure-modal show"} tabIndex={-1} role="dialog"
             onClick={(): void => props.onClose()}>
            <div className="modal-dialog " role="document" style={{minWidth: '50%'}} onClick={e => e.stopPropagation()}>
                <div className="modal-content">
                    <button type="button" className="close" onClick={(): void => props.onClose()}>
                        <span/><span/>
                    </button>

                    <div className="modal-header"/>
                    <div className={'d-flex modal-body flex-column'}>
                        <div className={'d-flex flex-row gap-3'}>
                            <div className={'adventure-metadata'}>
                                <div className={'mb-5'}>
                                    <p>{props.adventure.earns}</p>
                                    <span>{AdventureI18n.redPoint}</span>
                                </div>

                                <div className={'mb-4'}>
                                    <span>{AdventureI18n.from}</span>
                                    <p>{moment(props.adventure.starDate).format('DD/MM/YYYY')}</p>
                                </div>

                                <div className={'mb-4'}>
                                    <span>{AdventureI18n.to}</span>
                                    <p>{moment(props.adventure.endDate).format('DD/MM/YYYY')}</p>
                                </div>

                            </div>

                            <div className={'adventure-data flex-column'}>
                                <h5>{props.adventure.label}</h5>
                                <div style={{
                                    maxHeight: '50vh',
                                    overflow : 'scroll'
                                }}>
                                    <img className={'img-fluid w-100'} src={require('../../../../../assets/img/adventure/adventure-spacecraft.png')}
                                         alt={'adventure-preview'}/>
                                    <div className={'description'}>{parse(props.adventure.description)}</div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <Link to={"/adventure/" + props.adventure.id} className={'large-blue-button'}
                              onClick={() => props.onClose()}>
                            {AdventureI18n.back}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
