import { SpeedQuizUserAnswers } from "../../domain/entity/userAnswers";
import {
    SEND_SPEED_QUIZ_ANSWERS,
    SEND_SPEED_QUIZ_ANSWERS_FAILED,
    SEND_SPEED_QUIZ_ANSWERS_SUCCEEDED,
    SendSpeedQuizAnswersAction,
    SendSpeedQuizAnswersFailedAction,
    SendSpeedQuizAnswersSucceededAction
} from "./actionTypes";

export const sendSpeedQuizAnswers = (speedQuizId: string, roundId: string, answers: SpeedQuizUserAnswers[]): SendSpeedQuizAnswersAction => ({
    type   : SEND_SPEED_QUIZ_ANSWERS,
    payload: {speedQuizId, roundId, answers}
})

export const sendSpeedQuizAnswersFailed = (error: string): SendSpeedQuizAnswersFailedAction => ({
    type   : SEND_SPEED_QUIZ_ANSWERS_FAILED,
    payload: error
})

export const sendSpeedQuizAnswersSucceeded = (): SendSpeedQuizAnswersSucceededAction => ({
    type: SEND_SPEED_QUIZ_ANSWERS_SUCCEEDED
})
