import React, { JSX } from "react";
import { Badge } from "../../../domain/entities/badge/badge";
import '../profile.modal.css'
import { ProfileI18n } from "../../../config/i18n";

export const BadgeDetailsModal = (props: { badge: Badge; onClose: () => void; }): JSX.Element => {

    const title = props.badge.isEncrypted ?
        ProfileI18n.badgeEncrypted : ProfileI18n.badgeWine

    const image = props.badge.isEncrypted ?
        <span>?</span> : <img className={"img-fluid"} src={props.badge.picture} alt={props.badge.name}/>

    return (
        <div className={"modal modal-details modal-wrapper"} tabIndex={-1} role="dialog"
             onClick={(): void => props.onClose()}>
            <div className="modal-dialog" role="document">
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <button type="button" className="close" onClick={(): void => props.onClose()}>
                        <span/><span/>
                    </button>

                    <div className="modal-body">
                        <div className="badge-details">
                            <span>{title}</span>

                            <div className="badge-container my-5">
                                <div className="hex-background">
                                    {image}
                                </div>
                            </div>

                            <h3 className={'mb-4'}>{props.badge.name}</h3>

                            <p>{props.badge.description}</p>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button className={'large-blue-button'}
                                onClick={(): void => props.onClose()}>{ProfileI18n.ok}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
