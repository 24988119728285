import React from 'react';
import { Profile } from "../../../../../profile/domain/entities/profile";
import { AdventureScore } from "../../../../domain/entities/score/adventureScore";
import { AdventureI18n } from "../../../../config/i18n";
import { AdventureSpecifications } from "../../specifications";

interface Props {
    profile: Profile | undefined;
    adventureScore: AdventureScore | undefined;
}

const SupportStandardDashboard = (props: Props) => {
    return (
        <React.Fragment>
            <div className={'flex-box'}>

                <div className={'box-adventure-info'}>
                    <p>{AdventureI18n.turnover}</p>
                    <strong>{AdventureSpecifications.formatNumber(props.adventureScore?.totalTurnover, ' ', ' €')}</strong>
                    <span className={'mt-2'}>
                       sur {AdventureSpecifications.formatNumber(props.adventureScore?.totalTurnoverGoal, ' ', ' €')}
                    </span>
                </div>

                <div className={'box-adventure-info'}>
                    <p>{AdventureI18n.cm}</p>
                    <strong>{props.adventureScore?.totalActivatedClient}</strong>
                    <span>sur {props.adventureScore?.totalActivatedClientGoal}</span>
                </div>
            </div>

            <div className={'box-100'}>
                <div className={'adventure-progress'}>
                    <div className="progress-title">
                        <span>{AdventureI18n.result}</span>
                    </div>
                    <div className="progressbar">
                        <div className={'progress-bg'} style={{marginTop: 10}}>
                            <div className="actual-progress"
                                 style={{
                                     width: props.adventureScore?.getTotalScoreWithPercentage()
                                 }}/>
                        </div>
                        <div className="actual-progress-val" style={{marginTop: 0}}>
                            {props.adventureScore?.getTotalScoreWithPercentage()}
                        </div>
                    </div>
                    <div className={'d-flex justify-content-between fex-row progress-bar-values'}>
                        <p>{AdventureSpecifications.formatNumber(props.adventureScore?.totalTurnover, ' ', ' €')}</p>
                        <p>{AdventureSpecifications.formatNumber(props.adventureScore?.totalTurnoverGoal, ' ', ' €')}</p>
                    </div>
                    <div className={'d-flex justify-content-between fex-row progress-bar-values'}>
                        <p>{AdventureSpecifications.formatNumber(props.adventureScore?.totalActivatedClient, ' ', '')}</p>
                        <p>{AdventureSpecifications.formatNumber(props.adventureScore?.totalActivatedClientGoal, ' ', '')}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default SupportStandardDashboard;
