export class MCQScore {
    constructor(
        protected _score: number,
        protected _canAccessAdventure: boolean
    ) {}

    get score(): number {
        return this._score;
    }

    get canAccessAdventure(): boolean {
        return this._canAccessAdventure;
    }
}
