import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { AppState } from "../../../../redux-configuration/AppState";
import { destroyUserSession, logoutUser } from "../../../../authentication/usecases/logout/actions";
import { Link } from "react-router-dom";
import { NavigationI18n } from "../../../config/I18n";
import { ProfileIcon } from "../../../../shared/adapters/primaries/icons/profile.icon";
import { RocketIcon } from "../../../../shared/adapters/primaries/icons/rocket.icon";
import { GlobalScoreIcon } from "../../../../shared/adapters/primaries/icons/globalScore.icon";
import { DashboardIcon } from "../../../../shared/adapters/primaries/icons/dashboard.icon";
import { LogoutIcon } from "../../../../shared/adapters/primaries/icons/logout.icon";
import { useLocation } from 'react-router-dom';

export default function Sidebar() {
    const dispatch = useDispatch()
    const currentPath = useLocation().pathname;
    const isLoggedOut = useSelector((state: AppState) => state.authentication.logout.success)

    const refreshPage = () => {
        dispatch(destroyUserSession())
        window.location.href = '/login'
    };

    useEffect(() => {
        if (isLoggedOut) {
            refreshPage()
        }
    }, [isLoggedOut])

    const logout = () => {
        const response = window.confirm(NavigationI18n.disconnect);
        if (response) {
            dispatch(logoutUser())
        }
    }

    const isActive = (pathUrl: string) => {
        if (currentPath === pathUrl) {
            return 'active'
        }
        return ''
    }

    return (
        <div className="sidebar">
            <div className="sidebar-content">
                <Link to="/dashboard" className="sidebar-logo">
                    <img alt={'Sonepar'}
                         src={require('../../../../assets/img/sonepar-logo.png')}/>
                </Link>

                <div className="sidebar-menu">
                    <Link to="/dashboard" className={isActive('/dashboard')}>
                        <DashboardIcon isSelected={() => currentPath === '/dashboard'} width={42} height={34}/>
                        <span>Dashboard</span>
                    </Link>

                    <Link to="/score" className={isActive('/score')}>
                        <GlobalScoreIcon isSelected={() => currentPath === '/score'} width={46} height={40}/>
                        <span>Score</span>
                    </Link>

                    <Link to="/missions" className={isActive('/missions')}>
                        <RocketIcon isSelected={() => currentPath === '/missions'} width={46} height={40}/>
                        <span>Missions</span>
                    </Link>

                    <Link to="/profile" className={isActive('/profile')}>
                        <ProfileIcon isSelected={() => currentPath === '/profile'} width={46} height={46}/>
                        <span>Profile</span>
                    </Link>
                </div>
            </div>

            <div className="sidebar-content">
                <Link to='' onClick={() => logout()} className={'logout-btn'}>
                    <LogoutIcon width={16} height={16}/>
                </Link>
            </div>
        </div>
    )
}
