import { SpeedQuizDetails } from "../entity/details";
import { SpeedQuizRoundStatusType, SpeedQuizStatusType } from "../types";
import { SpeedQuizRound } from "../entity/round";
import { SpeedQuizPlayer } from "../entity/player";

export class SpeedQuizDetailsBuilder {
    protected _id!: string
    protected _status!: SpeedQuizStatusType
    protected _title!: string
    protected _message!: string
    protected _backgroundImage!: string
    protected _user!: SpeedQuizPlayer
    protected _opponent!: SpeedQuizPlayer
    protected _endDate!: Date
    protected _nextRoundId!: string
    protected _nextRoundStatus!: SpeedQuizRoundStatusType
    protected _rounds!: SpeedQuizRound[]

    withId(value: string): SpeedQuizDetailsBuilder {
        this._id = value
        return this
    }

    withStatus(value: SpeedQuizStatusType): SpeedQuizDetailsBuilder {
        this._status = value
        return this
    }

    withTitle(value: string): SpeedQuizDetailsBuilder {
        this._title = value
        return this
    }

    withMessage(value: string): SpeedQuizDetailsBuilder {
        this._message = value
        return this
    }

    withBackgroundImage(value: string): SpeedQuizDetailsBuilder {
        this._backgroundImage = value
        return this
    }

    withUser(value: SpeedQuizPlayer): SpeedQuizDetailsBuilder {
        this._user = value
        return this
    }

    withOpponent(value: SpeedQuizPlayer): SpeedQuizDetailsBuilder {
        this._opponent = value
        return this
    }

    withEndDate(value: Date): SpeedQuizDetailsBuilder {
        this._endDate = value
        return this
    }

    withNextRoundId(value: string): SpeedQuizDetailsBuilder {
        this._nextRoundId = value
        return this
    }

    withNextRoundStatus(value: SpeedQuizRoundStatusType): SpeedQuizDetailsBuilder {
        this._nextRoundStatus = value
        return this
    }

    withRounds(value: SpeedQuizRound[]): SpeedQuizDetailsBuilder {
        this._rounds = value
        return this
    }

    build(): SpeedQuizDetails {
        return new SpeedQuizDetails(
            this._id,
            this._status,
            this._title,
            this._message,
            this._backgroundImage,
            this._user,
            this._opponent,
            this._endDate,
            this._nextRoundId,
            this._nextRoundStatus,
            this._rounds
        )
    }
}
