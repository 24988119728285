import { ScoreDTO } from "../dto/score.dto";
import { Score } from "../../../../domain/entities/score";
import { CollectiveScore } from "../../../../domain/entities/collectiveScore";
import { CollectiveScoreBuilder } from "../../../../domain/builder/collectiveScore.builder";
import { PlayerScore } from "../../../../domain/entities/playerScore";
import { PlayerScoreBuilder } from "../../../../domain/builder/playerScore.builder";

export class ScoreMapper {
    static mapScoreDTOToScore(scoreDTO: ScoreDTO): Score {

        const players: PlayerScore[] = scoreDTO.players.map(player => {
            const playerScore = new PlayerScoreBuilder()
                .withEmail(player.email)
                .withFullName(player.name)
                .withAgency(player.regionName)
                .withRegion(player.regionLabel)
                .withScore(player.score)
                .withRank(player.rank)

            if (player.avatarName) {
                playerScore.withAvatar(player.avatarName)
            }
            return playerScore.build()
        })

        const agencies: CollectiveScore[] = scoreDTO.agencies.map((agency, index) =>
            new CollectiveScoreBuilder()
                .withCode(agency.code)
                .withAgencyName(agency.name)
                .withRegionName(agency.regionName)
                .withRegionLabel(agency.regionLabel)
                .withScore(agency.score)
                .withRank(index + 1)
                .build()
        )

        const regions: CollectiveScore[] = scoreDTO.regions.map((region, index) =>
            new CollectiveScoreBuilder()
                .withRegionName(region.name)
                .withRegionLabel(region.label)
                .withScore(region.score)
                .withRank(index + 1)
                .build()
        )

        return {
            players,
            agencies,
            regions
        }
    }
}
