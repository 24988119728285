import React from 'react';
import { ApplicationContext } from "../../../../shared/config/application.context";
import { Adventure } from "../../../domain/entities/adventure";
import ColorlessScoreIcon from "../../../../shared/adapters/primaries/icons/colorlessScore.icon";
import { AdventureI18n } from "../../../config/i18n";
import { CalendarIcon } from "../../../../shared/adapters/primaries/icons/calendar.icon";

const moment = ApplicationContext.getInstance().momentJs()

const AdventureHeader = (props: { adventure: Adventure }) => {
    return (
        <div className={'adventure-header'}>
            <h1 className="adventure-label">{props.adventure.label}</h1>

            <div className="d-flex">
                <div className={'info-card'}>
                    <ColorlessScoreIcon className={'icon'}/>
                    <p>{props.adventure.earns}</p>
                </div>

                <div className={'mx-3 info-card'}>
                    <CalendarIcon className={'icon'}/>
                    <p>
                        {`${AdventureI18n.from}  ${moment(props.adventure.starDate).format('D MMMM YYYY')}  ${AdventureI18n.to} ${moment(props.adventure.endDate).format('D MMMM Y')}`}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AdventureHeader;
