import { BadgeType } from "../domain/types/badge.type";

export const badgeList: BadgeType[] = [
    {
        id         : 'IGNITION',
        title      : '3, 2, 1, 0, Ignition',
        description: 'Personnalisez la photo de votre profil en choisissant l’avatar qui vous correspond le mieux',
        picture    : require('../../assets/img/badge/3_2_1_IGNITION.png')
    },
    {
        id         : 'IN_ORBIT',
        title      : 'En orbite',
        description: 'Instructions : Obtenez un score supérieur à 100 Red Point',
        picture    : require('../../assets/img/badge/EN_ORBITE.png')
    },
    {
        id         : 'HYPERSPACE',
        title      : 'Hyperspace',
        description: 'Instructions : Atteignez 100% de votre objectif sur l’une de vos missions challenges',
        picture    : require('../../assets/img/badge/HYPERSPACE.png')
    },
    {
        id         : 'LIGHT_SPEED',
        title      : 'Vitesse lumière',
        description: 'Instructions : Atteignez 150% de votre objectif sur l’une de vos missions challenges',
        picture    : require('../../assets/img/badge/VITESSE_LUMIERE.png')
    },
    {
        id         : 'ADMIRAL_SPUTNIK',
        title      : 'Amiral Spoutnik',
        description: 'Instructions : Arrivez premier au classement des ventes',
        picture    : require('../../assets/img/badge/SPUTNIK.png')
    },
    {
        id         : 'CAPTAIN_FALCON',
        title      : 'Capitaine Falcon',
        description: 'Instructions : Arrivez dans le top 3 du classement des ventes',
        picture    : require('../../assets/img/badge/CAPTAIN_FALCON.png')
    },
    {
        id         : 'FIRST_HUMAN_STEP',
        title      : 'Un petit pas pour l’homme…',
        description: 'Instructions : Arrivez dans le top 10 du classement des joueurs',
        picture    : require('../../assets/img/badge/PETIT_PAS.png')
    },
    {
        id         : 'LONG_LIVE',
        title      : 'Longue vie et prospérité !',
        description: 'Instructions : Atteignez la première place du classement des joueurs',
        picture    : require('../../assets/img/badge/LONGUE_VIE.png')
    },
    {
        id         : 'LIMITLESS',
        title      : 'Aucune limite à mon pouvoir !',
        description: 'Instructions : Obtenez un score parfait à un QCM pour la première fois ',
        picture    : require('../../assets/img/badge/AUCUNE_LIMITE.png')
    },
    {
        id         : 'ARMSTRONG',
        title      : 'Armstrong',
        description: 'Instructions : Atteignez la première place du classement régional',
        picture    : require('../../assets/img/badge/ARMSTRONG.png')
    }
]
