import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux-configuration/AppState";
import { loadScoreLoadingSelector, playersScoreSelector } from "../../usecases/selectors";
import { loadScore } from "../../usecases/actions";
import DashboardHeader from "../../../dashboard/adapters/primaries/dashboard/components/header";
import LoadingContainer from "../../../shared/adapters/primaries/loading/loading";
import { ScoreI18n } from "../../config/i18n";
import GlobalScoreUserInfo from "./components/header";
import { profileSelector } from "../../../profile/usecases/profile.selector";
import CollectiveScoreList from "./components/collective.score";
import IndividualScoreList from "./components/individual.score";
import { AppEventBuilder } from "../../../shared/domain/entities/logging/appEvent.builder";
import { BeaconsType } from "../../../shared/domain/entities/logging/beacons.type";
import { logEvent } from "../../../shared/usecases/logging/logging.action";

export default function ScoreContainer() {

    const dispatch = useDispatch()

    const loading = useSelector((state: AppState) => loadScoreLoadingSelector(state))
    const profile = useSelector((state: AppState) => profileSelector(state))
    const players = useSelector((state: AppState) => playersScoreSelector(state))

    const userScore = players?.find(item => item.email === profile?.email)

    useEffect(() => {
        dispatch(loadScore())
        const event = (new AppEventBuilder())
            .withBeacon(BeaconsType.DISPLAY_SCORE)
            .build()
        dispatch(logEvent(event))

    }, [])

    return (
        <div className={'global-score'}>
            <DashboardHeader title={ScoreI18n.score}/>

            {profile && <GlobalScoreUserInfo profile={profile}
                                             rank={userScore ? userScore.rank : 0}/>}

            {loading && (
                <div className={'item loading-container'}>
                    <LoadingContainer/>
                </div>
            )}

            {players && (
                <div className={'row'}>
                    <IndividualScoreList/>

                    <CollectiveScoreList/>
                </div>
            )}
        </div>
    )
}
