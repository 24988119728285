import { MCQResponse } from "./MCQResponse";

export class MCQQuestion {
    constructor(
        protected _id: number,
        protected _text: string,
        protected _description: string,
        protected _picture: string,
        protected _validResponses: number,
        protected _responses: MCQResponse[]
    ) {
        this._responses = _responses
            .map(value => ({value, sort: Math.random()}))
            .sort((a, b) => a.sort - b.sort)
            .map(({value}) => value)
    }

    get id(): number {
        return this._id;
    }

    get text(): string {
        return this._text;
    }

    get description(): string {
        return this._description;
    }

    get picture(): string {
        return this._picture;
    }

    get validResponses(): number {
        return this._validResponses;
    }

    get responses(): MCQResponse[] {
        return this._responses;
    }
}
