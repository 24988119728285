import {
    RETRIEVE_PROFILE,
    RETRIEVE_PROFILE_FAILED,
    RETRIEVE_PROFILE_SUCCEEDED,
    RetrieveProfileAction,
    RetrieveProfileFailedAction,
    RetrieveProfileSucceededAction
} from "./retrieveProfile.types";

export const retrieveProfile = (): RetrieveProfileAction => ({
    type: RETRIEVE_PROFILE
})

export const retrieveProfileFailed = (error: string): RetrieveProfileFailedAction => ({
    type   : RETRIEVE_PROFILE_FAILED,
    payload: error
})

export const retrieveProfileSucceeded = (): RetrieveProfileSucceededAction => ({
    type   : RETRIEVE_PROFILE_SUCCEEDED,
})
