import React from "react"
interface Props {
    width: number;
    height: number;
    isSelected: () => boolean;
}

export function RocketIcon(props: Props) {
    const color = props.isSelected() ? '#FFF' : '#979797'

    return (
        <svg width={props.width} height={props.height} viewBox={`0 0 46 40`}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M32.59 16.765a.556.556 0 000-.132A29.637 29.637 0 0034 9.663c0-.332-.29-.6-.647-.6a.693.693 0 00-.576 0 34.311 34.311 0 00-6.23 3.959H26.5a.626.626 0 00-.214.198c-2.932 2.301-5.326 5.135-7.033 8.326a.686.686 0 00-.227.06c-.271.132-6.748 3.252-9.006 9.335a.582.582 0 00.288.661.687.687 0 00.766-.061c1.47-1.308 3.85-1.764 5.377-1.926l-1.19 1.704a.574.574 0 00.187.822l2.77 1.667a.679.679 0 00.886-.174l1.236-1.763c.466 1.361.983 3.563.291 5.345a.578.578 0 00.26.685.687.687 0 00.782-.05c5.073-4.379 5.52-11.133 5.532-11.397a.56.56 0 00-.032-.216c2.441-2.737 4.253-5.907 5.325-9.317a.587.587 0 00.09-.156zm-1.068-.906a8.551 8.551 0 01-3.792-2.231 34.855 34.855 0 014.918-3.174 30.032 30.032 0 01-1.126 5.405zm-4.769-1.445a9.829 9.829 0 004.394 2.585c-1.657 4.751-5.054 10.522-11.99 13.258l-.647-.372c-.058-6.965 4.25-12.148 8.243-15.471zm-8.282 8.89a17.438 17.438 0 00-1.165 5.07 15.164 15.164 0 00-5.208 1.145c1.55-2.519 3.738-4.652 6.373-6.215zm-.09 9.215l-1.696-1.02.608-.864v.6c.06.22.213.41.425.522a.962.962 0 00.701.078c.194-.066.376-.138.563-.204l-.602.888zm2.86-1.848a20.076 20.076 0 004.529-3.048c-.468 2.857-1.676 5.566-3.52 7.895a12.36 12.36 0 00-1.01-4.865v.018z"
                    fill={color}
                    fillRule="nonzero"
                />
                <path
                    d="M23.135 24.594a2.482 2.482 0 001.88.352 2.5 2.5 0 10-1.88-.352zm.257-2.81c.184-.303.483-.518.83-.596.09-.01.182-.01.274 0a1.313 1.313 0 11-1.104.596z"
                    fill={color}
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}
