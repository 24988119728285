import { Observable, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators'
import { MCQService } from "../../../domain/gateway/MCQ.service";
import { MCQ } from "../../../domain/entity/MCQ";
import { MCQDTO, MCQScoreDTO } from "./dto/MCQ.dto";
import { MCQMapper } from "./mapper/MCQ.mapper";
import { MCQScore } from "../../../domain/entity/MCQScore";
import { SecuredHttpClient } from "../../../../shared/adapters/secondaries/securedHttpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/config/dependencies.factory";
import { MCQAnswerType } from "../../../domain/type/MCQAnswer.type";

export class ApiMCQService implements MCQService {

    getMCQ(adventureId: string): Observable<MCQ> {
        const url = process.env.REACT_APP_API_URL + '/v1/mcq/' + adventureId

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: MCQDTO }>(url)
            .pipe(
                map((response: { data: MCQDTO }) => MCQMapper.mapMCQData(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    sendMCQAnswers(mcqId: number, answers: MCQAnswerType[]): Observable<MCQScore> {
        const url = process.env.REACT_APP_API_URL + '/v1/mcq/' + mcqId + '/responses'
        const body = new FormData();

        body.append('mcqId', mcqId.toString())
        body.append('responses', JSON.stringify(answers))

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post<{ data: MCQScoreDTO }>(url, body)
            .pipe(
                map(response => MCQMapper.mapMCQScore(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }
}
