import React from 'react';
import '../../../assets/css/theme.css'

const OnlyDesktop = () => {
    return (
        <div className={'onlyDesktop'}>
            <div className="splash">
                <div className="content">
                    <span>Téléchargez l&apos;application mobile<br/> <b>Sonepar&apos;s Adventures</b>  <br/> depuis votre store préféré</span>
                    <div className={'store-content gap-5 mt-3'}>
                        <a href={'https://itunes.apple.com/app/id1562717405'}>
                            <img className={'appstore'} alt={'appstore'} src={require('../../../assets/img/stores.png')}/>
                        </a>
                        <a href={'https://play.google.com/store/apps/details?id=com.redsadventure'}>
                            <img className={'googleStore'} alt={'appstore'} src={require('../../../assets/img/stores.png')}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnlyDesktop;
