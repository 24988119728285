import { Score } from '../domain/entities/score';

export const LOAD_SCORE = 'LOAD_SCORE';
export const LOAD_SCORE_FAILED = 'LOAD_SCORE_FAILED';
export const LOAD_SCORE_SUCCEEDED = 'LOAD_SCORE_SUCCEEDED';

export interface LoadScoreAction {
    type: typeof LOAD_SCORE;
}

export interface LoadScoreFailedAction {
    type: typeof LOAD_SCORE_FAILED;
    payload: string;
}

export interface LoadScoreSucceededAction {
    type: typeof LOAD_SCORE_SUCCEEDED;
    payload: Score;
}

export type LoadScoreActionTypes = LoadScoreAction | LoadScoreFailedAction | LoadScoreSucceededAction
