import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Login } from "../../../authentication/adapters/primaries";
import { Dashboard } from "../../../dashboard/adapters/primaries/dashboard";
import { AdventureDetails } from "../../../adventure/adapters/primaries";
import ThemeLayout from "./components/theme.layout";
import { ProfilePage } from "../../../profile/adapters/primaries";
import { TeamMatePage } from "../../../teamMate/adapters/primaries";
import SpeedQuizQuestionsContainer from "../../../speedQuiz/adapters/primaries/questions/questions";
import SpeedQuizResultContainer from "../../../speedQuiz/adapters/primaries/result/result";
import OnboardingContainer from "../../../authentication/adapters/primaries/onboarding/onboarding.container";
import { Missions } from "../../../dashboard/adapters/primaries/missions";
import { McqPage } from "../../../MCQ/adapters/primaries";
import ScoreContainer from "../../../score/adapters/primaries/score.container";
import { AppEventBuilder } from "../../../shared/domain/entities/logging/appEvent.builder";
import { BeaconsType } from "../../../shared/domain/entities/logging/beacons.type";
import { logEvent } from "../../../shared/usecases/logging/logging.action";
import { useDispatch } from "react-redux";

interface Props {
    isAuthenticated: boolean | undefined;
}

export const RootNavigationContainer = (props: Props) => {
    return (
        props.isAuthenticated === undefined ? <></> : props.isAuthenticated ? dashboardNavigation() : loginNavigation()
    )
}

function dashboardNavigation(): JSX.Element {
    const dispatch = useDispatch()
    const event = (new AppEventBuilder())
        .withBeacon(BeaconsType.EVENT_SESSION_WEB_START)
        .build()
    dispatch(logEvent(event))

    return (
        <BrowserRouter>
            <ThemeLayout>
                <Routes>
                    <Route index element={<Dashboard/>} path="/"/>

                    <Route element={<Dashboard/>} path="/dashboard"/>

                    <Route element={<Missions/>} path="/missions"/>

                    <Route element={<ProfilePage/>} path="/profile"/>

                    <Route element={<AdventureDetails/>} path="adventure/:adventureId"/>

                    <Route element={<McqPage/>} path="adventure/:adventureId/mcq/:mcqId"/>

                    <Route element={<TeamMatePage/>} path="team/:email"/>

                    <Route element={<ScoreContainer/>} path="score"/>

                    <Route element={<SpeedQuizQuestionsContainer/>} path="/speed-quiz/:id"/>

                    <Route element={<SpeedQuizResultContainer/>} path="/speed-quiz/:id/result"/>

                    <Route path="/login/*" element={<Navigate to="/dashboard"/>}/>

                </Routes>
            </ThemeLayout>
        </BrowserRouter>
    );
}

function loginNavigation(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/login/onboarding" element={<OnboardingContainer/>}/>

                <Route path="/*" element={<Login/>}/>

            </Routes>
        </BrowserRouter>
    )
}
