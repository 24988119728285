export const SpeedQuizI18n = {
    onlyOneAnswer : '\n\n(1 seule réponse possible)',
    manyAnswers   : '\n\n(Plusieurs réponses possibles)',
    next          : 'Continuer',
    finish        : 'Terminer',
    bonusPoints   : 'Points bonus',
    earnedPoints  : 'Points gagnés',
    you           : 'Vous',
    participate   : 'Participer',
    consult       : 'Consulter',
    speedQuiz     : 'Speed Quiz',
    ok            : 'Ok',
    calculateScore: 'Calcul du score',
    ruleOne       : 'Si le joueur remporte le duel, il gagne 10 points.',
    ruleTwo       : 'Si le joueur répond dans les 24 heures, il gagne 1 point bonus pour chaque manche.',
    ruleThree     : 'Si le joueur répond correctement aux 3 questions d\'une manche, il gagne 1 point bonus.',
    ruleFour      : 'Si le joueur répond sans faute à toutes les questions des 3 manches, il gagne 4 points bonus.',
    show          : 'Voir',
    getStarted    : 'Préparez vous pour la '
}
