import { MessageService } from '../domain/gateway/message.service';
import { ApiMessageService } from '../adapters/secondaries/real/apiMessage.service';
import { MessageRepository } from "../domain/gateway/message.repository";
import { LocalStorageMessageRepository } from "../adapters/secondaries/real/localStorageMessage.repository";

export class MessageDependenciesFactory {
    static getMessageService(): MessageService {
        return new ApiMessageService()
    }

    static getMessageRepository(): MessageRepository {
        return new LocalStorageMessageRepository()
    }
}
