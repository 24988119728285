import { combineReducers } from 'redux';
import { ProfileState } from "./state";
import { retrieveProfileReducer } from "../usecases/retrieveProfile/retrieveProfile.reducer";
import { setStoreProfileReducer } from "../usecases/setStoreProfile/setReduxProfile.reducer";
import { retrieveBadgesReducer } from "../usecases/retrieveBadges/loadBadges.reducer";
import { updateAvatarReducer } from "../usecases/updateAvatar/updateAvatar.reducer";


export const profileRootReducer = combineReducers<ProfileState>({
    retrieveProfile: retrieveProfileReducer,
    storeProfile   : setStoreProfileReducer,
    retrieveBadges : retrieveBadgesReducer,
    updateAvatar   : updateAvatarReducer
})
