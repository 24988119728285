import {
    SEND_SPEED_QUIZ_ANSWERS,
    SEND_SPEED_QUIZ_ANSWERS_FAILED,
    SEND_SPEED_QUIZ_ANSWERS_SUCCEEDED,
    SendSpeedQuizAnswersActionTypes
} from "./actionTypes";
import { HandleRequestState } from "../../../redux-configuration/AppState";


const initialState: HandleRequestState = {
    loading: false,
    success: undefined,
    error  : undefined
}

export const sendSpeedQuizAnswersReducer = (state = initialState, action: SendSpeedQuizAnswersActionTypes): HandleRequestState => {
    switch (action.type) {
        case SEND_SPEED_QUIZ_ANSWERS:
            return {
                loading: true,
                success: undefined,
                error  : undefined
            }
        case SEND_SPEED_QUIZ_ANSWERS_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        case SEND_SPEED_QUIZ_ANSWERS_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        default:
            return state
    }
}
