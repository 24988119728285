import { AvatarType } from "../../../profile/domain/entities/types";

export class TeamMate {
    constructor(
        protected _email: string,
        protected _firstName: string,
        protected _lastName: string,
        protected _agency: string,
        protected _agencyCode: string,
        protected _region: string,
        protected _score: number,
        protected _avatar: AvatarType,
        protected _badges: string[]
    ) {
    }

    get email(): string {
        return this._email;
    }

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get agency(): string {
        return this._agency;
    }

    get agencyCode():  string {
        return this._agencyCode;
    }

    get region(): string {
        return this._region;
    }

    get score(): number {
        return this._score;
    }

    get avatar(): AvatarType {
        return this._avatar;
    }

    get badges(): string[] {
        return this._badges;
    }

}
