import React, { ReactElement } from 'react';
import { Profile } from "../../../../profile/domain/entities/profile";
import { Adventure } from "../../../domain/entities/adventure";
import { AdventureScore } from "../../../domain/entities/score/adventureScore";
import { AdventureI18n } from "../../../config/i18n";
import { RegionBackground } from "../../../../shared/adapters/primaries/region.background";
import { findAvatar } from "../../../../profile/config/findAvatar";
import { AdventureType } from "../../../domain/types";
import { ProfileType } from "../../../../profile/domain/entities/types";
import ItinerantStandardDashboard from "../standard/dashboard/itinerantStandard.dashboard";
import AgenceStandardDashboard from "../standard/dashboard/agenceStandard.dashboard";
import SupportStandardDashboard from "../standard/dashboard/supportStandard.dashboard";
import InterAgenceAdventureDashboard from "../interAgence/dashboard/interAgence.adventure.dashboard";
import { AdventureAgencyScore } from "../../../domain/entities/score/adventureAgencyScore";

interface Props {
    profile: Profile | undefined
    adventure: Adventure
    adventureScore: AdventureScore | undefined
}

const DashboardSection = (props: Props) => {
    return (
        <div className={'data-section'}>
            <h2>{AdventureI18n.yourResult}</h2>

            <div className={"data-content"}>
                <div className={'box-100 profile'}>
                    <RegionBackground styles={{width: 130, height: 130, padding: 5, borderRadius: 70}}
                                      region={props.profile?.region}>
                        <img width={120} height={120} src={findAvatar(props.profile?.avatar).image}
                             alt={'avatar'}/>
                    </RegionBackground>

                    <div>
                        <h3>{props.profile?.firstName} {props.profile?.lastName}</h3>
                        <h4>{props.profile?.agency}</h4>
                        {
                            getAgencyScore()?.category &&
                            <h6 style={{color: 'var(--silver-chalice)'}}>{AdventureI18n.category(getAgencyScore()?.category)}</h6>
                        }
                        <div className={'d-flex flex-row gap-2'}>
                            <RegionBackground styles={{width: 20, height: 20, borderRadius: 15}}
                                              region={props.profile?.region}/>
                            <span>{props.profile?.region}</span>
                        </div>

                    </div>
                </div>
                {renderDashboardContent(props.adventure.type, props.profile?.type)}
            </div>
        </div>
    );

    function getAgencyScore(): AdventureAgencyScore {
        const agencyCode = props.profile?.agencyCode
        return props.adventureScore?.agenciesScore.find(item => item.code === agencyCode) as AdventureAgencyScore
    }


    function renderDashboardContent(missionType: AdventureType, profileType?: ProfileType): ReactElement {
        if (missionType === 'STANDARD') {
            if (profileType === 'ITINERANT') {
                return <ItinerantStandardDashboard profile={props.profile}
                                                   adventureScore={props.adventureScore}/>
            } else if (profileType === 'AGENCE') {
                return <AgenceStandardDashboard profile={props.profile}
                                                adventureScore={props.adventureScore}/>
            } else {
                return <SupportStandardDashboard profile={props.profile}
                                                 adventureScore={props.adventureScore}/>
            }
        } else {
            return <InterAgenceAdventureDashboard adventureScore={props.adventureScore}
                                                  profile={props.profile}
            />
        }
    }

};

export default DashboardSection;
