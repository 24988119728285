import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../redux-configuration/AppState";
import {
    requestPasswordErrorSelector,
    requestPasswordLoadingSelector,
    requestPasswordSuccessSelector
} from "../../usecases/requestPassword/selector";
import {
    loginUserErrorSelector,
    loginUserLoadingSelector,
    loginUserSuccessSelector
} from "../../usecases/login/selector";
import { RequestPasswordAction } from "../../usecases/requestPassword/actionTypes";
import { requestPassword } from "../../usecases/requestPassword/actions";
import { LoginUserAction } from "../../usecases/login/actionTypes";
import { loginUser } from "../../usecases/login/actions";
import LoginContainer from "./login.container";

interface StateToPropsType {
    loading: boolean;
    passwordSuccess: boolean | undefined;
    passwordError: string | undefined;
    loginSuccess: boolean | undefined;
    loginError: string | undefined;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading        : requestPasswordLoadingSelector(state) || loginUserLoadingSelector(state),
    passwordSuccess: requestPasswordSuccessSelector(state),
    passwordError  : requestPasswordErrorSelector(state),
    loginSuccess   : loginUserSuccessSelector(state),
    loginError     : loginUserErrorSelector(state)
})

interface DispatchToPropsType {
    requestPassword: (email: string) => void;
    login: (email: string, code: string) => void;
}


const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    requestPassword: (email: string): RequestPasswordAction => dispatch(requestPassword(email)),
    login          : (email: string, code: string): LoginUserAction => dispatch(loginUser(email, code))
})


export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
