import { Message } from '../../domain/entities/message';

export const LOAD_MESSAGES = 'LOAD_MESSAGES'
export const LOAD_MESSAGES_FAILED = 'LOAD_MESSAGES_FAILED'
export const LOAD_MESSAGES_SUCCEEDED = 'LOAD_MESSAGES_SUCCEEDED'

export interface LoadMessagesAction {
    type: typeof LOAD_MESSAGES;
}

export interface LoadMessagesFailedAction {
    type: typeof LOAD_MESSAGES_FAILED;
    payload: string;
}

export interface LoadMessagesSucceededAction {
    type: typeof LOAD_MESSAGES_SUCCEEDED;
    payload: Message[];
}

export type LoadMessagesActionTypes = LoadMessagesAction | LoadMessagesFailedAction | LoadMessagesSucceededAction
