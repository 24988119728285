import { BeaconsType } from "./beacons.type";

export class AppEvent {
    constructor(
        private _beacon: BeaconsType,
        private _value: string = '',
        private _references: Reference[],
        private _isSecured: boolean = true
    ) {
    }

    get beacon(): BeaconsType {
        return this._beacon;
    }

    get value(): string {
        return this._value;
    }

    get references(): Reference[] {
        return this._references;
    }

    get isSecured(): boolean {
        return this._isSecured;
    }
}

export interface Reference {
    key: string;
    value: string;
}
