import { Observable, of } from 'rxjs'
import { catchError, concatMap, switchMap } from 'rxjs/operators'
import { Epic, ofType, StateObservable } from 'redux-observable';
import { AdventureService } from '../../domain/gateway/adventure.service';
import { Adventure } from '../../domain/entities/adventure';
import { LOAD_ADVENTURE_DETAILS, LoadAdventureDetailsAction } from "./actionTypes";
import { loadAdventureDetailsFailed, loadAdventureDetailsSucceeded } from "./actions";
import { LoadAdventureDetailsState } from "../../config/state";
import { loadMCQ } from "../../../MCQ/usecases/load/actions";
import { AdventureRepository } from "../../domain/gateway/adventure.repository";
import { AdventureBuilder } from "../../domain/entities/adventure.builder";
import { loadAdventureScore } from "../score/actions";


export const loadAdventureDetailsEpic: Epic = (action$: Observable<LoadAdventureDetailsAction>,
                                               store: StateObservable<LoadAdventureDetailsState>,
                                               {adventureService, adventureRepository}:
                                                   {
                                                       adventureService: AdventureService;
                                                       adventureRepository: AdventureRepository
                                                   }) =>
    action$.pipe(
        ofType(LOAD_ADVENTURE_DETAILS),
        switchMap(action => adventureService.loadAdventureList()
            .pipe(
                concatMap((adventureList: Adventure[]) => {
                    const adventure: Adventure | undefined = adventureList.find(item => item.id === action.payload)
                    return adventureRepository.getSavedAdventuresIds()
                        .pipe(
                            concatMap((adventuresIds: string[]) => [
                                loadAdventureScore(action.payload),
                                loadMCQ(action.payload),
                                loadAdventureDetailsSucceeded(updateAdventureStatus(adventure, adventuresIds)),
                            ]),
                            catchError((error: string) => of(loadAdventureDetailsFailed(error)))
                        )
                }),
                catchError((error: string) => of(loadAdventureDetailsFailed(error)))
            )
        )
    )


const updateAdventureStatus = (adventure: Adventure | undefined, adventureIds: string[]): Adventure => {
    if (adventure?.status === 'new' && adventureIds.find(id => id === adventure.id)) {
        return new AdventureBuilder()
            .withId(adventure.id)
            .withLabel(adventure.label)
            .withDescription(adventure.description)
            .withPicture(adventure.picture)
            .withEarns(adventure.earns)
            .withEndDate(adventure.endDate)
            .withStartDate(adventure.starDate)
            .withStatus('inprogress')
            .withType(adventure.type)
            .build()
    }
    return adventure as Adventure
}
