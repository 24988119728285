import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { ProfileService } from "../../../domain/gateway/profile.service";
import { Profile } from "../../../domain/entities/profile";
import { SecuredHttpClient } from "../../../../shared/adapters/secondaries/securedHttpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/config/dependencies.factory";
import { ProfileDto } from "./dto/profileDto";
import { ProfileMapper } from "./mapper/profile.mapper";
import { Badge } from "../../../domain/entities/badge/badge";
import { AvatarType, BadgeIdType } from "../../../domain/entities/types";

export class ApiProfileService implements ProfileService {
    retrieveRemoteProfile(): Observable<Profile> {
        const url = `${process.env.REACT_APP_API_URL}/v1/profile`
        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post<{ data: ProfileDto }>(url)
            .pipe(
                map((rs) => ProfileMapper.mapProfileDtoToProfile(rs.data)),
                catchError(err => {
                    if (err.status) {
                        return throwError(() => err.status)
                    }
                    return throwError(() => err)
                })
            )
    }

    retrieveBadges(): Observable<Badge[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/badge`
        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: { badges: BadgeIdType[] } }>(url)
            .pipe(
                map((rs) => ProfileMapper.mapBadges(rs.data.badges)),
                catchError((err) => {
                    if (err.status) {
                        return throwError(() => err.status)
                    }
                    return throwError(() => err)
                })
            )
    }

    updateAvatar(avatarId: AvatarType): Observable<void> {
        const url = `${process.env.REACT_APP_API_URL}/v1/profile/avatar`

        const body = new FormData();
        body.append('avatarName', avatarId)

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }
}
