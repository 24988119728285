import { AdventureService } from '../domain/gateway/adventure.service';
import { ApiAdventureService } from '../adapters/secondaries/real/apiAdventure.service';
import { AdventureRepository } from "../domain/gateway/adventure.repository";
import { SqlAdventureRepository } from "../adapters/secondaries/real/sqlAdventure.repository";

export class AdventureDependenciesFactory {

    static getAdventureService(): AdventureService {
        return new ApiAdventureService()
    }

    static getAdventureRepository(): AdventureRepository {
        return new SqlAdventureRepository()
    }

}
