import { SpeedQuizStatusType } from "../types";
import { SpeedQuizPlayer } from "./player";
import { ApplicationContext } from "../../../shared/config/application.context";

const moment = ApplicationContext.getInstance().momentJs()

export class SpeedQuiz {
    constructor(
        protected _id: string,
        protected _status: SpeedQuizStatusType,
        protected _title: string,
        protected _message: string,
        protected _backgroundImage: string,
        protected _user: SpeedQuizPlayer,
        protected _opponent: SpeedQuizPlayer,
        protected _endDate: Date
    ) {
    }

    get id(): string {
        return this._id;
    }

    get status(): SpeedQuizStatusType {
        return this._status;
    }

    get title(): string {
        return this._title;
    }

    get message(): string {
        return this._message;
    }

    get backgroundImage(): string {
        return this._backgroundImage;
    }

    get user(): SpeedQuizPlayer {
        return this._user;
    }

    get opponent(): SpeedQuizPlayer {
        return this._opponent;
    }

    get endDate(): Date {
        return this._endDate;
    }

    startDate(): Date {
        return moment(this._endDate)
            .subtract(5, 'days')
            .set('hour', 10)
            .set('minute', 0).toDate()
    }
}
