import { SpeedQuiz } from '../../domain/entity/SpeedQuiz';

export const LOAD_SPEED_QUIZ_LIST = 'LOAD_SPEED_QUIZ_LIST'
export const LOAD_SPEED_QUIZ_LIST_FAILED = 'LOAD_SPEED_QUIZ_LIST_FAILED'
export const LOAD_SPEED_QUIZ_LIST_SUCCEEDED = 'LOAD_SPEED_QUIZ_LIST_SUCCEEDED'

export interface LoadSpeedQuizListAction {
    type: typeof LOAD_SPEED_QUIZ_LIST;
}

export interface LoadSpeedQuizListFailedAction {
    type: typeof LOAD_SPEED_QUIZ_LIST_FAILED;
    payload: string;
}

export interface LoadSpeedQuizListSucceededAction {
    type: typeof LOAD_SPEED_QUIZ_LIST_SUCCEEDED;
    payload: SpeedQuiz[];
}

export type LoadSpeedQuizListActionTypes =
    LoadSpeedQuizListAction
    | LoadSpeedQuizListFailedAction
    | LoadSpeedQuizListSucceededAction
