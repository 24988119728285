import React, { useState } from "react";
import '../profile.modal.css'
import { avatars, findAvatar } from "../../../config/findAvatar";
import { AvatarType } from "../../../domain/entities/types";
import { Avatar } from "../../../domain/entities/avatar";
import { ProfileI18n } from "../../../config/i18n";

interface Props {
    onClose: () => void;
    currentAvatar: AvatarType;
    onAvatarChosen: (avatarId: AvatarType) => void
}

export function UpdateAvatarModal(props: Props) {
    const profileScoreItemIndex: Array<HTMLDivElement | null> = []

    const [selectedAvatar, setSelectedAvatar] = useState<Avatar | undefined>(undefined)

    const className = !selectedAvatar ? ' disabled' : ''
    const currentAvatar: Avatar = selectedAvatar ?? findAvatar(props.currentAvatar)
    return (
        <div className={"modal modal-details modal-wrapper"} tabIndex={-1} role="dialog"
             onClick={(): void => props.onClose()}>
            <div className="modal-dialog" role="document">
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <button type="button" className="close" onClick={(): void => props.onClose()}>
                        <span/><span/>
                    </button>

                    <div className="modal-body">
                        <h3>{ProfileI18n.avatarChoice}</h3>

                        <div className={"d-flex align-items-center p-3 mt-3"}>
                            <img className={'img-fluid'}
                                 width={180}
                                 height={180}
                                 src={currentAvatar.image}
                                 alt={currentAvatar.title}/>

                            <div className={'mx-4'}>
                                <h4>{currentAvatar.title}</h4>
                                <span>{currentAvatar.description}</span>
                            </div>
                        </div>

                        <div className={"d-flex flex-wrap gap-4 avatars-list justify-content-center"}>
                            {
                                avatars.map((item, index) => {
                                    return (
                                        <div key={item.id} className={"avatar-img"}
                                             onClick={() => {
                                                 profileScoreItemIndex.map((item) => {
                                                     item?.setAttribute('class', 'img-fluid')
                                                 })
                                                 profileScoreItemIndex[index]?.setAttribute('class', profileScoreItemIndex[index]?.className + ' selected')
                                                 setSelectedAvatar(avatars[index])
                                             }}>
                                            <img ref={(ref) => {
                                                profileScoreItemIndex[index] = ref
                                            }}
                                                 key={item.id}
                                                 className={'img-fluid'}
                                                 width={110}
                                                 height={110}
                                                 src={item.image}
                                                 alt={item.title}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button disabled={selectedAvatar === undefined}
                                className={'large-blue-button' + className}
                                onClick={() => {
                                    if (selectedAvatar) {
                                        props.onAvatarChosen(selectedAvatar.id)
                                    }
                                }}>
                            {ProfileI18n.chooseAvatar}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
