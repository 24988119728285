import { from, Observable } from 'rxjs';
import { AuthenticationRepository } from '../../../domain/gateway/authentication.repository';

export class LocalStorageAuthenticationRepository implements AuthenticationRepository {

    saveAuthenticationToken(token: string): Observable<void> {
        const saveTokenPromise = new Promise<void>((resolve) => {
            localStorage.setItem('token', token)
            resolve(void 0)
        })
        return from(saveTokenPromise)
    }

    getAuthenticationToken(): Observable<string> {
        const getTokenPromise = new Promise<string>((resolve, reject) => {
            const token = localStorage.getItem('token')
            if (token)
                resolve(token)
            else
                reject()
        })
        return from(getTokenPromise)
    }

    removeAuthenticationToken(): Observable<void> {
        const removeTokenPromise = new Promise<void>((resolve) => {
            localStorage.removeItem('token')
            localStorage.removeItem('adventures')
            resolve()
        })
        return from(removeTokenPromise)
    }
}
