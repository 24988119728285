import { SpeedQuizAnswer } from "../entity/answer";
import { SpeedQuizQuestion } from "../entity/question";

export class SpeedQuizQuestionBuilder {
    protected _id!: string
    protected _text!: string
    protected _time!: number
    protected _image!: string
    protected _numberOfCorrectAnswers!: number
    protected _answers!: SpeedQuizAnswer[]

    withId(value: string): SpeedQuizQuestionBuilder {
        this._id = value
        return this
    }

    withText(value: string): SpeedQuizQuestionBuilder {
        this._text = value
        return this
    }

    withTime(value: number): SpeedQuizQuestionBuilder {
        this._time = value
        return this
    }

    withImage(value: string): SpeedQuizQuestionBuilder {
        this._image = value
        return this
    }

    withNumberOfCorrectAnswers(value: number): SpeedQuizQuestionBuilder {
        this._numberOfCorrectAnswers = value
        return this
    }

    withAnswers(value: SpeedQuizAnswer[]): SpeedQuizQuestionBuilder {
        this._answers = value
        return this
    }

    build(): SpeedQuizQuestion {
        return new SpeedQuizQuestion(
            this._id,
            this._text,
            this._time,
            this._image,
            this._numberOfCorrectAnswers,
            this._answers
        )
    }
}
