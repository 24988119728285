import { LoadMessagesState } from '../../config/state';
import { LOAD_MESSAGES, LOAD_MESSAGES_FAILED, LOAD_MESSAGES_SUCCEEDED, LoadMessagesActionTypes } from './actionTypes';

const initialState: LoadMessagesState = {
    loading : false,
    messages: undefined,
    error   : undefined
}
export const loadMessagesReducer = (state = initialState, action: LoadMessagesActionTypes): LoadMessagesState => {
    switch (action.type) {
        case LOAD_MESSAGES:
            return {
                loading : true,
                messages: undefined,
                error   : undefined
            }
        case LOAD_MESSAGES_FAILED:
            return {
                loading : false,
                messages: undefined,
                error   : action.payload
            }
        case LOAD_MESSAGES_SUCCEEDED:
            return {
                loading : false,
                messages: action.payload,
                error   : undefined
            }
        default:
            return state
    }
}
