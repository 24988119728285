import { Observable, throwError } from 'rxjs';
import { MessageService } from '../../../domain/gateway/message.service';
import { Message } from '../../../domain/entities/message';
import { catchError, map } from 'rxjs/operators'
import { MessageMapper } from './mapper/message.mapper';
import { SecuredHttpClient } from "../../../../shared/adapters/secondaries/securedHttpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/config/dependencies.factory";
import { MessageDto } from "./dto/message.dto";

export class ApiMessageService implements MessageService {
    loadMessages(): Observable<Message[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/news'

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: MessageDto[] }>(url)
            .pipe(
                map(response => MessageMapper.mapMessageDtoToMessage(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    catchMessage(messageId: string): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/news/' + messageId + '/catch'

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }
}
