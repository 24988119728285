import { combineReducers } from "redux";
import { loadMessagesReducer } from "../usecases/load/reducer";
import { saveMessageReducer } from "../usecases/save/reducer";
import { catchMessageReducer } from "../usecases/catch/reducer";

export const messageRootReducer = combineReducers({
    load : loadMessagesReducer,
    save : saveMessageReducer,
    catch: catchMessageReducer
})
