import {
    SUBSCRIBE_TO_ADVENTURE,
    SUBSCRIBE_TO_ADVENTURE_FAILED,
    SUBSCRIBE_TO_ADVENTURE_SUCCEEDED,
    SubscribeToAdventureActionsTypes
} from './subscribeToAdventure.types';
import { HandleRequestState } from "../../../redux-configuration/AppState";

const initialState: HandleRequestState = {
    loading: false,
    error  : undefined,
    success: undefined
}
export const subscribeToAdventureReducer = (state = initialState, action: SubscribeToAdventureActionsTypes): HandleRequestState => {
    switch (action.type) {
        case SUBSCRIBE_TO_ADVENTURE:
            return {
                loading: true,
                error  : undefined,
                success: undefined
            }
        case SUBSCRIBE_TO_ADVENTURE_FAILED:
            return {
                loading: false,
                error  : action.payload,
                success: undefined
            }
        case SUBSCRIBE_TO_ADVENTURE_SUCCEEDED:
            return {
                loading: false,
                error  : undefined,
                success: true
            }
        default:
            return state;
    }
}
