import { SpeedQuizStatusType } from "../types";
import { SpeedQuiz } from "../entity/SpeedQuiz";
import { SpeedQuizPlayer } from "../entity/player";

export class SpeedQuizBuilder {
    protected _id!: string
    protected _status!: SpeedQuizStatusType
    protected _title!: string
    protected _message!: string
    protected _backgroundImage!: string
    protected _user!: SpeedQuizPlayer
    protected _opponent!: SpeedQuizPlayer
    protected _endDate!: Date

    withId(value: string): SpeedQuizBuilder {
        this._id = value
        return this
    }

    withStatus(value: SpeedQuizStatusType): SpeedQuizBuilder {
        this._status = value
        return this
    }

    withTitle(value: string): SpeedQuizBuilder {
        this._title = value
        return this
    }

    withMessage(value: string): SpeedQuizBuilder {
        this._message = value
        return this
    }

    withBackgroundImage(value: string): SpeedQuizBuilder {
        this._backgroundImage = value
        return this
    }

    withUser(value: SpeedQuizPlayer): SpeedQuizBuilder {
        this._user = value
        return this
    }

    withOpponent(value: SpeedQuizPlayer): SpeedQuizBuilder {
        this._opponent = value
        return this
    }

    withEndDate(value: Date): SpeedQuizBuilder {
        this._endDate = value
        return this
    }

    build(): SpeedQuiz {
        return new SpeedQuiz(
            this._id,
            this._status,
            this._title,
            this._message,
            this._backgroundImage,
            this._user,
            this._opponent,
            this._endDate
        )
    }
}
