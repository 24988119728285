import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux-configuration/AppState";
import { DashboardI18n } from "../../../../config/i18n";
import ScoreIcon from "../../../../../shared/adapters/primaries/icons/score.icon";
import { findAvatar } from "../../../../../profile/config/findAvatar";
import { useNavigate } from "react-router-dom";

export default function DashboardHeader(props: { title: string }) {
    const profile = useSelector((state: AppState) => state.profile.storeProfile.profile)
    const router = useNavigate()

    return (
        <div className={'dashboard-header d-flex align-items-center justify-content-between'}>
            <h1>{props.title}</h1>

            <div className={'header-score d-flex align-items-center'} onClick={() => router('/profile')}>
                <p className={'score'}>
                    {profile?.score}
                </p>

                <ScoreIcon width={20}/>

                <p className={'name'}>
                    {DashboardI18n.welcome} {profile?.firstName}
                </p>

                <img style={{width: 32, height: 32}} src={findAvatar(profile?.avatar).image} alt={'avatar'}/>
            </div>
        </div>
    )
}
