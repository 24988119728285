import { Message } from '../../domain/entities/message';
import {
    LOAD_MESSAGES,
    LOAD_MESSAGES_FAILED,
    LOAD_MESSAGES_SUCCEEDED,
    LoadMessagesAction,
    LoadMessagesFailedAction,
    LoadMessagesSucceededAction
} from './actionTypes';


export const loadMessages = (): LoadMessagesAction => ({
    type: LOAD_MESSAGES
})

export const loadMessagesFailed = (error: string): LoadMessagesFailedAction => ({
    type   : LOAD_MESSAGES_FAILED,
    payload: error
})

export const loadMessagesSucceeded = (messages: Message[]): LoadMessagesSucceededAction => ({
    type   : LOAD_MESSAGES_SUCCEEDED,
    payload: messages
})
