import React from "react";
import { SpeedQuizQuestion } from "../../../../domain/entity/question";
import { SpeedQuizI18n } from "../../../../config/i18n";


interface Props {
    question: SpeedQuizQuestion
}

export default function SpeedQuizQuestionText(props: Props) {

    return (
        <div className={'question-item'}>
            {props.question.image && <img className={'img-fluid my-4'} src={props.question.image} alt={'Question'}/>}

            <h2>{props.question.text}</h2>

            <p>{props.question.numberOfCorrectAnswers > 1 ? SpeedQuizI18n.manyAnswers : SpeedQuizI18n.onlyOneAnswer}</p>
        </div>
    )
}
