import React from 'react';

export const BackIcon = (props: { className?: string; onClick: () => void }) => {
    return (
        <div className={props.className} onClick={props.onClick}>
            <svg width="28" height="35" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.2938 8.21601H7.77809L12.628 3.51718C13.1629 2.9967 13.3717 2.23808 13.176 1.52709C12.9802 0.816104 12.4095 0.260759 11.6788 0.0702496C10.9482 -0.120259 10.1686 0.0830112 9.6337 0.603492L1.19912 8.81106C0.381545 9.61138 0.381545 10.9039 1.19912 11.7042L9.6337 19.9118C10.4547 20.7164 11.7906 20.721 12.6174 19.9221C13.4443 19.1231 13.449 17.8232 12.628 17.0186L7.77809 12.3198H14.2938C19.5344 12.3198 23.7827 16.4538 23.7827 21.5533C23.7827 26.6529 19.5344 30.7868 14.2938 30.7868C13.1292 30.7868 12.1852 31.7055 12.1852 32.8387C12.1852 33.972 13.1292 34.8906 14.2938 34.8906C21.8635 34.8906 28 28.9193 28 21.5533C28 14.1873 21.8635 8.21601 14.2938 8.21601Z"
                    fill="white"/>
            </svg>
        </div>
    );
};
