import { EventLoggedAction, LogEventAction } from "./analytics.types";
import { AppEvent } from "../../domain/entities/logging/AppEvent";

export const LOG_EVENT = 'LOG_EVENT'
export const EVENT_LOGGED = 'EVENT_LOGGED'

export const logEvent = (appEvent: AppEvent): LogEventAction => ({
    type   : LOG_EVENT,
    payload: appEvent
})

export const eventLogged = (): EventLoggedAction => ({
    type: EVENT_LOGGED
})
