import { SpeedQuizService } from '../../domain/gateway/speedQuiz.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { LOAD_SPEED_QUIZ_RESULT, LoadSpeedQuizResultAction } from './actionTypes';
import { SpeedQuizResultState } from "../../config/state";
import { SpeedQuizResult } from "../../domain/entity/result";
import { loadSpeedQuizResultFailed, loadSpeedQuizResultSucceeded } from "./actions";


export const loadSpeedQuizResultEpic: Epic = (action$: Observable<LoadSpeedQuizResultAction>,
                                              store: StateObservable<SpeedQuizResultState>,
                                              { speedQuizService }: { speedQuizService: SpeedQuizService }) =>
    action$.pipe(
        ofType(LOAD_SPEED_QUIZ_RESULT),
        switchMap(action => speedQuizService.loadSpeedQuizResult(action.payload)
            .pipe(
                map((result: SpeedQuizResult) => loadSpeedQuizResultSucceeded(result)),
                catchError(err => of(loadSpeedQuizResultFailed(err)))
            )
        )
    );
