export class SpeedQuizAnswer {
    constructor(
        protected _id: string,
        protected _text: string,
        protected _isCorrect: boolean,
        protected _isOpponentAnswer: boolean
    ) {}

    get id(): string {
        return this._id;
    }

    get text(): string {
        return this._text;
    }

    get isCorrect(): boolean {
        return this._isCorrect;
    }

    get isOpponentAnswer(): boolean {
        return this._isOpponentAnswer;
    }
}
