import { Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs'
import { catchError, concatMap, switchMap } from 'rxjs/operators'
import { UPDATE_AVATAR, UpdateAvatarAction } from "./updateAvatar.types";
import { AppState } from "../../../redux-configuration/AppState";
import { ProfileService } from "../../domain/gateway/profile.service";
import { ProfileBuilder } from "../../domain/entities/profile.builder";
import { setStoreProfile } from "../setStoreProfile/setReduxProfile.actions";
import { retrieveProfile } from "../retrieveProfile/retrieveProfile.actions";
import { retrieveBadges } from "../retrieveBadges/loadBadges.actions";
import { updateAvatarFailed, updateAvatarSucceeded } from "./updateAvatar.actions";


export const updateAvatarEpic: Epic = (action$: Observable<UpdateAvatarAction>,
                                       store: StateObservable<AppState>,
                                       {profileService}: { profileService: ProfileService }) =>
    action$.pipe(
        ofType(UPDATE_AVATAR),
        switchMap(action => profileService.updateAvatar(action.payload)
            .pipe(
                concatMap(() => {
                    const currentProfile = store.value.profile.storeProfile.profile
                    const newProfile = (new ProfileBuilder())
                        .fromProfile(currentProfile)
                        .withAvatar(action.payload)
                        .build()
                    return [
                        setStoreProfile(newProfile),
                        retrieveProfile(),
                        retrieveBadges(),
                        updateAvatarSucceeded()
                    ]
                }),
                catchError(error => of(updateAvatarFailed(error)))
            )
        )
    )
