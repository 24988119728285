import { AuthenticationService } from "../domain/gateway/authentication.service";
import { ApiAuthenticationService } from "../adapters/secondaries/real/apiAuthentication.service";
import { AuthenticationRepository } from "../domain/gateway/authentication.repository";
import {
    LocalStorageAuthenticationRepository
} from "../adapters/secondaries/real/localStorageAuthentication.repository";

export class AuthenticationDependenciesFactory {
    static getAuthenticationService(): AuthenticationService {
        return new ApiAuthenticationService()

    }

    static getAuthenticationRepository(): AuthenticationRepository {
        return new LocalStorageAuthenticationRepository()
    }
}
