import { SpeedQuizPlayer } from "../entity/player";
import { AvatarType } from "../../../profile/domain/entities/types";

export class SpeedQuizPlayerBuilder {
    protected _name!: string
    protected _score!: string
    protected _email!: string
    protected _image!: AvatarType
    protected _category!: string

    withName(value: string): SpeedQuizPlayerBuilder {
        this._name = value
        return this
    }

    withScore(value: string): SpeedQuizPlayerBuilder {
        this._score = value
        return this
    }

    withEmail(value: string): SpeedQuizPlayerBuilder {
        this._email = value
        return this
    }

    withImage(value: AvatarType): SpeedQuizPlayerBuilder {
        this._image = value
        return this
    }

    withCategory(value: string): SpeedQuizPlayerBuilder {
        this._category = value
        return this
    }

    build(): SpeedQuizPlayer {
        return new SpeedQuizPlayer(
            this._name,
            this._score,
            this._email,
            this._image,
            this._category
        )
    }
}
