import {
    LOAD_SPEED_QUIZ_LIST,
    LOAD_SPEED_QUIZ_LIST_FAILED,
    LOAD_SPEED_QUIZ_LIST_SUCCEEDED,
    LoadSpeedQuizListAction,
    LoadSpeedQuizListFailedAction,
    LoadSpeedQuizListSucceededAction
} from "./actionTypes";
import { SpeedQuiz } from "../../domain/entity/SpeedQuiz";

export const loadSpeedQuizList = (): LoadSpeedQuizListAction => ({
    type: LOAD_SPEED_QUIZ_LIST
})

export const loadSpeedQuizListFailed = (error: string): LoadSpeedQuizListFailedAction => ({
    type   : LOAD_SPEED_QUIZ_LIST_FAILED,
    payload: error
})

export const loadSpeedQuizListSucceeded = (speedQuiz: SpeedQuiz[]): LoadSpeedQuizListSucceededAction => ({
    type   : LOAD_SPEED_QUIZ_LIST_SUCCEEDED,
    payload: speedQuiz
})
