export class SpeedQuizResult {
    constructor(
        protected _message: string,
        protected _totalPoints: number,
        protected _earnedPoints: number,
        protected _bonusPoints: number
    ) {}


    get message(): string {
        return this._message;
    }

    get totalPoints(): number {
        return this._totalPoints;
    }

    get earnedPoints(): number {
        return this._earnedPoints;
    }

    get bonusPoints(): number {
        return this._bonusPoints;
    }
}
