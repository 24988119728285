import {
    AUTHENTICATE_USER,
    AuthenticateUserAction,
    USER_IS_AUTHENTICATED,
    USER_IS_NOT_AUTHENTICATED,
    UserIsAuthenticatedAction,
    UserIsNotAuthenticatedAction
} from "./actionTypes";

export const authenticateUser = (): AuthenticateUserAction => ({
    type: AUTHENTICATE_USER
});

export const userIsAuthenticated = (token: string): UserIsAuthenticatedAction => ({
    type   : USER_IS_AUTHENTICATED,
    payload: token
})

export const userIsNotAuthenticated = (): UserIsNotAuthenticatedAction => ({
    type: USER_IS_NOT_AUTHENTICATED
})
