import { SpeedQuizResultState } from "../../config/state";
import {
    LOAD_SPEED_QUIZ_RESULT,
    LOAD_SPEED_QUIZ_RESULT_FAILED,
    LOAD_SPEED_QUIZ_RESULT_SUCCEEDED,
    LoadSpeedQuizResultActionTypes
} from "./actionTypes";

const initialState: SpeedQuizResultState = {
    loading: false,
    result : undefined,
    error  : undefined
}

export const loadSpeedQuizResultReducer = (state = initialState, action: LoadSpeedQuizResultActionTypes): SpeedQuizResultState => {
    switch (action.type) {
        case LOAD_SPEED_QUIZ_RESULT:
            return {
                loading: true,
                result : undefined,
                error  : undefined
            }
        case LOAD_SPEED_QUIZ_RESULT_FAILED:
            return {
                loading: false,
                result : undefined,
                error  : action.payload
            }
        case LOAD_SPEED_QUIZ_RESULT_SUCCEEDED:
            return {
                result : action.payload,
                loading: false,
                error  : undefined
            }
        default:
            return state
    }
}
