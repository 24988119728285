import { SpeedQuizResult } from "../entity/result";

export class SpeedQuizResultBuilder {
    protected _message!: string
    protected _totalPoints!: number
    protected _earnedPoints!: number
    protected _bonusPoints!: number

    withMessage(value: string): SpeedQuizResultBuilder {
        this._message = value
        return this
    }

    withTotalPoints(value: number): SpeedQuizResultBuilder {
        this._totalPoints = value
        return this
    }

    withEarnedPoints(value: number): SpeedQuizResultBuilder {
        this._earnedPoints = value
        return this
    }

    withBonusPoints(value: number): SpeedQuizResultBuilder {
        this._bonusPoints = value
        return this
    }

    build(): SpeedQuizResult {
        return new SpeedQuizResult(
            this._message,
            this._totalPoints,
            this._earnedPoints,
            this._bonusPoints
        )
    }
}
