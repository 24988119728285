import { HandleRequestState } from "../../../redux-configuration/AppState";
import {
    RETRIEVE_PROFILE,
    RETRIEVE_PROFILE_FAILED,
    RETRIEVE_PROFILE_SUCCEEDED,
    retrieveProfileActionsTypes
} from "./retrieveProfile.types";


const initialState: HandleRequestState = {
    loading: false,
    error    : undefined,
    success  : undefined
}
export const retrieveProfileReducer = (state = initialState, action: retrieveProfileActionsTypes): HandleRequestState => {
    switch (action.type) {
        case RETRIEVE_PROFILE:
            return {
                ...state, loading: true,
            }
        case RETRIEVE_PROFILE_FAILED:
            return {
                ...state, loading: false,
                error              : action.payload,
            }
        case RETRIEVE_PROFILE_SUCCEEDED:
            return {
                ...state, loading: false,
                success            : true
            }
        default:
            return state;
    }
}
