import { AuthenticationRepository } from "../../../authentication/domain/gateway/authentication.repository";
import { from, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import axios from "axios";

export class SecuredHttpClient {
    private authenticationRepository: AuthenticationRepository

    constructor(authenticationRepository: AuthenticationRepository) {
        this.authenticationRepository = authenticationRepository
    }

    get<R>(url: string, headers?: Record<string, string>): Observable<R> {
        return this.authenticationRepository.getAuthenticationToken()
            .pipe(
                switchMap((token: string) => {
                    const promise = new Promise<R>((resolve, reject) => {
                        axios.get<R>(url, {
                            headers: this.getHeaders(token, headers)
                        })
                            .then((rs) => resolve(rs.data))
                            .catch((reason) => reject(reason))
                    })
                    return from(promise)
                })
            )
    }

    post<T>(url: string, body?: object, headers?: Record<string, string>): Observable<T> {
        return this.authenticationRepository.getAuthenticationToken()
            .pipe(
                switchMap((token: string) => {
                    const promise = new Promise<T>((resolve, reject) => {
                        axios.post<T>(url, body, {
                            headers: this.getHeaders(token, headers)
                        })
                            .then((rs) => resolve(rs.data))
                            .catch((reason) => reject(reason))
                    })
                    return from(promise)
                })
            )
    }

    private getHeaders(token: string, headers?: Record<string, string>): Record<string, string> {
        return {
            ...headers,
            Authorization: `${token}`,
            Build        : `${process.env.REACT_APP_BUILD}`
        }
    }
}
