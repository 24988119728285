import { SpeedQuizService } from '../../domain/gateway/speedQuiz.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { SEND_SPEED_QUIZ_ANSWERS, SendSpeedQuizAnswersAction } from "./actionTypes";
import { HandleRequestState } from "../../../redux-configuration/AppState";
import { sendSpeedQuizAnswersFailed, sendSpeedQuizAnswersSucceeded } from "./actions";


export const sendSpeedQuizAnswersEpic: Epic = (action$: Observable<SendSpeedQuizAnswersAction>,
                                               store: StateObservable<HandleRequestState>,
                                               {speedQuizService}: { speedQuizService: SpeedQuizService }) =>
    action$.pipe(
        ofType(SEND_SPEED_QUIZ_ANSWERS),
        switchMap(action =>
            speedQuizService.sendSpeedQuizAnswers(action.payload.speedQuizId, action.payload.roundId, action.payload.answers)
                .pipe(
                    map(() => sendSpeedQuizAnswersSucceeded()),
                    catchError(err => of(sendSpeedQuizAnswersFailed(err)))
                )
        )
    );
