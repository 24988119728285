import React from "react";
import Sidebar from "./sidebar";

export default function ThemeLayout(props: { children: JSX.Element }) {
    return (
        <main>
            <Sidebar></Sidebar>

            <div className={'page-content'}>
                {props.children}
            </div>
        </main>
    );
}
