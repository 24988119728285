import { SpeedQuizResult } from "../../domain/entity/result";

export const LOAD_SPEED_QUIZ_RESULT = 'LOAD_SPEED_QUIZ_RESULT'
export const LOAD_SPEED_QUIZ_RESULT_FAILED = 'LOAD_SPEED_QUIZ_RESULT_FAILED'
export const LOAD_SPEED_QUIZ_RESULT_SUCCEEDED = 'LOAD_SPEED_QUIZ_RESULT_SUCCEEDED'

export interface LoadSpeedQuizResultAction {
    type: typeof LOAD_SPEED_QUIZ_RESULT;
    payload: string;
}

export interface LoadSpeedQuizResultFailedAction {
    type: typeof LOAD_SPEED_QUIZ_RESULT_FAILED;
    payload: string;
}

export interface LoadSpeedQuizResultSucceededAction {
    type: typeof LOAD_SPEED_QUIZ_RESULT_SUCCEEDED;
    payload: SpeedQuizResult;
}

export type LoadSpeedQuizResultActionTypes =
    LoadSpeedQuizResultAction
    | LoadSpeedQuizResultFailedAction
    | LoadSpeedQuizResultSucceededAction
