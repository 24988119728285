import { Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs'
import { catchError, concatMap, map, switchMap } from 'rxjs/operators'
import { AdventureService } from '../../domain/gateway/adventure.service';
import { loadAdventureListFailed, loadAdventureListSucceeded } from './actions';
import { Adventure } from '../../domain/entities/adventure';
import { LOAD_ADVENTURE_LIST, LoadAdventureListAction } from './actionTypes';
import { LoadAdventureListState } from '../../config/state';
import { AdventureBuilder } from "../../domain/entities/adventure.builder";
import { AdventureRepository } from "../../domain/gateway/adventure.repository";

export const loadAdventureListEpic: Epic = (action$: Observable<LoadAdventureListAction>,
                                            store: StateObservable<LoadAdventureListState>,
                                            { adventureService, adventureRepository }:
                                                { adventureService: AdventureService; adventureRepository: AdventureRepository }) =>
    action$.pipe(
        ofType(LOAD_ADVENTURE_LIST),
        switchMap(() => adventureService.loadAdventureList()
            .pipe(
                concatMap((remoteAdventures: Adventure[]) => adventureRepository.getSavedAdventuresIds()
                    .pipe(
                        map((adventuresIds: string[]) =>
                            loadAdventureListSucceeded(updateAdventuresStatus(remoteAdventures, adventuresIds))
                        ),
                        catchError((error: string) => of(loadAdventureListFailed(error)))
                    )
                ), catchError((error: string) => of(loadAdventureListFailed(error)))
            )
        )
    )

const updateAdventuresStatus = (adventures: Adventure[], adventureIds: string[]): Adventure[] => {
    const adventureResult: Adventure[] = []
    adventures.map(adventure => {
        if (adventureIds.find(id => id === adventure.id) && adventure.status === 'new')
            adventureResult.push(new AdventureBuilder()
                .withId(adventure.id)
                .withLabel(adventure.label)
                .withDescription(adventure.description)
                .withPicture(adventure.picture)
                .withEarns(adventure.earns)
                .withEndDate(adventure.endDate)
                .withStartDate(adventure.starDate)
                .withStatus('inprogress')
                .build())
        else
            adventureResult.push(adventure)
    })
    return adventureResult
}
