import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { TeamMateMapper } from './mapper/teamMate.mapper';
import { TeamMateService } from "../../../domain/gateway/teamMate.service";
import { TeamMate } from "../../../domain/entity/teamMate";
import { SecuredHttpClient } from "../../../../shared/adapters/secondaries/securedHttpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/config/dependencies.factory";
import { ProfileDto } from "../../../../profile/adapters/secondaries/real/dto/profileDto";


export class ApiTeamMateService implements TeamMateService {

    loadTeamMate(email: string): Observable<TeamMate> {
        const url = process.env.REACT_APP_API_URL + '/v1/profile/teammate'

        const body = new FormData();
        body.append('email', email)

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post<{ data: { profile: ProfileDto; badges: string[] } }>(url, body)
            .pipe(
                map(response => TeamMateMapper.mapDataToTeamMate(response.data.profile, response.data.badges)),
                catchError(err => throwError(err.status.toString()))
            )
    }

}
