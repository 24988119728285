import React from 'react';

export const CalendarIcon = (props: { className?: string }) => {
    return (
        <div className={props.className}>
            <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.43062 0.664551C1.05225 0.664551 0.736816 0.963389 0.736816 1.32184V12.1672C0.736816 12.5256 1.05225 12.8245 1.43062 12.8245H15.6537C16.0321 12.8245 16.3475 12.5256 16.3475 12.1672V1.32184C16.3475 0.963389 16.0321 0.664551 15.6537 0.664551H1.43062ZM1.43036 1.32201H15.6534V3.95118H1.43036V1.32201ZM3.51204 2.63648C3.51204 2.09585 3.98209 1.65054 4.55275 1.65054C5.12341 1.65054 5.59347 2.09585 5.59347 2.63648C5.59347 3.1771 5.12342 3.62242 4.55275 3.62242C3.98209 3.62242 3.51204 3.1771 3.51204 2.63648ZM12.5311 1.65054C11.9605 1.65054 11.4904 2.09585 11.4904 2.63648C11.4904 3.1771 11.9605 3.62242 12.5311 3.62242C13.1018 3.62242 13.5718 3.1771 13.5718 2.63648C13.5718 2.09585 13.1018 1.65054 12.5311 1.65054ZM4.89949 2.63662C4.89949 2.45121 4.74828 2.30797 4.55258 2.30797C4.35689 2.30797 4.20568 2.45121 4.20568 2.63662C4.20568 2.82201 4.35689 2.96526 4.55258 2.96526C4.74828 2.96526 4.89949 2.82201 4.89949 2.63662ZM12.5317 2.30797C12.7274 2.30797 12.8786 2.45121 12.8786 2.63662C12.8786 2.82201 12.7274 2.96526 12.5317 2.96526C12.336 2.96526 12.1848 2.82201 12.1848 2.63662C12.1848 2.45121 12.336 2.30797 12.5317 2.30797ZM15.6534 4.60841H1.43036V12.1673H15.6534V4.60841ZM2.64461 5.43011V11.3457H11.6641V8.71657H14.4394V5.43011H2.64461ZM5.41968 6.08716H3.33825V8.05904H5.41968V6.08716ZM6.11357 6.08716H8.19499V8.05904H6.11357V6.08716ZM10.9703 6.08716H8.88889V8.05904H10.9703V6.08716ZM11.6642 6.08716H13.7456V8.05904H11.6642V6.08716ZM5.41968 8.71651H3.33825V10.6884H5.41968V8.71651ZM6.11357 8.71651H8.19499V10.6884H6.11357V8.71651ZM10.9703 8.71651H8.88889V10.6884H10.9703V8.71651Z" fill="white"/>
            </svg>

        </div>
    );
};
