import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Adventure } from "../../domain/entities/adventure";
import { Profile } from "../../../profile/domain/entities/profile";
import { AdventureScore } from "../../domain/entities/score/adventureScore";
import { AppState } from "../../../redux-configuration/AppState";
import { adventureDetailsSelector } from "../../usecases/details/selector";
import { adventureScoreSelector } from "../../usecases/score/selector";
import { profileSelector } from "../../../profile/usecases/profile.selector";
import { LoadAdventureDetailsAction } from "../../usecases/details/actionTypes";
import { loadAdventureDetails } from "../../usecases/details/actions";
import { AdventureDetailsContainer } from "./adventureDetails.container";
import { MCQ } from "../../../MCQ/domain/entity/MCQ";
import { MCQSelector } from "../../../MCQ/usecases/load/selector";
import { AppEvent } from "../../../shared/domain/entities/logging/AppEvent";
import { LogEventAction } from "../../../shared/usecases/logging/analytics.types";
import { logEvent } from "../../../shared/usecases/logging/logging.action";

interface StateToPropsType {
    adventure: Adventure | undefined;
    profile: Profile | undefined;
    adventureScore: AdventureScore | undefined;
    mcq: MCQ | undefined;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    adventure     : adventureDetailsSelector(state),
    adventureScore: adventureScoreSelector(state),
    profile       : profileSelector(state),
    mcq           : MCQSelector(state)
})

interface DispatchToPropsType {
    loadAdventure: (id: string) => void;
    log: (event: AppEvent) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadAdventure: (id: string): LoadAdventureDetailsAction => dispatch(loadAdventureDetails(id)),
    log          : (event: AppEvent): LogEventAction => dispatch(logEvent(event))
})

export const AdventureDetails = connect(mapStateToProps, mapDispatchToProps)(AdventureDetailsContainer)
