import {  Epic, ofType, StateObservable } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { eventLogged, LOG_EVENT } from './logging.action'
import { LogEventAction } from "./analytics.types";
import { AppState } from "../../../redux-configuration/AppState";
import { EventLogger } from "../../domain/gateway/event.logger";

export const appLoggerEpic: Epic = (action$: Observable<LogEventAction>,
                                    store: StateObservable<AppState>,
                                    { eventLogger }: { eventLogger: EventLogger }) =>
    action$.pipe(
        ofType(LOG_EVENT),
        switchMap(action => {
                if (action.payload.isSecured) {
                    return eventLogger.securedLog(action.payload)
                        .pipe(
                            map(() => eventLogged())
                        )
                }
                else {
                    return eventLogger.unsecuredLog(action.payload)
                        .pipe(
                            map(() => eventLogged())
                        )
                }
            }
        )
    )
