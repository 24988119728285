import {
    LOAD_SCORE,
    LOAD_SCORE_FAILED,
    LOAD_SCORE_SUCCEEDED,
    LoadScoreAction,
    LoadScoreFailedAction,
    LoadScoreSucceededAction
} from "./actionTypes";
import { Score } from "../domain/entities/score";

export const loadScore = (): LoadScoreAction => ({
    type: LOAD_SCORE
})

export const loadScoreFailed = (error: string): LoadScoreFailedAction => ({
    type   : LOAD_SCORE_FAILED,
    payload: error
})

export const loadScoreSucceeded = (score: Score): LoadScoreSucceededAction => ({
    type   : LOAD_SCORE_SUCCEEDED,
    payload: score
})
