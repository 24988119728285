import {
    LOAD_TEAM_MATE,
    LOAD_TEAM_MATE_FAILED,
    LOAD_TEAM_MATE_SUCCEEDED,
    LoadTeamMateActionTypes
} from "./actionTypes";
import { TeamMate } from "../../domain/entity/teamMate";

export const loadTeamMate = (email: string): LoadTeamMateActionTypes => ({
    type   : LOAD_TEAM_MATE,
    payload: email
})

export const loadTeamMateFailed = (error: string): LoadTeamMateActionTypes => ({
    type   : LOAD_TEAM_MATE_FAILED,
    payload: error
})

export const loadTeamMateSucceeded = (teamMate: TeamMate): LoadTeamMateActionTypes => ({
    type   : LOAD_TEAM_MATE_SUCCEEDED,
    payload: teamMate
})
