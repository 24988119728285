import { MCQ } from "../../../../domain/entity/MCQ";
import { MCQDTO, MCQResponseDTO, MCQScoreDTO, MCQuestionDTO } from "../dto/MCQ.dto";
import { MCQQuestion } from "../../../../domain/entity/MCQQuestion";
import { MCQResponse } from "../../../../domain/entity/MCQResponse";
import { MCQResponseBuilder } from "../../../../domain/entity/MCQResponse.builder";
import { MCQQuestionBuilder } from "../../../../domain/entity/MCQQuestion.builder";
import { MCQBuilder } from "../../../../domain/entity/MCQ.builder";
import { MCQSpecifications } from "./specifications";
import { MCQScore } from "../../../../domain/entity/MCQScore";

export class MCQMapper {

    static mapMCQData(data: MCQDTO): MCQ {

        const questions: MCQQuestion[] = data.mcq.questions.map((question: MCQuestionDTO) => {

            const responses: MCQResponse[] = question.responses.map((response: MCQResponseDTO) =>
                new MCQResponseBuilder()
                    .withId(response.id)
                    .withText(response.text)
                    .withIsCorrect(response.correct)
                    .build()
            )

            return new MCQQuestionBuilder()
                .withId(question.id)
                .withText(question.text)
                .withDescription(question.description)
                .withPicture(question.picture)
                .withValidResponses(question.validResponses)
                .withResponses(responses)
                .build()
        })

        return new MCQBuilder()
            .withId(data.mcq.id)
            .withDescription(data.mcq.description)
            .withReward(data.mcq.reward)
            .withScore(data.score)
            .withCanAccessAdventure(data.canAccessAdventure)
            .withQuestions(MCQSpecifications.shuffleQuestions(questions))
            .build()
    }

    static mapMCQScore(score: MCQScoreDTO): MCQScore {
        return new MCQScore(score.score, score.canAccessAdventure)
    }

}
