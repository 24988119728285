import { SpeedQuizDetails } from "../../domain/entity/details";

export const LOAD_SPEED_QUIZ_DETAILS = 'LOAD_SPEED_QUIZ_DETAILS'
export const LOAD_SPEED_QUIZ_DETAILS_FAILED = 'LOAD_SPEED_QUIZ_DETAILS_FAILED'
export const LOAD_SPEED_QUIZ_DETAILS_SUCCEEDED = 'LOAD_SPEED_QUIZ_DETAILS_SUCCEEDED'

export interface LoadSpeedQuizDetailsAction {
    type: typeof LOAD_SPEED_QUIZ_DETAILS;
    payload: string;
}

export interface LoadSpeedQuizDetailsFailedAction {
    type: typeof LOAD_SPEED_QUIZ_DETAILS_FAILED;
    payload: string;
}

export interface LoadSpeedQuizDetailsSucceededAction {
    type: typeof LOAD_SPEED_QUIZ_DETAILS_SUCCEEDED;
    payload: SpeedQuizDetails;
}

export type LoadSpeedQuizDetailsActionTypes =
    LoadSpeedQuizDetailsAction
    | LoadSpeedQuizDetailsFailedAction
    | LoadSpeedQuizDetailsSucceededAction
