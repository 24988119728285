import {
    SUBSCRIBE_TO_ADVENTURE,
    SUBSCRIBE_TO_ADVENTURE_FAILED,
    SUBSCRIBE_TO_ADVENTURE_SUCCEEDED, SubscribeToAdventureAction,
    SubscribeToAdventureFailedAction, SubscribeToAdventureSucceededAction
} from './subscribeToAdventure.types';

export const subscribeToAdventure = (adventureId: string): SubscribeToAdventureAction => ({
    type   : SUBSCRIBE_TO_ADVENTURE,
    payload: adventureId
})

export const subscribeToAdventureFailed = (error: string): SubscribeToAdventureFailedAction => ({
    type   : SUBSCRIBE_TO_ADVENTURE_FAILED,
    payload: error
})

export const subscribeToAdventureSucceeded = (): SubscribeToAdventureSucceededAction => ({
    type: SUBSCRIBE_TO_ADVENTURE_SUCCEEDED
})
