export const Theme = {
    primary      : '#009EE3',
    secondary    : '#27348B',
    complementary: '#1468B6',
    disabled     : '#777777',
    yellow       : '#F5A623',
    gray         : '#3E3E3E',
    text         : {
        white: '#FFFFFF',
        gray : '#ADADAD'
    },
    regions      : {
        galacticos: {
            primary  : '#00FFC4',
            secondary: '#FE63B6'
        },
        androides : {
            primary  : '#18B6E2',
            secondary: '#C52EDF'
        },
        meteores  : {
            primary  : '#FAD961',
            secondary: '#F76B1C'
        },
        explorers : {
            primary  : '#B4EC51',
            secondary: '#429321'
        },
        cosmos    : {
            primary  : '#21177D',
            secondary: '#D0021B'
        },
        passengers: {
            primary  : '#FAD961',
            secondary: '#C52EDF'
        },
        travelers : {
            primary  : '#27348B',
            secondary: '#683E82'
        },
        siege     : {
            primary  : '#009EE3',
            secondary: '#27348B'
        },
        astroid     : {
            primary  : '#707070',
            secondary: '#FF6000'
        },
        andromeda     : {
            primary: '#FFFFFF',
            secondary : '#707070'
        }
    }
}
