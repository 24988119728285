import { SpeedQuizService } from "../../../domain/gateway/speedQuiz.service";
import { Observable, throwError } from "rxjs";
import { SpeedQuiz } from "../../../domain/entity/SpeedQuiz";
import { catchError, map } from 'rxjs/operators'
import { SpeedQuizMapper } from "./mapper/speedQuiz.mapper";
import { SecuredHttpClient } from "../../../../shared/adapters/secondaries/securedHttpClient";
import { AuthenticationDependenciesFactory } from "../../../../authentication/config/dependencies.factory";
import { SpeedQuizDTO } from "./dto/speedQuiz.dto";
import { SpeedQuizDetails } from "../../../domain/entity/details";
import { SpeedQuizDetailsDTO } from "./dto/details.dto";
import { SpeedQuizQuestion } from "../../../domain/entity/question";
import { SpeedQuizQuestionDTO } from "./dto/question.dto";
import { SpeedQuizUserAnswers } from "../../../domain/entity/userAnswers";
import { SpeedQuizResult } from "../../../domain/entity/result";
import { SpeedQuizResultDTO } from "./dto/result.dto";

export class ApiSpeedQuizService implements SpeedQuizService {

    loadSpeedQuizList(): Observable<SpeedQuiz[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/speedQuiz/list'

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: SpeedQuizDTO[] }>(url)
            .pipe(
                map((response: { data: SpeedQuizDTO[] }) => SpeedQuizMapper.mapSpeedQuizDTO(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    loadSpeedQuizDetails(speedQuizId: string): Observable<SpeedQuizDetails> {
        const url = process.env.REACT_APP_API_URL + '/v1/speedQuiz/' + speedQuizId + '/status'

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: SpeedQuizDetailsDTO }>(url)
            .pipe(
                map((response: { data: SpeedQuizDetailsDTO }) => SpeedQuizMapper.mapSpeedQuizDetailsDTO(response.data, speedQuizId)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    loadSpeedQuizQuestions(speedQuizId: string, roundId: string): Observable<SpeedQuizQuestion[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/speedQuiz/' + speedQuizId + '/round/' + roundId

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: SpeedQuizQuestionDTO[] }>(url)
            .pipe(
                map((response: { data: SpeedQuizQuestionDTO[] }) => SpeedQuizMapper.mapSpeedQuizQuestionsDTO(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }

    sendSpeedQuizAnswers(speedQuizId: string, roundId: string, answers: SpeedQuizUserAnswers[]): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/speedQuiz/' + speedQuizId + '/round/' + roundId + '/responses'

        const responses = answers.map(answer => ({
            questionId: answer.questionId,
            responses : answer.answers ? answer.answers : []
        }))

        const body = new FormData();

        body.append('quizId', speedQuizId)
        body.append('roundId', roundId)
        body.append('responses', JSON.stringify(responses))

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err.status.toString()))
            )
    }

    loadSpeedQuizResult(speedQuizId: string): Observable<SpeedQuizResult> {
        const url = process.env.REACT_APP_API_URL + '/v1/speedQuiz/' + speedQuizId + '/result'

        return new SecuredHttpClient(AuthenticationDependenciesFactory.getAuthenticationRepository())
            .get<{ data: SpeedQuizResultDTO }>(url)
            .pipe(
                map((response: { data: SpeedQuizResultDTO }) => SpeedQuizMapper.mapSpeedQuizResultDTO(response.data)),
                catchError(err => throwError(err.status.toString()))
            )
    }
}
