import { MCQQuestion } from "./MCQQuestion";

export class MCQ {
    constructor(
        protected _id: number,
        protected _description: string,
        protected _questions: MCQQuestion[],
        protected _score: number,
        protected _reward: number,
        protected _canAccessAdventure: boolean
    ) {
        this._questions = _questions
            .map(value => ({value, sort: Math.random()}))
            .sort((a, b) => a.sort - b.sort)
            .map(({value}) => value)
    }

    get id(): number {
        return this._id;
    }

    get description(): string {
        return this._description;
    }

    get questions(): MCQQuestion[] {
        return this._questions;
    }

    get score(): number {
        return this._score;
    }

    get reward(): number {
        return this._reward;
    }

    get canAccessAdventure(): boolean {
        return this._canAccessAdventure;
    }
}
