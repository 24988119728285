import { MCQ } from "./MCQ";
import { MCQQuestion } from "./MCQQuestion";

export class MCQBuilder {
    protected _id!: number
    protected _description!: string
    protected _questions!: MCQQuestion[]
    protected _score!: number
    protected _reward!: number
    protected _canAccessAdventure!: boolean

    withId(value: number): MCQBuilder {
        this._id = value
        return this
    }

    withDescription(value: string): MCQBuilder {
        this._description = value
        return this
    }

    withQuestions(value: MCQQuestion[]): MCQBuilder {
        this._questions = value
        return this
    }

    withScore(value: number): MCQBuilder {
        this._score = value
        return this
    }

    withReward(value: number): MCQBuilder {
        this._reward = value
        return this
    }

    withCanAccessAdventure(value: boolean): MCQBuilder {
        this._canAccessAdventure = value
        return this
    }

    build(): MCQ {
        return new MCQ(
            this._id,
            this._description,
            this._questions,
            this._score,
            this._reward,
            this._canAccessAdventure
        )
    }
}
