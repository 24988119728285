import {
    LOAD_SPEED_QUIZ_DETAILS,
    LOAD_SPEED_QUIZ_DETAILS_FAILED,
    LOAD_SPEED_QUIZ_DETAILS_SUCCEEDED,
    LoadSpeedQuizDetailsAction,
    LoadSpeedQuizDetailsFailedAction,
    LoadSpeedQuizDetailsSucceededAction
} from './actionTypes';
import { SpeedQuizDetails } from "../../domain/entity/details";


export const loadSpeedQuizDetails = (id: string): LoadSpeedQuizDetailsAction => ({
    type   : LOAD_SPEED_QUIZ_DETAILS,
    payload: id
})

export const loadSpeedQuizDetailsFailed = (error: string): LoadSpeedQuizDetailsFailedAction => ({
    type   : LOAD_SPEED_QUIZ_DETAILS_FAILED,
    payload: error
})

export const loadSpeedQuizDetailsSucceeded = (speedQuiz: SpeedQuizDetails): LoadSpeedQuizDetailsSucceededAction => ({
    type   : LOAD_SPEED_QUIZ_DETAILS_SUCCEEDED,
    payload: speedQuiz
})
