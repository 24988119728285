import { Epic, ofType, StateObservable } from 'redux-observable';
import { of, Observable } from 'rxjs'
import { catchError, concatMap, switchMap } from 'rxjs/operators'
import { AdventureService } from '../../domain/gateway/adventure.service';
import { LOAD_ADVENTURE_SCORE, LoadAdventureScoreAction, } from './actionTypes';
import { LoadAdventureScoreState } from '../../config/state';
import { AdventureScore } from "../../domain/entities/score/adventureScore";
import { loadAdventureScoreFailed, loadAdventureScoreSucceeded } from "./actions";

export const loadAdventureScoreEpic: Epic = (action$: Observable<LoadAdventureScoreAction>,
                                             store: StateObservable<LoadAdventureScoreState>,
                                             { adventureService }: { adventureService: AdventureService }) =>
    action$.pipe(
        ofType(LOAD_ADVENTURE_SCORE),
        switchMap(action => adventureService.loadAdventureScore(action.payload)
            .pipe(
                concatMap((score: AdventureScore) => [
                    loadAdventureScoreSucceeded(score),
                ]),
                catchError((error: string) => of(loadAdventureScoreFailed(error)))
            )
        )
    )
