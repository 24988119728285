import { SpeedQuizService } from '../../domain/gateway/speedQuiz.service';
import { SpeedQuiz } from '../../domain/entity/SpeedQuiz';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { LOAD_SPEED_QUIZ_LIST, LoadSpeedQuizListAction } from './actionTypes';
import { loadSpeedQuizListFailed, loadSpeedQuizListSucceeded } from './actions';
import { SpeedQuizListState } from '../../config/state';

export const loadSpeedQuizListEpic: Epic = (action$: Observable<LoadSpeedQuizListAction>,
                                            store: StateObservable<SpeedQuizListState>,
                                            {speedQuizService}: { speedQuizService: SpeedQuizService }) =>
    action$.pipe(
        ofType(LOAD_SPEED_QUIZ_LIST),
        switchMap(() => speedQuizService.loadSpeedQuizList()
            .pipe(
                map((speedQuiz: SpeedQuiz[]) => loadSpeedQuizListSucceeded(speedQuiz)),
                catchError(err => of(loadSpeedQuizListFailed(err)))
            )
        )
    );
