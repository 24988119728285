import { ScoreState } from '../config/state';
import { LOAD_SCORE, LOAD_SCORE_FAILED, LOAD_SCORE_SUCCEEDED, LoadScoreActionTypes } from "./actionTypes";

const initialState: ScoreState = {
    loading : false,
    error   : undefined,
    players : undefined,
    regions : undefined,
    agencies: undefined,
}


export const scoreReducer = (state = initialState, action: LoadScoreActionTypes): ScoreState => {
    switch (action.type) {
        case LOAD_SCORE:
            return {
                loading : true,
                error   : undefined,
                players : undefined,
                regions : undefined,
                agencies: undefined
            }
        case LOAD_SCORE_FAILED:
            return {
                loading : false,
                error   : action.payload,
                players : undefined,
                regions : undefined,
                agencies: undefined
            }
        case LOAD_SCORE_SUCCEEDED:
            return {
                loading : false,
                error   : undefined,
                players : action.payload.players,
                regions : action.payload.regions,
                agencies: action.payload.agencies
            }
        default:
            return state;
    }
}
