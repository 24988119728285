import {
    REQUEST_PASSWORD,
    REQUEST_PASSWORD_FAILED,
    REQUEST_PASSWORD_SUCCEEDED,
    RequestPasswordActionTypes
} from './actionTypes';
import { HandleRequestState } from "../../../redux-configuration/AppState";


const initialState: HandleRequestState = {
    error  : undefined,
    loading: false,
    success: undefined
}

export const requestPasswordReducer = (state = initialState, action: RequestPasswordActionTypes): HandleRequestState => {
    switch (action.type) {
        case REQUEST_PASSWORD:
            return {
                error  : undefined,
                loading: true,
                success: undefined
            }
        case REQUEST_PASSWORD_FAILED:
            return {
                error  : action.payload,
                loading: false,
                success: undefined
            }
        case REQUEST_PASSWORD_SUCCEEDED:
            return {
                error  : undefined,
                loading: false,
                success: true
            }
        default:
            return state
    }
}
