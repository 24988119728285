import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Profile } from "../../../../profile/domain/entities/profile";
import { Adventure } from "../../../../adventure/domain/entities/adventure";
import { SpeedQuiz } from "../../../../speedQuiz/domain/entity/SpeedQuiz";
import { AppState } from "../../../../redux-configuration/AppState";
import { loadSpeedQuizListLoadingSelector, speedQuizListSelector } from "../../../../speedQuiz/usecases/list/selectors";
import { profileSelector } from "../../../../profile/usecases/profile.selector";
import { adventureListSelector, loadAdventureListLoadingSelector } from "../../../../adventure/usecases/list/selector";
import { LoadAdventureListAction } from "../../../../adventure/usecases/list/actionTypes";
import { loadAdventureList } from "../../../../adventure/usecases/list/actions";
import { LoadSpeedQuizListAction } from "../../../../speedQuiz/usecases/list/actionTypes";
import { loadSpeedQuizList } from "../../../../speedQuiz/usecases/list/actions";
import { MissionsContainer } from "./missions.container";

interface StateToPropsType {
    loading: boolean;
    profile: Profile | undefined
    adventures: Adventure[] | undefined;
    speedQuizList: SpeedQuiz[] | undefined;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading      : loadAdventureListLoadingSelector(state) || loadSpeedQuizListLoadingSelector(state),
    profile      : profileSelector(state),
    adventures   : adventureListSelector(state),
    speedQuizList: speedQuizListSelector(state),
})

interface DispatchToPropsType {
    loadAdventures: () => void;
    loadSpeedQuizList: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadAdventures   : (): LoadAdventureListAction => dispatch(loadAdventureList()),
    loadSpeedQuizList: (): LoadSpeedQuizListAction => dispatch(loadSpeedQuizList()),
})

export const Missions = connect(mapStateToProps, mapDispatchToProps)(MissionsContainer)
