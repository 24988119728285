import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Profile } from "../../../profile/domain/entities/profile";
import { AppState } from "../../../redux-configuration/AppState";
import { profileSelector } from "../../../profile/usecases/profile.selector";
import { MCQ } from "../../domain/entity/MCQ";
import { MCQSelector } from "../../usecases/load/selector";
import { LoadAdventureDetailsAction } from "../../../adventure/usecases/details/actionTypes";
import { loadAdventureDetails } from "../../../adventure/usecases/details/actions";
import { McqContainer } from "./mcq.Container";
import { loadMCQ } from "../../usecases/load/actions";
import { LoadMCQAction } from "../../usecases/load/actionTypes";
import { Adventure } from "../../../adventure/domain/entities/adventure";
import { adventureDetailsSelector } from "../../../adventure/usecases/details/selector";
import { MCQAnswerType } from "../../domain/type/MCQAnswer.type";
import { SendMCQAnswersAction } from "../../usecases/send/actionTypes";
import { sendMCQAnswers } from "../../usecases/send/actions";
import { MCQScoreSelector } from "../../usecases/send/selector";
import { MCQScore } from "../../domain/entity/MCQScore";

interface StateToPropsType {
    profile: Profile | undefined;
    adventure: Adventure | undefined;
    mcq: MCQ | undefined;
    mcqScore: MCQScore | undefined;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    profile  : profileSelector(state),
    mcq      : MCQSelector(state),
    adventure: adventureDetailsSelector(state),
    mcqScore : MCQScoreSelector(state)
})

interface DispatchToPropsType {
    loadAdventure: (id: string) => void;
    loadMcq: (id: string) => void;
    sendMCQResponse: (adventureId: string, mcqId: number, response: MCQAnswerType[]) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadAdventure  : (adventureId: string): LoadAdventureDetailsAction => dispatch(loadAdventureDetails(adventureId)),
    loadMcq        : (mcqId: string): LoadMCQAction => dispatch(loadMCQ(mcqId)),
    sendMCQResponse: (adventureId: string, mcqId: number, response: MCQAnswerType[]): SendMCQAnswersAction => dispatch(sendMCQAnswers(mcqId, adventureId, response))
})

export const McqPage = connect(mapStateToProps, mapDispatchToProps)(McqContainer)
