import { AdventurePlayerScore } from './adventurePlayerScore';
import { AdventureAgencyScore } from './adventureAgencyScore';

export class AdventureScore {
    constructor(
        protected _playersScore: AdventurePlayerScore[],
        protected _agenciesScore: AdventureAgencyScore[],
        protected _totalTurnover: number,
        protected _totalTurnoverGoal: number,
        protected _totalActivatedClient: number,
        protected _totalActivatedClientGoal: number
    ) {
    }

    get playersScore(): AdventurePlayerScore[] {
        return this._playersScore;
    }

    get agenciesScore(): AdventureAgencyScore[] {
        return this._agenciesScore;
    }

    get totalTurnover(): number {
        return this._totalTurnover;
    }

    get totalTurnoverGoal(): number {
        return this._totalTurnoverGoal;
    }

    get totalActivatedClient(): number {
        return this._totalActivatedClient;
    }

    get totalActivatedClientGoal(): number {
        return this._totalActivatedClientGoal;
    }

    /**
     * this function may be used only for type: SUPPORT
     */
    getTotalScoreWithPercentage(): string {
        const turnoverScore = (this.totalTurnover / this.totalTurnoverGoal)
        const activatedClientScore = (this.totalActivatedClient / this.totalActivatedClientGoal)
        const avg = (activatedClientScore + turnoverScore) / 2
        return (avg * 100).toFixed(2) + "%"
    }
}
