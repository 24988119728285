import { AvatarType, NetworkType, ProfileType } from "./types";

export class Profile {
    constructor(
        protected _firstName: string,
        protected _lastName: string,
        protected _agency: string,
        protected _agencyCode: string,
        protected _region: string,
        protected _avatar: AvatarType,
        protected _score: number,
        protected _type: ProfileType,
        protected _email: string,
        protected _network: NetworkType
    ) {
    }

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get agency(): string {
        return this._agency;
    }

    get agencyCode():  string {
        return this._agencyCode;
    }

    get region(): string {
        return this._region;
    }

    get avatar(): AvatarType {
        return this._avatar;
    }

    get score(): number {
        return this._score;
    }

    get abbreviatedName(): string {
        return `${this._firstName} ${this._lastName.substr(0, 1)}.`
    }

    get type(): ProfileType {
        return this._type;
    }

    get email(): string {
        return this._email;
    }

    get network(): NetworkType {
        return this._network;
    }

    capitalize(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1)
    }

    displayName(): string {
        return this.capitalize(this._firstName) + ' ' + this.capitalize(this._lastName)
    }
}
