import React from "react";
import { Adventure } from "../../../../../adventure/domain/entities/adventure";
import { AdventureSpecifications } from "../../../../../adventure/adapters/primaries/specifications";
import { DashboardI18n } from "../../../../config/i18n";
import { RocketIcon } from "../../../../../shared/adapters/primaries/icons/rocket.icon";
import { CalendarIcon } from "../../../../../shared/adapters/primaries/icons/calendar.icon";

interface Props {
    adventure: Adventure;
    onClick: () => void;
}

export default function MissionAdventureItem(props: Props) {
    return (
        <div className={'mission-item d-flex align-items-center'} onClick={() => props.onClick()}>
            <img src={props.adventure.picture}
                 alt={'Aventure'}
                 width={110}
                 height={110}/>

            <div className={'content'}>
                <h2>{props.adventure.label}</h2>

                <div className={'d-flex'}>
                    <div className={'mission-score d-flex align-items-center'}>
                        <RocketIcon isSelected={() => true} width={20} height={17}/>
                        <span>{props.adventure.earns}</span>
                    </div>

                    <div className={'mission-date d-flex align-items-center'}>
                        <CalendarIcon/>
                        <span>{AdventureSpecifications.getAdventureDate(props.adventure.starDate, props.adventure.endDate)}</span>
                    </div>
                </div>

                <div className={'d-flex align-items-center mt-3'}>
                    <span className={'progress-text'}>{AdventureSpecifications.daysLeftInPercentage(props.adventure)}</span>

                    <div className={'mission-progress-bar'}>
                        <div className={'mission-progress'} style={{width: AdventureSpecifications.daysLeftInPercentage(props.adventure)}}></div>
                    </div>
                </div>
            </div>

            <button>{DashboardI18n.start}</button>
        </div>
    )
}
