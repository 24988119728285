import React, { CSSProperties, JSX } from 'react';
import { Theme } from "../../config/theme";

type props = {
    region: string | undefined;
    styles?: CSSProperties;
    children?: JSX.Element
};
export const RegionBackground = (props: props) => {

    let bgColors: [string, string]
    switch (props.region) {
        case 'GALACTICOS':
            bgColors = [Theme.regions.galacticos.primary, Theme.regions.galacticos.secondary];
            break;
        case 'ANDROIDES':
            bgColors = [Theme.regions.androides.primary, Theme.regions.androides.secondary]
            break;
        case 'METEORES':
            bgColors = [Theme.regions.meteores.primary, Theme.regions.meteores.secondary]
            break;
        case 'EXPLORERS':
            bgColors = [Theme.regions.explorers.primary, Theme.regions.explorers.secondary]
            break;
        case 'COSMOS':
            bgColors = [Theme.regions.cosmos.primary, Theme.regions.cosmos.secondary]
            break;
        case 'PASSENGERS':
            bgColors = [Theme.regions.passengers.primary, Theme.regions.passengers.secondary]
            break;
        case 'MILKYWAY':
            bgColors = [Theme.regions.travelers.primary, Theme.regions.travelers.secondary]
            break;
        case 'SIEGE':
            bgColors = [Theme.regions.siege.primary, Theme.regions.siege.secondary]
            break;
        case 'ASTEROID':
            bgColors = [Theme.regions.astroid.primary, Theme.regions.astroid.secondary]
            break;
        case 'ANDROMEDA':
            bgColors = [Theme.regions.andromeda.primary, Theme.regions.andromeda.secondary]
            break;
        default:
            bgColors = [Theme.regions.galacticos.primary, Theme.regions.galacticos.secondary]
    }


    return (
        <div style={{...styles(...bgColors).gradientBG, ...props.styles}}>
            {props.children}
        </div>
    );
};

const styles = (primary: string, secondary: string) => ({
    gradientBG: {
        backgroundImage: `linear-gradient(300deg, ${primary} 0%, ${secondary} 100%)`,
        borderRadius   : 15,
        height         : 25,
        width          : 25,

    }
})
