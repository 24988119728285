import { Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { ScoreState } from '../config/state';
import { ScoreService } from '../domain/gateway/scoreService';
import { Score } from '../domain/entities/score';
import { LOAD_SCORE, LoadScoreAction } from "./actionTypes";
import { loadScoreFailed, loadScoreSucceeded } from "./actions";

export const scoreEpic: Epic = (action$: Observable<LoadScoreAction>,
                                store: StateObservable<ScoreState>,
                                {scoreService}: { scoreService: ScoreService }) =>
    action$.pipe(
        ofType(LOAD_SCORE),
        switchMap(() => scoreService.loadScore()
            .pipe(
                map((score: Score) => loadScoreSucceeded(score)),
                catchError((error: string) => of(loadScoreFailed(error)))
            )
        )
    );
