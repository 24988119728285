import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Profile } from "../../../../profile/domain/entities/profile";
import { Message } from "../../../../message/domain/entities/message";
import { Adventure } from "../../../../adventure/domain/entities/adventure";
import { PlayerScore } from "../../../../score/domain/entities/playerScore";
import { SpeedQuiz } from "../../../../speedQuiz/domain/entity/SpeedQuiz";
import { AppState } from "../../../../redux-configuration/AppState";
import { loadMessagesLoadingSelector, messagesSelector } from "../../../../message/usecases/load/selectors";
import { loadSpeedQuizListLoadingSelector, speedQuizListSelector } from "../../../../speedQuiz/usecases/list/selectors";
import { profileSelector } from "../../../../profile/usecases/profile.selector";
import { adventureListSelector, loadAdventureListLoadingSelector } from "../../../../adventure/usecases/list/selector";
import { playersScoreSelector } from "../../../../score/usecases/selectors";
import { LoadScoreAction } from "../../../../score/usecases/actionTypes";
import { loadScore } from "../../../../score/usecases/actions";
import { LoadMessagesAction } from "../../../../message/usecases/load/actionTypes";
import { loadMessages } from "../../../../message/usecases/load/actions";
import { LoadAdventureListAction } from "../../../../adventure/usecases/list/actionTypes";
import { loadAdventureList } from "../../../../adventure/usecases/list/actions";
import { LoadSpeedQuizListAction } from "../../../../speedQuiz/usecases/list/actionTypes";
import { loadSpeedQuizList } from "../../../../speedQuiz/usecases/list/actions";
import { DashboardContainer } from "./dashboard.container";
import { AppEvent } from "../../../../shared/domain/entities/logging/AppEvent";
import { LogEventAction } from "../../../../shared/usecases/logging/analytics.types";
import { logEvent } from "../../../../shared/usecases/logging/logging.action";
import { retrieveBadges } from "../../../../profile/usecases/retrieveBadges/loadBadges.actions";
import { RetrieveBadgesAction } from "../../../../profile/usecases/retrieveBadges/loadBadges.types";

interface StateToPropsType {
    loading: boolean;
    profile: Profile | undefined
    messages: Message[] | undefined;
    adventures: Adventure[] | undefined;
    playersScore: PlayerScore[] | undefined;
    speedQuizList: SpeedQuiz[] | undefined;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading      : loadMessagesLoadingSelector(state) || loadSpeedQuizListLoadingSelector(state) || loadAdventureListLoadingSelector(state),
    profile      : profileSelector(state),
    messages     : messagesSelector(state),
    adventures   : adventureListSelector(state)?.filter((adventure) => adventure.status != 'archived'),
    playersScore : playersScoreSelector(state),
    speedQuizList: speedQuizListSelector(state),
})

interface DispatchToPropsType {
    loadScore: () => void;
    loadBadges: () => void;
    loadMessages: () => void;
    loadAdventures: () => void;
    loadSpeedQuizList: () => void;
    log: (event: AppEvent) => void;

}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadScore        : (): LoadScoreAction => dispatch(loadScore()),
    loadBadges       : (): RetrieveBadgesAction => dispatch(retrieveBadges()),
    loadMessages     : (): LoadMessagesAction => dispatch(loadMessages()),
    loadAdventures   : (): LoadAdventureListAction => dispatch(loadAdventureList()),
    loadSpeedQuizList: (): LoadSpeedQuizListAction => dispatch(loadSpeedQuizList()),
    log              : (event: AppEvent): LogEventAction => dispatch(logEvent(event))
})

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
