import React, { useEffect } from "react";
import "./profile.css"
import { Profile } from "../../domain/entities/profile";
import { Badge } from "../../domain/entities/badge/badge";
import { AvatarType } from "../../domain/entities/types";
import { useSearchParams } from "react-router-dom";
import { RegionBackground } from "../../../shared/adapters/primaries/region.background";
import LoadingContainer from "../../../shared/adapters/primaries/loading/loading";
import { findAvatar } from "../../config/findAvatar";
import GalaxyIcon from "../../../shared/adapters/primaries/icons/galaxy.icon";
import PinIcon from "../../../shared/adapters/primaries/icons/pin.icon";
import { BadgesList } from "./badge/badges.list";
import { BadgeDetailsModal } from "./badge/badgeDetailsModal";
import { UpdateAvatarModal } from "./updateAvatar/updateAvatar.modal";
import { useNavigate } from "react-router-dom";
import { ProfileI18n } from "../../config/i18n";
import DashboardHeader from "../../../dashboard/adapters/primaries/dashboard/components/header";
import { EditIcon } from "./updateAvatar/edit.icon";
import { AppEventBuilder } from "../../../shared/domain/entities/logging/appEvent.builder";
import { BeaconsType } from "../../../shared/domain/entities/logging/beacons.type";
import { AppEvent } from "../../../shared/domain/entities/logging/AppEvent";

interface Props {
    loading: boolean;
    profile: Profile | undefined;
    badges: Badge[] | undefined;
    retrieveProfile: () => void;
    retrieveBadges: () => void;
    updateAvatar: (avatarId: AvatarType) => void;
    updateAvatarSucceeded: boolean | undefined;
    log: (event: AppEvent) => void;
}

export default function ProfileContainer(props: Props) {

    const [searchParams] = useSearchParams()
    const router = useNavigate()

    const badge = props.badges?.find((item) => item.id === searchParams.get('badge'))

    useEffect(() => {
        props.retrieveBadges()
        const event = (new AppEventBuilder())
            .withBeacon(BeaconsType.DISPLAY_PROFILE)
            .build()
        props.log(event)

    }, [])

    useEffect(() => {
        if (props.updateAvatarSucceeded === true) {
            router(-1)
        }
    }, [props.updateAvatarSucceeded])

    if (props.loading) {
        return <LoadingContainer/>
    }

    return (
        <div className="profile-dashboard">
            <DashboardHeader title={ProfileI18n.myProfile}/>

            <div className={'row m-0'}>
                <div className={'col-6 ps-0'}>
                    <div className={'item pt-5 pb-4'}>
                        <RegionBackground styles={avatarStyle}
                                          region={props.profile?.region}>
                            <div className={"position-relative"}
                                 onClick={() => router('/profile?update=avatar')}>

                                <div className={'profile-edit-btn'}>
                                    <EditIcon/>
                                </div>

                                <img className={'profile-image'} width={140} height={140}
                                     src={findAvatar(props.profile?.avatar).image}
                                     alt={'avatar'}/>
                            </div>
                        </RegionBackground>

                        <h2 className={'text-center mb-4'}>{props.profile?.firstName} {props.profile?.lastName} </h2>

                        <div className={"d-flex justify-content-center"}>
                            <div className={"frame d-flex justify-content-center align-items-center"}>
                                <GalaxyIcon width={29}/>
                                <h3>{props.profile?.region}</h3>
                            </div>

                            <div className={"frame d-flex justify-content-center align-items-center"}>
                                <PinIcon/>
                                <h3>{props.profile?.agency}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'col-6 badges pe-0'}>
                    <div className={'item'}>
                        <h2>{ProfileI18n.myBadges}</h2>

                        {props.badges && <BadgesList badges={props.badges}/>}
                    </div>
                </div>
            </div>

            {searchParams.get('badge') && badge &&
                <BadgeDetailsModal onClose={() => router(-1)} badge={badge}/>}

            {searchParams.get('update') && props.profile &&
                <UpdateAvatarModal currentAvatar={props.profile.avatar}
                                   onAvatarChosen={(avatarId) => props.updateAvatar(avatarId)}
                                   onClose={() => router(-1)}/>}
        </div>
    )
}

const avatarStyle = {
    width       : 150,
    height      : 150,
    padding     : 5,
    borderRadius: 75,
    marginBottom: 35,
    marginLeft  : 'auto',
    marginRight : 'auto'
};
