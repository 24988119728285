import { concatMap, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { SEND_MCQ_ANSWERS, SendMCQAnswersAction } from "./actionTypes";
import { SendMCQAnswersState } from "../../config/state";
import { MCQService } from "../../domain/gateway/MCQ.service";
import { sendMCQAnswersFailed, sendMCQAnswersSucceeded } from "./actions";
import { MCQScore } from "../../domain/entity/MCQScore";
import { loadAdventureDetails } from "../../../adventure/usecases/details/actions";

export const sendMCQAnswersEpic: Epic = (action$: Observable<SendMCQAnswersAction>,
                                         store: StateObservable<SendMCQAnswersState>,
                                         {mCQService}: { mCQService: MCQService }) =>
    action$.pipe(
        ofType(SEND_MCQ_ANSWERS),
        switchMap(action => mCQService.sendMCQAnswers(action.payload.mcqId, action.payload.answers)
            .pipe(
                concatMap((score: MCQScore) => [
                    loadAdventureDetails(action.payload.adventureId),
                    sendMCQAnswersSucceeded(score)
                ]),
                catchError(err => of(sendMCQAnswersFailed(err)))
            )
        )
    )
