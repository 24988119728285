import {
    LOAD_SPEED_QUIZ_RESULT,
    LOAD_SPEED_QUIZ_RESULT_FAILED,
    LOAD_SPEED_QUIZ_RESULT_SUCCEEDED,
    LoadSpeedQuizResultAction,
    LoadSpeedQuizResultFailedAction,
    LoadSpeedQuizResultSucceededAction
} from "./actionTypes";
import { SpeedQuizResult } from "../../domain/entity/result";

export const loadSpeedQuizResult = (speedQuizId: string): LoadSpeedQuizResultAction => ({
    type   : LOAD_SPEED_QUIZ_RESULT,
    payload: speedQuizId
})

export const loadSpeedQuizResultFailed = (error: string): LoadSpeedQuizResultFailedAction => ({
    type   : LOAD_SPEED_QUIZ_RESULT_FAILED,
    payload: error
})

export const loadSpeedQuizResultSucceeded = (result: SpeedQuizResult): LoadSpeedQuizResultSucceededAction => ({
    type   : LOAD_SPEED_QUIZ_RESULT_SUCCEEDED,
    payload: result
})
