import React from "react"
import './loading.css';

export default function LoadingContainer(props: { className?: string }) {
    return (
        <div className={`ring ${props.className}`}>
            <p>Loading...</p>
        </div>
    )
}
