import {
    AdventureAgencyScoreDto,
    AdventureDto,
    AdventurePlayerScoreDto,
    AdventureScoreDto
} from '../dto/adventure.dto';
import { Adventure } from '../../../../domain/entities/adventure';
import { AdventureBuilder } from '../../../../domain/entities/adventure.builder';
import { ApplicationContext } from "../../../../../shared/config/application.context";
import { AdventureStatusType } from "../../../../domain/types";
import { AdventureScore } from "../../../../domain/entities/score/adventureScore";
import { AdventurePlayerScore } from "../../../../domain/entities/score/adventurePlayerScore";
import { AdventureAgencyScore } from "../../../../domain/entities/score/adventureAgencyScore";
import { AdventureScoreBuilder } from "../../../../domain/entities/score/adventureScore.builder";
import { AdventureAgencyScoreBuilder } from "../../../../domain/entities/score/adventureAgencyScore.builder";
import { AdventurePlayerScoreBuilder } from "../../../../domain/entities/score/adventurePlayerScore.builder";

const moment = ApplicationContext.getInstance().momentJs()

export class AdventureMapper {

    static mapAdventureList(adventuresDto: AdventureDto[]): Adventure[] {
        return adventuresDto.map(adventureDTO => {
            return new AdventureBuilder()
                .withId(adventureDTO.id)
                .withLabel(adventureDTO.label)
                .withDescription(adventureDTO.details)
                .withPicture(adventureDTO.picture)
                .withStartDate(moment(adventureDTO.startDateTime).toDate())
                .withEndDate(moment(adventureDTO.endDateTime).toDate())
                .withEarns(adventureDTO.reward)
                .withType(adventureDTO.type)
                .withStatus(this.getStatusAdventure(adventureDTO))
                .build()
        })
    }

    private static getStatusAdventure(adventureDto: AdventureDto): AdventureStatusType {
        if (moment(adventureDto.endDateTime).toDate().getTime() < (new Date()).getTime()) {
            return 'archived'
        }
        return 'new'
    }

    static mapAdventureScoreDtoToAdventureScore(score: AdventureScoreDto): AdventureScore {
        const players: AdventurePlayerScore[] = this.mapPlayers(score.players)
        const agencies: AdventureAgencyScore[] = this.mapAgencies(score.agencies)

        const totalTurnover = score.totalTurnover ? Math.trunc(score.totalTurnover) : 0
        const totalTurnoverGoal = score.totalTurnoverGoal ? Math.trunc(score.totalTurnoverGoal) : 0
        const totalActivatedClient = score.totalActivatedClient ? Math.trunc(score.totalActivatedClient) : 0
        const totalActivatedClientGoal = score.totalActivatedClientGoal ? Math.trunc(score.totalActivatedClientGoal) : 0

        return new AdventureScoreBuilder()
            .withAgenciesScore(agencies)
            .withPlayersScore(players)
            .withTotalTurnover(totalTurnover)
            .withTotalTurnoverGoal(totalTurnoverGoal)
            .withTotalActivatedClient(totalActivatedClient)
            .withTotalActivatedClientGoal(totalActivatedClientGoal)
            .build()
    }

    private static mapAgencies(agencies: AdventureAgencyScoreDto[]): AdventureAgencyScore[] {
        return agencies.map(item => {
            const turnover = item.turnover ? Math.trunc(item.turnover) : 0
            const turnoverGoal = item.turnoverGoal ? Math.trunc(item.turnoverGoal) : 0

            const agencyScore = new AdventureAgencyScoreBuilder()
                .withRank(item.rank)
                .withName(item.name)
                .withRegion(item.regionLabel)
                .withScore(item.score)
                .withTurnover(turnover)
                .withTurnoverGoal(turnoverGoal)
                .withCode(item.code)

            if (item.category !== null) {
                agencyScore.withCategory(item.category)
            }
            if (item.activatedClient !== null) {
                agencyScore.withActivatedClient(item.activatedClient)
            }

            return agencyScore.build()
        })
    }

    private static mapPlayers(players: AdventurePlayerScoreDto[]): AdventurePlayerScore[] {
        return players.map(item => {
            const turnover = item.turnover ? Math.trunc(item.turnover) : 0
            const turnoverGoal = item.turnoverGoal ? Math.trunc(item.turnoverGoal) : 0
            const activatedClient = item.activatedClient ? Math.trunc(item.activatedClient) : 0
            const activatedClientGoal = item.activatedClientGoal ? Math.trunc(item.activatedClientGoal) : 0

            return new AdventurePlayerScoreBuilder()
                .withRank(item.rank)
                .withEmail(item.email)
                .withFullName(item.name)
                .withAgency(item.agencyName)
                .withAvatar(item.avatar)
                .withRegion(item.regionLabel)
                .withTurnover(turnover)
                .withTurnoverGoal(turnoverGoal)
                .withActivatedClient(activatedClient)
                .withActivatedClientGoal(activatedClientGoal)
                .build()
        })
    }
}
