export const DashboardI18n = {
    dashboard        : 'Tableau de board',
    missions         : 'Mes Missions',
    welcome          : 'Bonjour',
    message          : 'Message',
    readMessage      : 'Lire le message',
    lastMissions     : 'Dernières missions',
    seeMore          : 'Tout voir',
    start            : 'C\'est parti',
    statistics       : 'Vos statistiques',
    individualRank   : 'Classement individuel',
    collectiveRank   : 'Classement collective',
    completedMissions: 'Missions complètes',
    earnedBadges     : 'Badges obtenus',
    new              : 'Nouveaux',
    inProgress       : 'En cours',
    archived         : 'Historique',
    noMission         : 'Pas de mission pour cet onglet',
}
