export const AuthenticationI18n = {
    title               : 'Bienvenue,',
    subTitle            : 'Veuillez vous connecter',
    empty_code_error    : 'Veuillez saisir votre mot de passe',
    code_error          : 'Veuillez vérifier votre mot de passe',
    request_password_400: 'Aucun astronaut trouvé avec cet e-mail',
    request_password_406: 'Vous avez dépassée le limite de demande de mot de passe',
    invalid_email       : 'L\'email n\'est pas valide : il doit se terminer par @sonepar.fr ou cged.fr ou @bianchi.corsica.',
    valid_email         : 'Utilisez votre adresse @sonepar.fr, @cged.fr ou @bianchi.corsica',
    empty_email         : 'Veuillez saisir votre email',
    email_address       : 'Email sonepar',
    password            : 'Mot de passe',
    ask_for_password    : 'Demander un mot de passe',
    sign_in             : 's\'authentifier',
    welcome             : 'Bienvenue. Vous avez pris place à bord de la navette spatiale <b>SONEPARSpace X20</b><br/>Le voyage ne fait que démarrer et vous reservera de nombreuses surprises.',
    explanation         : 'Vous avez l\'honneur d\'avoir été choisi(e) par le commandant Digit, le meilleur pilote de vaisseaux de notre compagnie.' +
        '<br/>Il sera votre compagnon tout au long de vos prochains défis',
    ready               : 'Alors serez-vous pret à vous lancer dans l\'aventure ?<br/><br/>Bon voyage !',
    start               : 'Commencer'
}
