export const agencyCategories: Record<'A' | 'B' | 'C', {
    prime: number[];
    boostMin: number[];
    boostMax: number[];
}> = {
    A: {
        prime   : [250, 400, 800],
        boostMin: [4, 6, 11],
        boostMax: [5, 10, 999]
    },
    B: {
        prime   : [400, 650, 1100],
        boostMin: [6, 11, 16],
        boostMax: [10, 15, 999]
    },
    C: {
        prime   : [650, 800, 1200],
        boostMin: [8, 13, 18],
        boostMax: [12, 17, 999]
    }
}
