import React from "react"

interface Props {
    width: number;
    height: number;
    isSelected: () => boolean;
}

export function ProfileIcon(props: Props) {
    const color = props.isSelected() ? '#FFF' : '#979797'

    return (
        <svg width={props.width} height={props.height} viewBox={`0 0 ${props.width} ${props.height}`}>
            <g fill="none" fillRule="evenodd" stroke="#3E3E3E">
                <g fill={color} fillRule="nonzero" strokeWidth={0}>
                    <path
                        d="M23 12a7 7 0 110 14 7 7 0 010-14zm0-1c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8h0z"/>
                    <path
                        d="M31 19h-.998a3 3 0 00-2.994-3h-8.016a3 3 0 00-2.994 3H15a4 4 0 013.992-4h8.016A4 4 0 0131 19zM33.5 21a.501.501 0 01-.5-.5v-7c0-.275.225-.5.5-.5s.5.225.5.5v7c0 .275-.225.5-.5.5zM23 30c-3.012 0-5.658-1.067-6.907-2.784A.491.491 0 0116 26.93V23.49c0-.27.224-.491.5-.491s.5.221.5.491v3.276c1.103 1.392 3.382 2.25 6 2.25s4.896-.858 6-2.25V23.49c0-.27.225-.491.5-.491s.5.221.5.491v3.439a.484.484 0 01-.093.286C28.658 28.933 26.012 30 23 30z"/>
                    <path d="M27.5 18h-2a.501.501 0 01-.5-.5c0-.275.225-.5.5-.5h2c.275 0 .5.225.5.5s-.225.5-.5.5z"/>
                    <path
                        d="M28.4 19c-.33 0-.6-.18-.6-.4 0-.442-.539-.8-1.2-.8-.33 0-.6-.18-.6-.4 0-.22.27-.4.6-.4 1.325 0 2.4.717 2.4 1.6 0 .22-.27.4-.6.4zM32.5 22h-2a.501.501 0 01-.5-.5v-5c0-.275.225-.5.5-.5h2c.275 0 .5.225.5.5v5c0 .275-.225.5-.5.5zM31 21h1v-4h-1v4zM15.5 22h-2a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v5a.5.5 0 01-.5.5zM14 21h1v-4h-1v4zM12 27h1v8h-1zM12.5 27a.5.5 0 01-.5-.5c0-2.48 2.019-4.5 4.5-4.5a.5.5 0 010 1c-1.93 0-3.5 1.57-3.5 3.5a.5.5 0 01-.5.5zM33 27h1v8h-1zM33.5 27a.501.501 0 01-.5-.5c0-1.93-1.57-3.5-3.5-3.5a.501.501 0 01-.5-.5c0-.275.225-.5.5-.5 2.48 0 4.5 2.02 4.5 4.5 0 .275-.225.5-.5.5zM15.5 35a2.503 2.503 0 01-2.5-2.5c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5-1.122 2.5-2.5 2.5zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zM30.5 32h-4a.501.501 0 01-.5-.5c0-.275.225-.5.5-.5h4c.275 0 .5.225.5.5s-.225.5-.5.5z"/>
                </g>
            </g>
        </svg>
    )
}
