import { MessageDto } from '../dto/message.dto';
import { Message } from '../../../../domain/entities/message';
import { MessageBuilder } from '../../../../domain/entities/message.builder';

export class MessageMapper {
    static mapMessageDtoToMessage(messages: MessageDto[]): Message[] {
        return messages.map(item => {
            return new MessageBuilder()
                .withId(item.id)
                .withText(item.message)
                .withBanner(item.banner)
                .withTitle(item.title)
                .build()
        })
    }
}
