import React from "react";
import { SpeedQuizAnswer } from "../../../../domain/entity/answer";
import { AvatarType } from "../../../../../profile/domain/entities/types";
import { findAvatar } from "../../../../../profile/config/findAvatar";

interface Props {
    answer: SpeedQuizAnswer;
    selectedAnswers: string[];
    isTimeOver: boolean;
    opponentImage: AvatarType | undefined;
    onClick: () => void;
}

export default function SpeedQuizAnswerItem(props: Props) {

    const selectedButtonStyle = props.selectedAnswers.indexOf(props.answer.id) > -1 ? ' selected' : ''
    const correctButtonStyle = props.isTimeOver && props.answer.isCorrect ? ' correct' : ''

    return (
        <button disabled={props.isTimeOver} className={'answer-item' + selectedButtonStyle + correctButtonStyle}
                onClick={() => props.onClick()}>
            {props.opponentImage && props.answer.isOpponentAnswer && props.isTimeOver &&
                <img style={{width: 32, height: 32}}
                       src={findAvatar(props.opponentImage).image} alt={'Adversaire'}/>}

            {props.answer.text}
        </button>
    )
}
