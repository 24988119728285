import React from 'react';
import { Profile } from "../../../../../profile/domain/entities/profile";
import { AdventureScore } from "../../../../domain/entities/score/adventureScore";
import { AdventureAgencyScore } from "../../../../domain/entities/score/adventureAgencyScore";
import { AdventureI18n } from "../../../../config/i18n";
import { AdventureSpecifications } from "../../specifications";

interface Props {
    profile: Profile | undefined;
    adventureScore: AdventureScore | undefined;
}

const AgenceStandardDashboard = (props: Props) => {

    const getAgencyScore = (): AdventureAgencyScore => {
        const agencyCode = props.profile?.agencyCode
        return props.adventureScore?.agenciesScore.find(item => item.code === agencyCode) as AdventureAgencyScore
    }

    return (
        <React.Fragment>
            <div className={'flex-box'}>

                <div className={'box-adventure-info'}>
                    <p>{AdventureI18n.turnover}</p>
                    <strong>{AdventureSpecifications.formatNumber(getAgencyScore()?.turnover, ' ', ' €')}</strong>
                    <span className={'mt-2'}>
                        sur {AdventureSpecifications.formatNumber(getAgencyScore()?.turnoverGoal, ' ', ' €')}
                    </span>
                </div>

                <div className={'box-adventure-info'}>
                    <p>{AdventureI18n.rank}</p>
                    <strong>{getAgencyScore()?.rank}
                        <sup style={{fontSize: '60%', color: "var(--silver-chalice)", fontWeight: 'lighter'}}>
                            {getAgencyScore()?.rankSuffix()}
                        </sup>
                    </strong>
                </div>
            </div>

            <div className={'box-100'}>
                <div className={'adventure-progress'}>
                    <div className="progress-title">
                        <span>{AdventureI18n.result}</span>
                    </div>
                    <div className="progressbar">
                        <div className={'progress-bg'}>
                            <div className="actual-progress"
                                 style={{
                                     width: AdventureSpecifications.agencyScorePercentage(getAgencyScore())
                                         .replace(' ', '')
                                 }}/>
                        </div>
                        <div className="actual-progress-val">
                            {AdventureSpecifications.agencyScorePercentage(getAgencyScore())}
                        </div>
                    </div>
                    <div className={'d-flex justify-content-between fex-row progress-bar-values'}>
                        <p>{AdventureSpecifications.formatNumber(getAgencyScore()?.turnover, ' ', ' €')}</p>
                        <p>{AdventureSpecifications.formatNumber(getAgencyScore()?.turnoverGoal, ' ', ' €')}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default AgenceStandardDashboard;
