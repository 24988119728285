import { ApplicationContext } from "../../../shared/config/application.context";
import { AdventureI18n } from "../../config/i18n";
import { AdventurePlayerScore } from "../../domain/entities/score/adventurePlayerScore";
import { AdventureAgencyScore } from "../../domain/entities/score/adventureAgencyScore";
import { agencyCategories } from "./constants";
import { Adventure } from "../../domain/entities/adventure";

const moment = ApplicationContext.getInstance().momentJs()

export class AdventureSpecifications {
    static getAdventureDate(startDate: Date, endDate: Date): string {
        return moment().diff(moment(endDate)) < 0 ?
            `DU ${moment(startDate).format('DD/MM/YY')} AU ${moment(endDate).format('DD/MM/YY')}`
            : AdventureI18n.finished
    }

    static playerScorePercentage(playerScore: AdventurePlayerScore): string {
        if (playerScore.activatedClient !== 0 && playerScore.activatedClientGoal !== 0 && playerScore.turnover !== 0 && playerScore.turnoverGoal !== 0)
            return Math.trunc(
                (
                    (playerScore.turnover / playerScore.turnoverGoal) +
                    (playerScore.activatedClient / playerScore.activatedClientGoal)
                ) / 2 * 100
            ) + ' %'
        else if (playerScore.turnover !== 0 && playerScore.turnoverGoal !== 0)
            return Math.trunc(
                (playerScore.turnover / playerScore.turnoverGoal) * 100
            ) + ' %';
        else
            return '0 %';
    }

    static agencyScorePercentage(agencyScore: AdventureAgencyScore): string {
        if (agencyScore.activatedClient !== 0 && agencyScore.turnover !== 0 && agencyScore.turnoverGoal !== 0)
            return Math.trunc(
                (
                    (agencyScore.turnover / agencyScore.turnoverGoal)
                ) * 100
            ) + ' %'
        else if (agencyScore.turnover !== 0 && agencyScore.turnoverGoal !== 0)
            return Math.trunc(
                (agencyScore.turnover / agencyScore.turnoverGoal) * 100
            ) + ' %';
        else
            return '0 %';
    }

    static formatNumber(value: number | undefined, separator: string, unit: string): string {
        if (value !== undefined) {
            if (value > 999) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator) + unit;
            } else {
                return value.toString() + unit
            }
        }
        return ''
    }

    static calculatePrime(score: number, category: string): number {
        if (category === 'A' || category === 'B' || category === 'C') {
            if (10 <= score && score < 15) {
                return agencyCategories[category].prime[0]
            } else if (15 <= score && score < 20) {
                return agencyCategories[category].prime[1]
            } else if (20 <= score) {
                return agencyCategories[category].prime[2]
            }
        }
        return 0
    }

    static calculateBoost(clients: number, prime: number, category: string): number {
        if (category === 'A' || category === 'B' || category === 'C') {
            if (agencyCategories[category].boostMin[0] <= clients && clients <= agencyCategories[category].boostMax[0]) {
                return prime * 0.1
            } else if (agencyCategories[category].boostMin[1] <= clients && clients <= agencyCategories[category].boostMax[1]) {
                return prime * 0.15
            } else if (agencyCategories[category].boostMin[2] <= clients) {
                return prime * 0.2
            }
        }
        return 0
    }

    static daysLeftInPercentage(adventure: Adventure): string {
        const start = moment(adventure.starDate)
        const end = moment(adventure.endDate)
        const today = moment()

        const actualRest = end.diff(today, 'days')
        const totalDays = end.diff(start, 'days')

        const daysLeft = ((1 - (actualRest / totalDays)) * 100)

        return Math.min(daysLeft, 100).toFixed(0) + "%"
    }
}
